/* eslint-disable no-mixed-operators */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import './fantasyTeamBuilder.css';
import FantasyTeamCustom from '../fantasyTeamCustom/FantasyTeamCustom';
import { getPlayersByRole } from './fantasyTeamBuilderUtil';

// 📌 Role Selection Order
const roleOrder = ['WicketKeepers', 'Batters', 'AllRounders', 'Bowlers'];

// 📌 Constraints
const MAX_PLAYERS = 11;
const MIN_ROLE_COUNT = 1;
const MAX_PER_ROLE = 8;

const FantasyTeamBuilder = React.forwardRef(
  ({ matchInfo, playerStats, matchType }, ref) => {
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [categoryCounts, setCategoryCounts] = useState({
      WicketKeepers: 0,
      Batters: 0,
      AllRounders: 0,
      Bowlers: 0,
    });
    const [roleIndex, setRoleIndex] = useState(0);
    const [playerIndex, setPlayerIndex] = useState(0);

    const playersByRole = getPlayersByRole(matchInfo, playerStats);
    const currentRole = roleOrder[roleIndex];
    const currentPlayers = playersByRole[currentRole] || [];
    const currentPlayer = currentPlayers[playerIndex];

    const isTeamFull = selectedPlayers.length === MAX_PLAYERS;

    // 📌 Compute remaining required categories
    const remainingCategories = roleOrder.filter(
      (role) => categoryCounts[role] < MIN_ROLE_COUNT,
    );
    const minSlotsNeeded = remainingCategories.length;
    const maxAllowedForRole = MAX_PLAYERS - minSlotsNeeded;

    // 📌 Ensure mandatory picks before free selection
    const isMandatoryPick = remainingCategories.includes(currentRole);
    const canPickMoreFromRole = categoryCounts[currentRole] < MAX_PER_ROLE
      && selectedPlayers.length < maxAllowedForRole;

    // 🔄 Move to Next Player or Role
    const moveToNextPlayer = () => {
      let nextPlayerIndex = playerIndex + 1;
      let nextRoleIndex = roleIndex;

      // Move to the next player in the same role
      if (nextPlayerIndex < currentPlayers.length) {
        setPlayerIndex(nextPlayerIndex);
        return;
      }

      // If no more players in this role, move to next role
      nextPlayerIndex = 0;
      nextRoleIndex = (roleIndex + 1) % roleOrder.length;

      while (
        categoryCounts[roleOrder[nextRoleIndex]] >= MAX_PER_ROLE
        || playersByRole[roleOrder[nextRoleIndex]].length === 0
      ) {
        nextRoleIndex = (nextRoleIndex + 1) % roleOrder.length;
      }

      setPlayerIndex(nextPlayerIndex);
      setRoleIndex(nextRoleIndex);
    };

    // 📌 Add Player to Fantasy Team
    const addPlayerToTeam = () => {
      if (!isTeamFull && (isMandatoryPick || canPickMoreFromRole)) {
        setSelectedPlayers([...selectedPlayers, currentPlayer]);

        setCategoryCounts((prev) => ({
          ...prev,
          [currentRole]: prev[currentRole] + 1,
        }));

        moveToNextPlayer();
      }
    };

    // ⏭ Skip Player
    const skipPlayer = () => {
      moveToNextPlayer();
    };

    // 🔄 Reset Selection Process
    const resetSelection = () => {
      setSelectedPlayers([]);
      setCategoryCounts({
        WicketKeepers: 0,
        Batters: 0,
        AllRounders: 0,
        Bowlers: 0,
      });
      setRoleIndex(0);
      setPlayerIndex(0);
    };

    // Check if the current player is already in the selected team
    const isPlayerAlreadySelected = selectedPlayers.some(
      (player) => player.name === currentPlayer?.name,
    );

    return (
      <div ref={ref}>
        <div className="statsNguts__fantasyTeamBuilder">
          {/* 🔢 Player Selection Summary */}
          <div className="statsNguts__fantasyTeamBuilder_summary">
            <h3 className="summary_title">Your Selections</h3>

            {/* Progress Bar for Total Players Selected */}
            <div className="summary_progress">
              <progress
                max={MAX_PLAYERS}
                value={selectedPlayers.length}
              />
              <span>
                {selectedPlayers.length} / {MAX_PLAYERS} Players Selected
              </span>
            </div>

            {/* Role Breakdown */}
            <div className="summary_roles">
              <div className="role_count">
                <span className="role_icon">🧤</span>
                <p>Wicket Keepers: {categoryCounts.WicketKeepers}</p>
              </div>
              <div className="role_count">
                <span className="role_icon">🏏</span>
                <p>Batters: {categoryCounts.Batters}</p>
              </div>
              <div className="role_count">
                <span className="role_icon">⚡</span>
                <p>All-Rounders: {categoryCounts.AllRounders}</p>
              </div>
              <div className="role_count">
                <span className="role_icon">🎯</span>
                <p>Bowlers: {categoryCounts.Bowlers}</p>
              </div>
            </div>
          </div>

          {/* ✅ Finalization Message & Reset Button */}
          {isTeamFull && (
            <section className="statsNguts__fantasyTeamBuilder_final_message_container">
              <h3 className="statsNguts__fantasyTeamBuilder_final_message">
                Your Fantasy Team is Ready! 🚀
              </h3>
              <button
                type="button"
                className="btn btn-reset"
                onClick={resetSelection}
                aria-label="Reset Fantasy Team Selection"
              >
                Build Fantasy Team Again
              </button>
            </section>
          )}
          {!isTeamFull && currentPlayer && (
            <div className="statsNguts__fantasyTeamBuilder_player_card">
              {/* Player Image & Name */}
              {/* 📌 Top Container: Image on Left, Details on Right */}
              <div className="statsNguts__fantasyTeamBuilder_player_top">
                {/* 📷 Player Image */}
                <div className="statsNguts__fantasyTeamBuilder_player_image_container">
                  <img
                    src={require(`../../../assets/image/players/${currentPlayer.avatar}`)}
                    alt={currentPlayer.name}
                    className="statsNguts__fantasyTeamBuilder_player_image"
                  />
                </div>

                {/* 📋 Player Details */}
                <div className="statsNguts__fantasyTeamBuilder_player_details">
                  <h3 className="player_name">{currentPlayer.name}</h3>
                  <div className="statsNguts__fantasyTeamBuilder_role_team">
                    <p className="player_role">{currentPlayer.role}</p>
                    <p className="player_team">{currentPlayer.team}</p>
                  </div>
                </div>
              </div>

              {/* 📊 Player Stats */}
              <div className="statsNguts__fantasyTeamBuilder_player_stats_container">
                <h4 className="statsNguts__fantasyTeamBuilder_stats_heading">
                  <FaStar className="icon-star" />
                  Recent Performance Stats
                </h4>

                {/* Batting Stats */}
                {(currentPlayer.role === 'Wicket-Keeper'
                  || currentPlayer.role === 'Batter'
                  || currentPlayer.role === 'All-Rounder') && (
                  <div className="statsNguts__fantasyTeamBuilder_stats_grid">
                    {currentPlayer.last5Runs && (
                      <div className="statsNguts__fantasyTeamBuilder_stat_card">
                        <h5>🏏 Runs in {matchType}</h5>
                        <p>{currentPlayer.last5Runs || '-'}</p>
                      </div>
                    )}

                    {currentPlayer.last5RunsVsOpposition && (
                      <div className="statsNguts__fantasyTeamBuilder_stat_card">
                        <h5>🏏 Runs Vs {currentPlayer.opposition}</h5>
                        <p>{currentPlayer.last5RunsVsOpposition || '-'}</p>
                      </div>
                    )}

                    {currentPlayer.last5RunsOnGround && (
                      <div className="statsNguts__fantasyTeamBuilder_stat_card full-width">
                        <h5>🏏 Runs in {currentPlayer.ground}</h5>
                        <p>{currentPlayer.last5RunsOnGround || '-'}</p>
                      </div>
                    )}

                    {currentPlayer.last5RunsInternational && (
                      <div className="statsNguts__fantasyTeamBuilder_stat_card full-width">
                        <h5>🏏 Runs in T20Is</h5>
                        <p>{currentPlayer.last5RunsInternational || '-'}</p>
                      </div>
                    )}

                    {currentPlayer.country === 'India'
                      && currentPlayer.last5RunsDomestic && (
                        <div className="statsNguts__fantasyTeamBuilder_stat_card full-width">
                          <h5>🏏 Runs in Domestic T20s</h5>
                          <p>{currentPlayer.last5RunsDomestic || '-'}</p>
                        </div>
                    )}

                    {currentPlayer.last5RunsInternationalLeague && (
                      <div className="statsNguts__fantasyTeamBuilder_stat_card full-width">
                        <h5>🏏 Runs in Intl. T20 Leagues</h5>
                        <p>
                          {currentPlayer.last5RunsInternationalLeague || '-'}
                        </p>
                      </div>
                    )}
                  </div>
                )}

                {/* Bowling Stats */}
                {(currentPlayer.role === 'All-Rounder'
                  || currentPlayer.role === 'Bowler') && (
                  <div className="statsNguts__fantasyTeamBuilder_stats_grid">
                    {currentPlayer.last5Wickets && (
                      <div className="statsNguts__fantasyTeamBuilder_stat_card">
                        <h5>🥎 Wickets in {matchType}</h5>
                        <p>{currentPlayer.last5Wickets || '-'}</p>
                      </div>
                    )}

                    {currentPlayer.last5WicketsVsOpposition && (
                      <div className="statsNguts__fantasyTeamBuilder_stat_card">
                        <h5>🥎 Wickets Vs {currentPlayer.opposition}</h5>
                        <p>{currentPlayer.last5WicketsVsOpposition || '-'}</p>
                      </div>
                    )}

                    {currentPlayer.last5WicketsOnGround && (
                      <div className="statsNguts__fantasyTeamBuilder_stat_card full-width">
                        <h5>🥎 Wickets in {currentPlayer.ground}</h5>
                        <p>{currentPlayer.last5WicketsOnGround || '-'}</p>
                      </div>
                    )}

                    {currentPlayer.last5WicketInternational && (
                      <div className="statsNguts__fantasyTeamBuilder_stat_card full-width">
                        <h5>🥎 Wickets in T20Is</h5>
                        <p>{currentPlayer.last5WicketInternational || '-'}</p>
                      </div>
                    )}

                    {currentPlayer.country === 'India'
                      && currentPlayer.last5WicketDomestic && (
                        <div className="statsNguts__fantasyTeamBuilder_stat_card full-width">
                          <h5>🥎 Wickets in Domestic T20s</h5>
                          <p>{currentPlayer.last5WicketDomestic || '-'}</p>
                        </div>
                    )}

                    {currentPlayer.last5WicketInternationalLeague && (
                      <div className="statsNguts__fantasyTeamBuilder_stat_card full-width">
                        <h5>🥎 Wickets in Intl. T20 Leagues</h5>
                        <p>
                          {currentPlayer.last5WicketInternationalLeague || '-'}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Buttons */}
              <div className="statsNguts__fantasyTeamBuilder_button_container">
                <button
                  type="button"
                  className={`btn ${
                    !isMandatoryPick && !canPickMoreFromRole
                      ? 'btn-disabled'
                      : 'btn-add'
                  }`}
                  onClick={addPlayerToTeam}
                  disabled={
                    (!isMandatoryPick && !canPickMoreFromRole)
                    || isPlayerAlreadySelected
                  }
                >
                  {isPlayerAlreadySelected ? 'Already Selected' : 'Add to Team'}
                </button>

                <button
                  type="button"
                  className="btn btn-skip"
                  onClick={skipPlayer}
                >
                  Skip
                </button>
              </div>
            </div>
          )}

          {/* 🏏 Fantasy Team Display */}
          <FantasyTeamCustom selectedPlayers={selectedPlayers} />
        </div>
      </div>
    );
  },
);

export default FantasyTeamBuilder;
