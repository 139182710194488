/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchTeamStats } from '../../../../../api/dataService';
import './seriesTeamStats.css';

const TeamInfoCard = ({ title, image, name }) => (
  <div className="statsNguts__seriesTeamStats_WPL2025_team_info_card">
    <h4 className="statsNguts__seriesTeamStats_WPL2025_team_info_title">{title}</h4>
    <img
      src={require(`../../../../../assets/image/players/${image}`)}
      alt={name}
      className="statsNguts__seriesTeamStats_WPL2025_team_info_img"
    />
    <p className="statsNguts__seriesTeamStats_WPL2025_team_info_name">{name}</p>
  </div>
);

const PlayerCard = ({ player }) => (
  <Link to={`/player/${player.id}`} className="player-card-link">
    <div className="statsNguts__seriesTeamStats_WPL2025_player_card">
      <img
        src={require(`../../../../../assets/image/players/${player.avatar}`)}
        alt={player.name}
        className="statsNguts__seriesTeamStats_WPL2025_player_img"
      />
      <h4>{player.name}</h4>
      <p>
        <strong>Country:</strong> {player.country}
      </p>
      <div className="statsNguts__seriesTeamStats_WPL2025_auction_price">
        <span className="statsNguts__seriesTeamStats_WPL2025_currency_symbol">₹</span>
        {player.auctionPrice}
      </div>
      <div
        className={`statsNguts__seriesTeamStats_WPL2025_player_status ${
          player.status === 'Available'
            ? 'status-available'
            : 'status-unavailable'
        }`}
      >
        {player.status}
      </div>
    </div>
  </Link>
);

const PlayerSection = ({ title, players }) => (
  <div className="statsNguts__seriesTeamStats_WPL2025_player_section">
    <h3>{title}</h3>
    <div className="statsNguts__seriesTeamStats_WPL2025_player_grid">
      {players.map((player, index) => (
        <PlayerCard key={index} player={player} />
      ))}
    </div>
  </div>
);

const SeriesTeamStats = () => {
  const { teamShortName } = useParams();
  const [teamStatsData, setTeamStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const teamStats = await fetchTeamStats('WPL-2025');
        if (isMounted.current) {
          setTeamStatsData(teamStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Team Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const teamData = teamStatsData[teamShortName];

  const teamStats = [
    {
      statName: 'Most Runs',
      stats: teamData.MostRuns.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Runs} Runs`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Wickets',
      stats: teamData.MostWickets.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Wickets} Wickets`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Strike Rate',
      stats: teamData.BestStrikeRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `SR: ${stat.StrikeRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Economy Rate',
      stats: teamData.BestEconomyRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Fours',
      stats: teamData.MostFours.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.TotalFours} Fours`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Sixes',
      stats: teamData.MostSixes.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.TotalSixes} Sixes`,
        isTop: i === 0,
      })),
    },
  ];

  return (
    <div className="statsNguts__seriesTeamStats_WPL2025">
      <div className="statsNguts__seriesTeamStats_WPL2025_team_header">
        <h2 className="statsNguts__seriesTeamStats_WPL2025_team_title">
          {teamData.teamName}
        </h2>
      </div>

      <div className="statsNguts__seriesTeamStats_WPL2025_team_details_container">
        {teamData.teamDetails.map((detail, index) => (
          <TeamInfoCard
            key={index}
            title={detail.Title}
            image={detail.Avatar}
            name={detail.Name}
          />
        ))}
      </div>

      {teamData.wicketKeepers.length > 0 && (
        <PlayerSection
          title="Wicket Keepers"
          players={teamData.wicketKeepers}
        />
      )}
      {teamData.batters.length > 0 && (
        <PlayerSection title="Batters" players={teamData.batters} />
      )}
      {teamData.allRounders.length > 0 && (
        <PlayerSection title="All Rounders" players={teamData.allRounders} />
      )}
      {teamData.bowlers.length > 0 && (
        <PlayerSection title="Bowlers" players={teamData.bowlers} />
      )}

      <div className="statsNguts__seriesTeamStats_WPL2025_stats_section">
        <h2 className="statsNguts__seriesTeamStats_WPL2025_section_heading">{teamData.teamName} Stats in WPL 2025</h2>
        <div className="statsNguts__seriesTeamStats_WPL2025_grid">
          {teamStats.map((statCategory, index) => (
            <div key={index} className="statsNguts__seriesTeamStats_WPL2025_card">
              <h3 className="statsNguts__seriesTeamStats_WPL2025_title">{statCategory.statName}</h3>
              <div className="statsNguts__seriesTeamStats_WPL2025_details">
                {statCategory.stats.map((stat, i) => (
                  <div key={i} className={`statsNguts__seriesTeamStats_WPL2025_detail ${stat.isTop ? 'highlight' : ''}`}>
                    <img
                      src={require(`../../../../../assets/image/players/${stat.photo}`)}
                      alt={stat.playerName}
                      className="statsNguts__seriesTeamStats_WPL2025_photo"
                    />
                    <p className="statsNguts__seriesTeamStats_WPL2025_player">{stat.playerName}</p>
                    <p className="statsNguts__seriesTeamStats_WPL2025_value">{stat.statValue}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeriesTeamStats;
