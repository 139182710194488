// 📌 Organizing Players by Role
export const getPlayersByRole = (matchInfo, playerStats) => {
  const teamAWicketkeepers = playerStats.teamA_WicketKeepers.filter((player) => player.status === 'Available');
  const teamABatters = playerStats.teamA_Batters.filter((player) => player.status === 'Available');
  const teamAAllRounders = playerStats.teamA_AllRounders.filter((player) => player.status === 'Available');
  const teamABowlers = playerStats.teamA_Bowlers.filter((player) => player.status === 'Available');
  const teamBWicketkeepers = playerStats.teamB_WicketKeepers.filter((player) => player.status === 'Available');
  const teamBBatters = playerStats.teamB_Batters.filter((player) => player.status === 'Available');
  const teamBAllRounders = playerStats.teamB_AllRounders.filter((player) => player.status === 'Available');
  const teamBBowlers = playerStats.teamB_Bowlers.filter((player) => player.status === 'Available');
  const wicketKeepers = [];
  const batters = [];
  const allRounders = [];
  const bowlers = [];

  teamAWicketkeepers.forEach((player) => {
    const { runsScored } = player;
    const runScoredformattedString = runsScored.length ? runsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredAgainstOpposition } = player;
    const runsScoredAgainstOppositionformattedString = runsScoredAgainstOpposition.length ? runsScoredAgainstOpposition.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredInGround } = player;
    const runsScoredInGroundformattedString = runsScoredInGround.length ? runsScoredInGround.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';

    const { internationalT20RunsScored } = player;
    const internationalT20RunsScoredformattedString = internationalT20RunsScored?.length ? internationalT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { domesticT20RunsScored } = player;
    const domesticT20RunsScoredformattedString = domesticT20RunsScored?.length ? domesticT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20LeagueRunsScored } = player;
    const internationalT20LeagueRunsScoredformattedString = internationalT20LeagueRunsScored?.length ? internationalT20LeagueRunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';

    const wk = {
      id: player.id,
      name: player.name,
      role: 'Wicket-Keeper',
      country: player.country,
      ground: matchInfo.matchGroundFullName,
      team: matchInfo.teamAShortName,
      opposition: matchInfo.teamBShortName,
      avatar: player.avatar,
      last5Runs: runScoredformattedString,
      last5RunsVsOpposition: runsScoredAgainstOppositionformattedString,
      last5RunsOnGround: runsScoredInGroundformattedString,
      last5RunsInternational: internationalT20RunsScoredformattedString,
      last5RunsDomestic: domesticT20RunsScoredformattedString,
      last5RunsInternationalLeague: internationalT20LeagueRunsScoredformattedString,
    };

    wicketKeepers.push(wk);
  });
  teamBWicketkeepers.forEach((player) => {
    const { runsScored } = player;
    const runScoredformattedString = runsScored.length ? runsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredAgainstOpposition } = player;
    const runsScoredAgainstOppositionformattedString = runsScoredAgainstOpposition.length ? runsScoredAgainstOpposition.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredInGround } = player;
    const runsScoredInGroundformattedString = runsScoredInGround.length ? runsScoredInGround.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20RunsScored } = player;
    const internationalT20RunsScoredformattedString = internationalT20RunsScored?.length ? internationalT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { domesticT20RunsScored } = player;
    const domesticT20RunsScoredformattedString = domesticT20RunsScored?.length ? domesticT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20LeagueRunsScored } = player;
    const internationalT20LeagueRunsScoredformattedString = internationalT20LeagueRunsScored?.length ? internationalT20LeagueRunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';

    const wk = {
      id: player.id,
      name: player.name,
      team: matchInfo.teamBShortName,
      opposition: matchInfo.teamAShortName,
      role: 'Wicket-Keeper',
      country: player.country,
      ground: matchInfo.matchGroundFullName,
      avatar: player.avatar,
      last5Runs: runScoredformattedString,
      last5RunsVsOpposition: runsScoredAgainstOppositionformattedString,
      last5RunsOnGround: runsScoredInGroundformattedString,
      last5RunsInternational: internationalT20RunsScoredformattedString,
      last5RunsDomestic: domesticT20RunsScoredformattedString,
      last5RunsInternationalLeague: internationalT20LeagueRunsScoredformattedString,
    };

    wicketKeepers.push(wk);
  });
  teamABatters.forEach((player) => {
    const { runsScored } = player;
    const runScoredformattedString = runsScored.length ? runsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredAgainstOpposition } = player;
    const runsScoredAgainstOppositionformattedString = runsScoredAgainstOpposition.length ? runsScoredAgainstOpposition.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredInGround } = player;
    const runsScoredInGroundformattedString = runsScoredInGround.length ? runsScoredInGround.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20RunsScored } = player;
    const internationalT20RunsScoredformattedString = internationalT20RunsScored?.length ? internationalT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { domesticT20RunsScored } = player;
    const domesticT20RunsScoredformattedString = domesticT20RunsScored?.length ? domesticT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20LeagueRunsScored } = player;
    const internationalT20LeagueRunsScoredformattedString = internationalT20LeagueRunsScored?.length ? internationalT20LeagueRunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';

    const bt = {
      id: player.id,
      name: player.name,
      team: matchInfo.teamAShortName,
      opposition: matchInfo.teamBShortName,
      ground: matchInfo.matchGroundFullName,
      role: 'Batter',
      country: player.country,
      avatar: player.avatar,
      last5Runs: runScoredformattedString,
      last5RunsVsOpposition: runsScoredAgainstOppositionformattedString,
      last5RunsOnGround: runsScoredInGroundformattedString,
      last5RunsInternational: internationalT20RunsScoredformattedString,
      last5RunsDomestic: domesticT20RunsScoredformattedString,
      last5RunsInternationalLeague: internationalT20LeagueRunsScoredformattedString,
    };

    batters.push(bt);
  });
  teamBBatters.forEach((player) => {
    const { runsScored } = player;
    const runScoredformattedString = runsScored.length ? runsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredAgainstOpposition } = player;
    const runsScoredAgainstOppositionformattedString = runsScoredAgainstOpposition.length ? runsScoredAgainstOpposition.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredInGround } = player;
    const runsScoredInGroundformattedString = runsScoredInGround.length ? runsScoredInGround.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20RunsScored } = player;
    const internationalT20RunsScoredformattedString = internationalT20RunsScored?.length ? internationalT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { domesticT20RunsScored } = player;
    const domesticT20RunsScoredformattedString = domesticT20RunsScored?.length ? domesticT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20LeagueRunsScored } = player;
    const internationalT20LeagueRunsScoredformattedString = internationalT20LeagueRunsScored?.length ? internationalT20LeagueRunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';

    const bt = {
      id: player.id,
      role: 'Batter',
      country: player.country,
      name: player.name,
      team: matchInfo.teamBShortName,
      ground: matchInfo.matchGroundFullName,
      opposition: matchInfo.teamAShortName,
      avatar: player.avatar,
      last5Runs: runScoredformattedString,
      last5RunsVsOpposition: runsScoredAgainstOppositionformattedString,
      last5RunsOnGround: runsScoredInGroundformattedString,
      last5RunsInternational: internationalT20RunsScoredformattedString,
      last5RunsDomestic: domesticT20RunsScoredformattedString,
      last5RunsInternationalLeague: internationalT20LeagueRunsScoredformattedString,
    };

    batters.push(bt);
  });
  teamAAllRounders.forEach((player) => {
    const { runsScored } = player;
    const runScoredformattedString = runsScored.length ? runsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredAgainstOpposition } = player;
    const runsScoredAgainstOppositionformattedString = runsScoredAgainstOpposition.length ? runsScoredAgainstOpposition.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredInGround } = player;
    const runsScoredInGroundformattedString = runsScoredInGround.length ? runsScoredInGround.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20RunsScored } = player;
    const internationalT20RunsScoredformattedString = internationalT20RunsScored?.length ? internationalT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { domesticT20RunsScored } = player;
    const domesticT20RunsScoredformattedString = domesticT20RunsScored?.length ? domesticT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20LeagueRunsScored } = player;
    const internationalT20LeagueRunsScoredformattedString = internationalT20LeagueRunsScored?.length ? internationalT20LeagueRunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';

    const { wicketsTaken } = player;
    const wicketsTakenformattedString = wicketsTaken.length ? wicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { wicketsTakenAgainstOpposition } = player;
    const wicketsTakenAgainstOppositionformattedString = wicketsTakenAgainstOpposition.length ? wicketsTakenAgainstOpposition.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { wicketsTakenInGround } = player;
    const wicketsTakenInGroundformattedString = wicketsTakenInGround.length ? wicketsTakenInGround.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';

    const { internationalT20WicketsTaken } = player;
    const internationalT20WicketsTakenformattedString = internationalT20WicketsTaken?.length ? internationalT20WicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { domesticT20WicketsTaken } = player;
    const domesticT20WicketsTakenformattedString = domesticT20WicketsTaken?.length ? domesticT20WicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { internationalT20LeagueWicketsTaken } = player;
    const internationalT20LeagueWicketsTakenformattedString = internationalT20LeagueWicketsTaken?.length ? internationalT20LeagueWicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';

    const ar = {
      id: player.id,
      role: 'All-Rounder',
      name: player.name,
      team: matchInfo.teamAShortName,
      country: player.country,
      opposition: matchInfo.teamBShortName,
      ground: matchInfo.matchGroundFullName,
      avatar: player.avatar,
      last5Runs: runScoredformattedString,
      last5RunsVsOpposition: runsScoredAgainstOppositionformattedString,
      last5RunsOnGround: runsScoredInGroundformattedString,
      last5Wickets: wicketsTakenformattedString,
      last5WicketsVsOpposition: wicketsTakenAgainstOppositionformattedString,
      last5WicketsOnGround: wicketsTakenInGroundformattedString,
      last5RunsInternational: internationalT20RunsScoredformattedString,
      last5RunsDomestic: domesticT20RunsScoredformattedString,
      last5RunsInternationalLeague: internationalT20LeagueRunsScoredformattedString,
      last5WicketInternational: internationalT20WicketsTakenformattedString,
      last5WicketDomestic: domesticT20WicketsTakenformattedString,
      last5WicketInternationalLeague: internationalT20LeagueWicketsTakenformattedString,
    };

    allRounders.push(ar);
  });
  teamBAllRounders.forEach((player) => {
    const { runsScored } = player;
    const runScoredformattedString = runsScored.length ? runsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredAgainstOpposition } = player;
    const runsScoredAgainstOppositionformattedString = runsScoredAgainstOpposition.length ? runsScoredAgainstOpposition.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { runsScoredInGround } = player;
    const runsScoredInGroundformattedString = runsScoredInGround.length ? runsScoredInGround.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20RunsScored } = player;
    const internationalT20RunsScoredformattedString = internationalT20RunsScored?.length ? internationalT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { domesticT20RunsScored } = player;
    const domesticT20RunsScoredformattedString = domesticT20RunsScored?.length ? domesticT20RunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';
    const { internationalT20LeagueRunsScored } = player;
    const internationalT20LeagueRunsScoredformattedString = internationalT20LeagueRunsScored?.length ? internationalT20LeagueRunsScored.slice(0, 5)
      .map((entry) => `${entry.Runs}(${entry.Balls})`)
      .join(', ') : '';

    const { wicketsTaken } = player;
    const wicketsTakenformattedString = wicketsTaken.length ? wicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { wicketsTakenAgainstOpposition } = player;
    const wicketsTakenAgainstOppositionformattedString = wicketsTakenAgainstOpposition.length ? wicketsTakenAgainstOpposition.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { wicketsTakenInGround } = player;
    const wicketsTakenInGroundformattedString = wicketsTakenInGround.length ? wicketsTakenInGround.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { internationalT20WicketsTaken } = player;
    const internationalT20WicketsTakenformattedString = internationalT20WicketsTaken?.length ? internationalT20WicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { domesticT20WicketsTaken } = player;
    const domesticT20WicketsTakenformattedString = domesticT20WicketsTaken?.length ? domesticT20WicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { internationalT20LeagueWicketsTaken } = player;
    const internationalT20LeagueWicketsTakenformattedString = internationalT20LeagueWicketsTaken?.length ? internationalT20LeagueWicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';

    const ar = {
      id: player.id,
      name: player.name,
      team: matchInfo.teamBShortName,
      opposition: matchInfo.teamAShortName,
      ground: matchInfo.matchGroundFullName,
      role: 'All-Rounder',
      country: player.country,
      avatar: player.avatar,
      last5Runs: runScoredformattedString,
      last5RunsVsOpposition: runsScoredAgainstOppositionformattedString,
      last5RunsOnGround: runsScoredInGroundformattedString,
      last5Wickets: wicketsTakenformattedString,
      last5WicketsVsOpposition: wicketsTakenAgainstOppositionformattedString,
      last5WicketsOnGround: wicketsTakenInGroundformattedString,
      last5RunsInternational: internationalT20RunsScoredformattedString,
      last5RunsDomestic: domesticT20RunsScoredformattedString,
      last5RunsInternationalLeague: internationalT20LeagueRunsScoredformattedString,
      last5WicketInternational: internationalT20WicketsTakenformattedString,
      last5WicketDomestic: domesticT20WicketsTakenformattedString,
      last5WicketInternationalLeague: internationalT20LeagueWicketsTakenformattedString,
    };

    allRounders.push(ar);
  });
  teamABowlers.forEach((player) => {
    const { wicketsTaken } = player;
    const wicketsTakenformattedString = wicketsTaken.length ? wicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { wicketsTakenAgainstOpposition } = player;
    const wicketsTakenAgainstOppositionformattedString = wicketsTakenAgainstOpposition.length ? wicketsTakenAgainstOpposition.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { wicketsTakenInGround } = player;
    const wicketsTakenInGroundformattedString = wicketsTakenInGround.length ? wicketsTakenInGround.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { internationalT20WicketsTaken } = player;
    const internationalT20WicketsTakenformattedString = internationalT20WicketsTaken?.length ? internationalT20WicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { domesticT20WicketsTaken } = player;
    const domesticT20WicketsTakenformattedString = domesticT20WicketsTaken?.length ? domesticT20WicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { internationalT20LeagueWicketsTaken } = player;
    const internationalT20LeagueWicketsTakenformattedString = internationalT20LeagueWicketsTaken?.length ? internationalT20LeagueWicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';

    const bw = {
      id: player.id,
      name: player.name,
      team: matchInfo.teamAShortName,
      opposition: matchInfo.teamBShortName,
      ground: matchInfo.matchGroundFullName,
      role: 'Bowler',
      country: player.country,
      avatar: player.avatar,
      last5Wickets: wicketsTakenformattedString,
      last5WicketsVsOpposition: wicketsTakenAgainstOppositionformattedString,
      last5WicketsOnGround: wicketsTakenInGroundformattedString,
      last5WicketInternational: internationalT20WicketsTakenformattedString,
      last5WicketDomestic: domesticT20WicketsTakenformattedString,
      last5WicketInternationalLeague: internationalT20LeagueWicketsTakenformattedString,
    };

    bowlers.push(bw);
  });
  teamBBowlers.forEach((player) => {
    const { wicketsTaken } = player;
    const wicketsTakenformattedString = wicketsTaken.length ? wicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { wicketsTakenAgainstOpposition } = player;
    const wicketsTakenAgainstOppositionformattedString = wicketsTakenAgainstOpposition.length ? wicketsTakenAgainstOpposition.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { wicketsTakenInGround } = player;
    const wicketsTakenInGroundformattedString = wicketsTakenInGround.length ? wicketsTakenInGround.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { internationalT20WicketsTaken } = player;
    const internationalT20WicketsTakenformattedString = internationalT20WicketsTaken?.length ? internationalT20WicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { domesticT20WicketsTaken } = player;
    const domesticT20WicketsTakenformattedString = domesticT20WicketsTaken?.length ? domesticT20WicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';
    const { internationalT20LeagueWicketsTaken } = player;
    const internationalT20LeagueWicketsTakenformattedString = internationalT20LeagueWicketsTaken?.length ? internationalT20LeagueWicketsTaken.slice(0, 5)
      .map((entry) => `${entry.Wickets}/${entry.Runs}`)
      .join(', ') : '';

    const bw = {
      id: player.id,
      name: player.name,
      team: matchInfo.teamBShortName,
      opposition: matchInfo.teamAShortName,
      ground: matchInfo.matchGroundFullName,
      role: 'Bowler',
      country: player.country,
      avatar: player.avatar,
      last5Wickets: wicketsTakenformattedString,
      last5WicketsVsOpposition: wicketsTakenAgainstOppositionformattedString,
      last5WicketsOnGround: wicketsTakenInGroundformattedString,
      last5WicketInternational: internationalT20WicketsTakenformattedString,
      last5WicketDomestic: domesticT20WicketsTakenformattedString,
      last5WicketInternationalLeague: internationalT20LeagueWicketsTakenformattedString,
    };

    bowlers.push(bw);
  });

  const playersByRole = {
    WicketKeepers: wicketKeepers,
    Batters: batters,
    AllRounders: allRounders,
    Bowlers: bowlers,
  };

  return playersByRole;
};
