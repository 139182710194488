/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import './news.css';

const News = ({ platform, avatar, text, platformLink, date }) => (
  <div className="statsNguts__news">
    <div className="statsNguts__news_avatar_container">
      <img src={require(`../../../../../assets/image/news/${avatar}`)} alt="userAvatar" className="statsNguts__news_avatar" />
    </div>
    <div className="statsNguts__news_text_container">
      <p className="statsNguts__news_text">{text}</p>
      <span className="statsNguts__news_date">{date}</span>
      <a href={platformLink} target="_blank" rel="noopener noreferrer" className="statsNguts__news_text_read_more_link">
        Read more on {platform}
      </a>
    </div>
  </div>
);

export default News;
