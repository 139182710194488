/* eslint-disable react/no-danger */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPlayerDetails } from '../../../../../api/dataService';
import './playerPage.css';

const PlayerPage = () => {
  const { playerId } = useParams();
  const [player, setPlayer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const playerData = await fetchPlayerDetails('WPL-2025', playerId);
        if (isMounted.current) {
          setPlayer(playerData);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, [playerId]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Player Details!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  if (!player) return <h2>Player not found</h2>;

  return (
    <div className="player-details-container">
      <h1>{player.name}</h1>
      <img src={require(`../../../../../assets/image/players/${player.avatar}`)} alt={player.name} className="player-avatar" />
      <p><strong>Batting Style:</strong> {player.battingStyle}</p>
      <p><strong>Bowling Style:</strong> {player.bowlingStyle}</p>
      <p className="player-overview" dangerouslySetInnerHTML={{ __html: player.overview }} />

      <h2>Recent Performances</h2>
      <div className="player-stats">

        {/* WPL 2025 Stats */}
        {player.WPL2025?.runsScored?.length > 0 && (
          <>
            <h3>WPL 2025 (Batting)</h3>
            <ul>
              {player.WPL2025.runsScored.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Runs} runs off {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {player.WPL2025?.wicketsTaken?.length > 0 && (
          <>
            <h3>WPL 2025 (Bowling)</h3>
            <ul>
              {player.WPL2025.wicketsTaken.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Wickets} wickets for {match.Runs} runs in {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {/* WPL 2024 Stats */}
        {player.WPL2024?.runsScored?.length > 0 && (
          <>
            <h3>WPL 2024 (Batting)</h3>
            <ul>
              {player.WPL2024.runsScored.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Runs} runs off {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {player.WPL2024?.wicketsTaken?.length > 0 && (
          <>
            <h3>WPL 2024 (Bowling)</h3>
            <ul>
              {player.WPL2024.wicketsTaken.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Wickets} wickets for {match.Runs} runs in {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {/* WPL 2023 Stats */}
        {player.WPL2023?.runsScored?.length > 0 && (
          <>
            <h3>WPL 2023 (Batting)</h3>
            <ul>
              {player.WPL2023.runsScored.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Runs} runs off {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {player.WPL2023?.wicketsTaken?.length > 0 && (
          <>
            <h3>WPL 2023 (Bowling)</h3>
            <ul>
              {player.WPL2023.wicketsTaken.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Wickets} wickets for {match.Runs} runs in {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {/* WomenT20I Stats */}
        {player.WomenT20I?.runsScored?.length > 0 && (
          <>
            <h3>T20I (Batting)</h3>
            <ul>
              {player.WomenT20I.runsScored.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Runs} runs off {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {player.WomenT20I?.wicketsTaken?.length > 0 && (
          <>
            <h3>T20I (Bowling)</h3>
            <ul>
              {player.WomenT20I.wicketsTaken.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Wickets} wickets for {match.Runs} runs in {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {/* WomenT20 India Domestic */}
        {player.WomenT20IndiaDomestic?.runsScored?.length > 0 && (
          <>
            <h3>T20I India Domestic (Batting)</h3>
            <ul>
              {player.WomenT20IndiaDomestic.runsScored.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Runs} runs off {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {player.WomenT20IndiaDomestic?.wicketsTaken?.length > 0 && (
          <>
            <h3>T20I India Domestic (Bowling)</h3>
            <ul>
              {player.WomenT20IndiaDomestic.wicketsTaken.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Wickets} wickets for {match.Runs} runs in {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {/* WomenT20 International League */}
        {player.WomenT20InternationalLeague?.runsScored?.length > 0 && (
          <>
            <h3>T20 Leagues (other than WPL) (Batting)</h3>
            <ul>
              {player.WomenT20InternationalLeague.runsScored.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Runs} runs off {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}

        {player.WomenT20InternationalLeague?.wicketsTaken?.length > 0 && (
          <>
            <h3>T20 Leagues (other than WPL) (Bowling)</h3>
            <ul>
              {player.WomenT20InternationalLeague.wicketsTaken.map((match, index) => (
                <li key={index}>
                  {match.Team} vs {match.Against} - {match.Wickets} wickets for {match.Runs} runs in {match.Balls} balls
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default PlayerPage;
