/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState } from 'react';
import './teamh2h.css';
import Teamh2hTrends from '../teamh2hTrends/Teamh2hTrends';
import Teamh2hOverview from '../teamh2hOverview/Teamh2hOverview';
import Teamh2hMatchups from '../teamh2hMatchups/Teamh2hMatchups';

const Teamh2h = ({ playerStats, teamStats, matchInfo }) => {
  const [activeTab, setActiveTab] = useState('h2hMatches');
  const [isTeamH2HOverviewVisible, setIsTeamH2HOverviewVisible] = useState(false);
  const [isTeamH2HTrendsVisible, setIsTeamH2HTrendsVisible] = useState(false);
  const [isTeamH2HMatchupsVisible, setIsTeamH2HMatchupsVisible] = useState(false);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleTeamH2HOverviewClick = () => {
    setIsTeamH2HOverviewVisible(!isTeamH2HOverviewVisible);
  };

  const handleTeamH2HTrendsClick = () => {
    setIsTeamH2HTrendsVisible(!isTeamH2HTrendsVisible);
  };

  const handleTeamH2HMatchupsClick = () => {
    setIsTeamH2HMatchupsVisible(!isTeamH2HMatchupsVisible);
  };

  const getTableColumns = () => {
    switch (activeTab) {
      case 'h2hMatches':
        return [`${matchInfo.teamAShortName}`, `${matchInfo.teamBShortName}`, 'Winner', 'Series', 'Match', 'Date', 'Ground', 'MOTM'];
      case 'h2hTopRunScorers':
        return ['Player', 'Team', 'Runs', 'Balls', 'Series', 'Match', 'Date', 'Ground'];
      case 'h2hTopWicketTakers':
        return ['Player', 'Team', 'Wickets', 'Balls', 'Runs', 'Series', 'Match', 'Date', 'Ground'];
      case 'teamARecentPerformances':
        return ['First Inning', 'Second Inning', 'Winner', 'Series', 'Match', 'Date', 'Ground', 'MOTM'];
      case 'teamBRecentPerformances':
        return ['First Inning', 'Second Inning', 'Winner', 'Series', 'Match', 'Date', 'Ground', 'MOTM'];
      default:
        return [];
    }
  };

  const tabDataMap = {
    h2hMatches: { description: 'H2H - Matches', data: teamStats.h2hMatches },
    h2hTopRunScorers: { description: 'H2H - Top 10 Batting Performances', data: teamStats.h2hBattingPerformances.slice(0, 10) },
    h2hTopWicketTakers: { description: 'H2H - Top 10 Bowling Performances', data: teamStats.h2hBowlingPerformances.slice(0, 10) },
    teamARecentPerformances: { description: `${matchInfo.teamAShortName} - Recent Performances`, data: teamStats.teamARecentPerformances.slice(0, 10) },
    teamBRecentPerformances: { description: `${matchInfo.teamBShortName} - Recent Performances`, data: teamStats.teamBRecentPerformances.slice(0, 10) },
  };

  const renderDataRows = () => {
    const tabData = tabDataMap[activeTab].data || [];

    return tabData.map((entry, rowIndex) => (
      <tr key={rowIndex}>
        {getTableColumns().map((column, columnIndex) => (
          <td key={columnIndex} className="statsNguts__teamh2h_card_stats_details_table_entry">
            {entry[column]}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <div className="statsNguts__teamh2h_card">
      <div className="statsNguts__teamh2h_card_avatarContainer">
        <img className="statsNguts__teamh2h_card_avatarContainer_avatar" src={require(`../../../../../assets/image/teams/${matchInfo.matchAvatar}`)} alt="teamh2hAvatar" />
        <div className="statsNguts__teamh2h_card_name">
          <h2>{matchInfo.teamAShortName} Vs {matchInfo.teamBShortName}</h2>
        </div>
        <div className="statsNguts__teamh2h_card_teamh2h_overview" onClick={handleTeamH2HOverviewClick}>
          <h4>Overview<br />
            <h5 className="clickToKnowMore">
              Click to Know More!
            </h5>
          </h4>
        </div>
        <div className="statsNguts__teamh2h_card_teamh2h_trends" onClick={handleTeamH2HTrendsClick}>
          <h4>Trends<br />
            <h5 className="clickToKnowMore">
              Click to Know More!
            </h5>
          </h4>
        </div>
        <div className="statsNguts__teamh2h_card_teamh2h_matchups" onClick={handleTeamH2HMatchupsClick}>
          <h4>Matchups<br />
            <h5 className="clickToKnowMore">
              Click to Know More!
            </h5>
          </h4>
        </div>
      </div>
      <div className="statsNguts__teamh2h_card_content">
        {isTeamH2HOverviewVisible && (
        <div className="statsNguts__teamh2h_card_teamh2hOverview">
          <Teamh2hOverview teamStats={teamStats} />
        </div>
        )}
        {isTeamH2HTrendsVisible && (
        <div className="statsNguts__teamh2h_card_teamh2hCharts">
          <Teamh2hTrends playerStats={playerStats} teamStats={teamStats} matchInfo={matchInfo} />
        </div>
        )}
        {isTeamH2HMatchupsVisible && (
        <div className="statsNguts__teamh2h_card_teamh2hMatchups">
          <Teamh2hMatchups playerStats={playerStats} />
        </div>
        )}
        <div className="statsNguts__teamh2h_card_stats_container">
          <h1>Stats</h1>
          <div className="statsNguts__teamh2h_card_stats">
            <div className="statsNguts__teamh2h_card_stats_tabs">
              {Object.keys(tabDataMap).map((tab, index) => (
                <button
                  key={index}
                  type="button"
                  className={`statsNguts__teamh2h_card_stats_tabs_button ${activeTab === tab ? 'active' : ''}`}
                  onClick={() => handleTabChange(tab)}
                >
                  {tabDataMap[tab].description}
                </button>
              ))}
            </div>
            <div className="statsNguts__teamh2h_card_stats_details">
              {activeTab && (
              <div className="statsNguts__teamh2h_card_stats_details_table">
                <table>
                  <thead>
                    <tr>
                      {getTableColumns().map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{renderDataRows()}</tbody>
                </table>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teamh2h;
