/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState } from 'react';
import './teamh2hTrends.css';
import { Chart } from 'react-google-charts';
import { getH2HPlayerMostRunsChart, getH2HPlayerMostWicketsChart } from '../utils/ChartsUtil.jsx';
import { getH2HWinsAndLosses } from '../utils/StatsUtil.jsx';
import Team from '../team/Team.jsx';

const Teamh2hTrends = ({ playerStats, teamStats, matchInfo }) => {
  const [isTrendsExpanded, setIsTrendsExpanded] = useState(true);
  const teamAWicketkeepers = playerStats.teamA_WicketKeepers.filter((player) => player.status === 'Available');
  const teamABatters = playerStats.teamA_Batters.filter((player) => player.status === 'Available');
  const teamAAllRounders = playerStats.teamA_AllRounders.filter((player) => player.status === 'Available');
  const teamABowlers = playerStats.teamA_Bowlers.filter((player) => player.status === 'Available');
  const teamBWicketkeepers = playerStats.teamB_WicketKeepers.filter((player) => player.status === 'Available');
  const teamBBatters = playerStats.teamB_Batters.filter((player) => player.status === 'Available');
  const teamBAllRounders = playerStats.teamB_AllRounders.filter((player) => player.status === 'Available');
  const teamBBowlers = playerStats.teamB_Bowlers.filter((player) => player.status === 'Available');

  // Get head-to-head stats
  const h2hStats = getH2HWinsAndLosses(matchInfo.teamAShortName, matchInfo.teamBShortName, teamStats.h2hMatches);

  const charts = [];
  if (teamStats.h2hMatches.length > 0) {
    charts.push(getH2HPlayerMostRunsChart([...teamAWicketkeepers, ...teamABatters, ...teamAAllRounders, ...teamBWicketkeepers, ...teamBBatters, ...teamBAllRounders], teamStats.h2hBattingPerformances));
    charts.push(getH2HPlayerMostWicketsChart([...teamAAllRounders, ...teamBAllRounders, ...teamABowlers, ...teamBBowlers], teamStats.h2hBowlingPerformances));
  }

  return (
    <div>
      <div className="statsNguts__teamh2h_trends">
        <button type="button" onClick={() => setIsTrendsExpanded(!isTrendsExpanded)} className="statsNguts__teamh2h_trends_toggle">
          Trends
        </button>
        {isTrendsExpanded && (
          <>
            <div className="statsNguts__teamh2h_statsWrapper">
              <div className="statsNguts__teamh2h_statsTotalMatchesHeader">
                Head-to-Head Matches since IPL 2022
              </div>
              <div className="statsNguts__teamh2h_statsTotalMatchesContainer">
                <div className="statsNguts__teamh2h_statsTeamSection teamA">
                  <img src={require(`../../../../../assets/image/teams/${matchInfo.teamAAvatar}`)} alt="Team A Logo" className="statsNguts__teamh2h_teamLogo" />
                  <div className="statsNguts__teamh2h_stats">
                    <div className="statsNguts__teamh2h_stats_stat">
                      <div className="statsNguts__teamh2h_stats_statTitle">Won</div>
                      <div className="statsNguts__teamh2h_stats_statValue">{h2hStats.teamATotalWins}</div>
                    </div>
                  </div>
                </div>
                <div className="statsNguts__teamh2h_vs">VS</div>
                <div className="statsNguts__teamh2h_statsTeamSection teamB">
                  <div className="statsNguts__teamh2h_stats">
                    <div className="statsNguts__teamh2h_stats_stat">
                      <div className="statsNguts__teamh2h_stats_statTitle">Won</div>
                      <div className="statsNguts__teamh2h_stats_statValue">{h2hStats.teamBTotalWins}</div>
                    </div>
                  </div>
                  <img src={require(`../../../../../assets/image/teams/${matchInfo.teamBAvatar}`)} alt="Team B Logo" className="statsNguts__teamh2h_teamLogo" />
                </div>
              </div>
            </div>
            {charts.map((chart, index) => (
              <div key={index} className="statsNguts__teamh2h_chartContainer">
                <Chart
                  chartType={chart.chartType}
                  data={chart.data}
                  options={chart.options}
                />
              </div>
            ))}
            <div className="statsNguts__teamh2h_card_teamStats">
              <Team teamStats={teamStats} matchInfo={matchInfo} playerStats={playerStats} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Teamh2hTrends;
