/* eslint-disable react/forbid-prop-types */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import PropTypes from 'prop-types';
import './fantasyCard.css';

const FantasyCard = ({ fantasyCard }) => {
  const playerImage = require(`../../../assets/image/players/${fantasyCard.image}`);

  return (
    <div className="statsNguts__fantasyCard">
      {/* Player Image */}
      <div className="statsNguts__fantasyCard_cardImage">
        <img src={playerImage} alt={fantasyCard.name} />
      </div>

      {/* Player Name Box */}
      <div className="statsNguts__fantasyCard_playerNameBox">
        <h2 className="statsNguts__fantasyCard_playerName">{fantasyCard.name}</h2>
      </div>

      {/* Player Country Box */}
      <div className="statsNguts__fantasyCard_playerTeamBox">
        <p className="statsNguts__fantasyCard_playerTeam">{fantasyCard.team}</p>
      </div>

      {/* Player Stats */}
      <div className="statsNguts__fantasyCard_cardContent">
        <ul className="statsNguts__fantasyCard_playerStats">
          {Object.entries(fantasyCard.details).map(([key, value], index) => (
            <li key={index}>
              <strong>{key}:</strong> <span>{value}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FantasyCard.propTypes = {
  player: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    team: PropTypes.string.isRequired,
    details: PropTypes.object.isRequired,
  }).isRequired,
};

export default FantasyCard;
