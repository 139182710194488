import React from 'react';
import './about.css';

const About = () => (
  <div className="statsNguts__about" id="about">
    <div className="statsNguts__about_heading">
      <h1 className="gradient__text">About Us</h1>
      <h3>We are passionate about sports and understand the importance of statistics and data in the game. Our mission is to provide the sports community with top-quality statistics, enabling them to analyze game data and make informed decisions while creating a fantasy team.
        <br /><br />
        We strive to develop a robust and intelligent tool that helps users generate multiple fantasy teams with different combinations, backed by comprehensive game data. Our goal is to maximize the potential of statistical insights, ensuring users can make well-informed choices.
      </h3>
    </div>
  </div>
);

export default About;
