const awsconfig = {
  Auth: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_viU9Vp3OH',
    aws_user_pools_web_client_id: '5d30h3shbgle9ct1vmch977q5b',
    oauth: {
      domain: 'statsnguts.auth.us-east-1.amazoncognito.com',
      scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://statsnguts.com/',
      redirectSignOut: 'https://statsnguts.com/',
      responseType: 'code',
    },
  },
};

export default awsconfig;
