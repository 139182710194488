/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import './matchStats.css';
import PlayerStats from '../playerStats/PlayerStats.jsx';
import GroundStats from '../groundStats/GroundStats.jsx';
import TeamStats from '../teamStats/TeamStats.jsx';
import MatchNews from '../matchNews/MatchNews.jsx';
import PostMatchAnalysis from '../postMatchAnalysis/PostMatchAnalysis.jsx';
import { fetchMatchStats } from '../../../../../api/dataService.js';

const MatchStats = ({ seriesId, matchNumber, matchId }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);
  const [activeComponent, setActiveComponent] = useState('playerStats');
  const playerStatsRef = useRef(null);
  const groundStatsRef = useRef(null);
  const teamStatsRef = useRef(null);
  const matchNewsRef = useRef(null);
  const postMatchAnalysisRef = useRef(null);

  const getRefForActiveComponent = (component) => {
    switch (component) {
      case 'playerStats':
        return playerStatsRef;
      case 'groundStats':
        return groundStatsRef;
      case 'teamStats':
        return teamStatsRef;
      case 'matchNews':
        return matchNewsRef;
      case 'postMatchAnalysis':
        return postMatchAnalysisRef;
      default:
        return null;
    }
  };

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const matches = await fetchMatchStats(seriesId, matchNumber, matchId);
        if (isMounted.current) {
          setData(matches);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, [matchNumber, matchId]);

  useEffect(() => {
    const ref = getRefForActiveComponent(activeComponent);
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeComponent]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Awesome Match Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const { matchInfo } = data;
  const { groundStats } = data;
  const { teamStats } = data;
  const { news } = data;
  const { playerStats } = data;

  ReactGA.send({ hitType: 'pageview', page: '/matchstats', title: `IPL 2024 MatchStats Page - Match ${matchId}` });

  const handleButtonClick = (component) => {
    ReactGA.event({
      category: 'User Actions',
      action: `${component}-Click`,
      label: 'IPL-2024-MatchStats',
    });

    let ref;
    switch (component) {
      case 'playerStats':
        ref = playerStatsRef;
        break;
      case 'groundStats':
        ref = groundStatsRef;
        break;
      case 'teamStats':
        ref = teamStatsRef;
        break;
      case 'matchNews':
        ref = matchNewsRef;
        break;
      case 'postMatchAnalysis':
        ref = postMatchAnalysisRef;
        break;
      default:
        return;
    }

    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    setActiveComponent(component);
  };

  const groundStatsDescription = 'Statistics from recent IPL and T20I matches played on the ground';
  const teamStatsDescription = 'Statistics from last 10 head to head IPL matches, and last 10 IPL matches of respective teams';

  return (
    <div className="statsNguts__matchStats section__padding" id="matchStats">
      <div className="statsNguts__matchStats_info">
        <div className="statsNguts__matchStats_avatarContainer">
          <img className="statsNguts__matchStats_avatarContainer_avatar" src={require(`../../../../../assets/image/teams/${matchInfo.matchAvatar}`)} alt="matchAvatar" />
        </div>
        <div className="statsNguts__matchStats_headingContainer">
          <div className="statsNguts__matchStats_headingBox">
            <h1 className="gradient__text">Match { matchInfo.matchNumber } - Stats & News</h1>
            <h2>{matchInfo.teamAFullName} Vs {matchInfo.teamBFullName}</h2>
            <h3>{matchInfo.matchGroundFullName}, {matchInfo.matchGroundCity}</h3>
            <h4>{matchInfo.matchDate}, {matchInfo.matchTime}</h4>
          </div>
        </div>
      </div>

      <div className="statsNguts__matchStats_buttons">
        <div className="statsNguts__matchStats_buttons_stats_buttons">
          <button type="button" onClick={() => handleButtonClick('playerStats')}>Player Stats</button>
          <button type="button" onClick={() => handleButtonClick('groundStats')}>Ground Stats</button>
          <button type="button" onClick={() => handleButtonClick('teamStats')}>Team Stats</button>
          <button type="button" onClick={() => handleButtonClick('matchNews')}>Match News</button>
        </div>
        <div className="statsNguts__matchStats_buttons_postMatchAnalysisButton">
          <button type="button" onClick={() => handleButtonClick('postMatchAnalysis')}>Post Match Analysis</button>
        </div>
      </div>

      {activeComponent === 'playerStats' && <PlayerStats ref={playerStatsRef} matchInfo={matchInfo} playerStats={playerStats} />}
      {activeComponent === 'groundStats' && <GroundStats ref={groundStatsRef} matchInfo={matchInfo} groundStats={groundStats} desc={groundStatsDescription} />}
      {activeComponent === 'teamStats' && <TeamStats ref={teamStatsRef} matchInfo={matchInfo} teamStats={teamStats} playerStats={playerStats} desc={teamStatsDescription} />}
      {activeComponent === 'matchNews' && <MatchNews ref={matchNewsRef} matchNews={news} />}
      {activeComponent === 'postMatchAnalysis' && <PostMatchAnalysis ref={postMatchAnalysisRef} seriesId={seriesId} matchInfo={matchInfo} matchNumber={matchNumber} matchId={matchId} />}
    </div>
  );
};

export default MatchStats;
