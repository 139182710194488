/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { fetchSeriesStats } from '../../../../../api/dataService';
import PublishedMatches from '../../../../common/publishedMatches/PublishedMatches';
import CompletedMatches from '../../../../common/completedMatches/CompletedMatches';
import UpcomingMatches from '../../../../common/upcomingMatches/UpcomingMatches';
import './seriesDescription.css';

const SeriesDescription = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);
  const seriesId = 'SL-VS-NZ-T20I-NOV-2024';
  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const seriesStats = await fetchSeriesStats(seriesId);
        if (isMounted.current) {
          setData(seriesStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Series Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const seriesStats = [
    {
      statName: 'Most Runs',
      stats: data.seriesStats.MostRuns.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Runs} Runs`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Wickets',
      stats: data.seriesStats.MostWickets.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Wickets} Wickets`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Strike Rate',
      stats: data.seriesStats.BestStrikeRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `SR: ${stat.StrikeRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Economy Rate',
      stats: data.seriesStats.BestEconomyRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Fours',
      stats: data.seriesStats.MostFours.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.TotalFours} Fours`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Sixes',
      stats: data.seriesStats.MostSixes.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.TotalSixes} Sixes`,
        isTop: i === 0,
      })),
    },
  ];

  return (
    <div className="statsNguts__seriesDescription_T20I section__padding" id="seriesDescription">
      <div className="statsNguts__seriesDescription_T20I_heading">
        <h1 className="gradient__text">New Zealand tour of Sri Lanka, November 2024</h1>
      </div>
      <div className="statsNguts__seriesDescription_T20I_text">
        <p>
          The T20 rivalry between New Zealand and Sri Lanka is marked by intense competition and unforgettable performances. Over the years, both teams have demonstrated their unique styles—New Zealand with their strategic approach and resilience, and Sri Lanka with their flair and unpredictable, aggressive gameplay. Their matches are often closely contested, featuring power-packed batting, clever bowling strategies, and fielding excellence that keeps fans on the edge of their seats. Notable encounters in ICC tournaments and bilateral series have contributed to this growing rivalry, with both teams frequently pushing each other to the limit.
        </p>
        <p>
          One of the most memorable moments in their T20 history came during the 2012 ICC T20 World Cup, where Sri Lanka triumphed over New Zealand in a thrilling Super Over. The game displayed the best of both team&apos;s cricketing prowess, with Sri Lanka&apos;s bowlers holding their nerve in the final moments. More recently, encounters between the two sides have continued to produce exciting finishes and individual brilliance, from hard-hitting batsmen like Martin Guptill to crafty bowlers like Wanindu Hasaranga. Each match in this rivalry promises an exhilarating display of T20 cricket, where experience meets youthful energy and tactical depth meets raw talent.
        </p>
      </div>
      <div className="statsNguts__seriesResult">
        <p className="statsNguts__seriesResult_text">Series Drawn 1-1</p>
        <p className="statsNguts__seriesResult_player">Player of the Series: Wanindu Hasaranga</p>
      </div>
      <div className="statsNguts__seriesDescription_T20I_stats">
        {seriesStats.map((statCategory, index) => (
          <div key={index} className="statsNguts__seriesDescription_T20I_statItem">
            <h3>{statCategory.statName}</h3>
            <div className="statsNguts__seriesDescription_T20I_statList">
              {statCategory.stats.map((stat, i) => (
                <div
                  key={i}
                  className={`statsNguts__seriesDescription_T20I_statDetail ${stat.isTop ? 'highlight' : ''}`}
                >
                  <img
                    src={require(`../../../../../assets/image/players/${stat.photo}`)}
                    alt={stat.playerName}
                    className="statsNguts__seriesDescription_T20I_photo"
                  />
                  <p className="statsNguts__seriesDescription_T20I_player">{stat.playerName}</p>
                  <p className="statsNguts__seriesDescription_T20I_value">{stat.statValue}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <PublishedMatches key="publishedMatchesSLVSNZT20INOV2024" seriesId={seriesId} />,
      <UpcomingMatches key="upcomingMatchesSLVSNZT20INOV2024" seriesId={seriesId} />,
      <CompletedMatches key="completedMatchesSLVSNZT20INOV2024" seriesId={seriesId} />,
    </div>
  );
};

export default SeriesDescription;
