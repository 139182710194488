/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchSeriesStats } from '../../../../../api/dataService';
import PublishedMatches from '../../../../common/publishedMatches/PublishedMatches';
import CompletedMatches from '../../../../common/completedMatches/CompletedMatches';
import UpcomingMatches from '../../../../common/upcomingMatches/UpcomingMatches';
import './seriesDescription.css';

const SeriesDescription = () => {
  const [seriesStatsdata, setSeriesStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);
  const seriesId = 'IND-VS-ENG-T20I-JAN-FEB-2025';
  const navigate = useNavigate();
  const handleTeamClick = (teamShortName) => {
    navigate(`T20I/team/${teamShortName}`); // Navigate to team page with team name in URL
  };

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const seriesStats = await fetchSeriesStats(seriesId);
        if (isMounted.current) {
          setSeriesStatsData(seriesStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Series Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const seriesStats = [
    {
      statName: 'Most Runs',
      stats: seriesStatsdata.seriesStats.MostRuns.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Runs} Runs`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Wickets',
      stats: seriesStatsdata.seriesStats.MostWickets.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Wickets} Wickets`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Strike Rate',
      stats: seriesStatsdata.seriesStats.BestStrikeRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `SR: ${stat.StrikeRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Economy Rate',
      stats: seriesStatsdata.seriesStats.BestEconomyRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
  ];

  const teamsData = seriesStatsdata.seriesStats.Teams;

  return (
    <div className="statsNguts__seriesDescription_T20I section__padding" id="seriesDescription">
      <div className="statsNguts__seriesDescription_T20I_heading">
        <h1 className="gradient__text">England tour of India, January-February 2025</h1>
      </div>
      <div className="statsNguts__seriesDescription_T20I_text">
        <p>
          The cricket rivalry between India and England in T20 Internationals (T20Is) is one of the most exciting and competitive matchups in the sport. Known for their contrasting playing styles, India, with its flair and spin dominance, and England, with its aggressive power-hitting and pace attack, have delivered numerous thrilling encounters in the shortest format.
        </p>
        <p>
          Over the years, iconic performances such as Yuvraj Singh&apos;s six sixes in an over against Stuart Broad in 2007 and England&apos;s comeback series win in 2022 have added to the intensity of this rivalry. Both teams continue to vie for supremacy in global tournaments, making their clashes must-watch events for cricket fans worldwide.
        </p>
      </div>

      <div className="statsNguts__seriesDescription_T20I_teams_heading">
        <h1 className="gradient__text">Teams</h1>
      </div>

      {/* Teams List */}
      <div className="statsNguts__seriesDescription_T20I_teams_grid">
        {teamsData.map((team, index) => (
          <div
            key={index}
            className="statsNguts__seriesDescription_T20I_teams_card"
            onClick={() => handleTeamClick(team.TeamShortName)}
          >
            <div className="statsNguts__seriesDescription_T20I_teams_images">
              <img src={require(`../../../../../assets/image/players/${team.TeamCaptainAvatar}`)} alt={`${team.TeamName} Captain`} className="captain-img" />
              <img src={require(`../../../../../assets/image/teams/${team.TeamAvatar}`)} alt={`${team.TeamName} Logo`} className="team-logo" />
            </div>
          </div>
        ))}
      </div>

      <div className="statsNguts__seriesDescription_T20I_statsSection">
        <h2 className="section-heading">Series Stats</h2>

        {/* Series Result */}
        <div className="statsNguts__seriesResult_inside">
          <p className="statsNguts__seriesResult_text">India won the 5-match series 4-1</p>
          <p className="statsNguts__seriesResult_player">Player of the Series: Varun Chakravarthy</p>
        </div>

        {/* Series Stats */}
        <div className="statsNguts__seriesDescription_T20I_stats">
          {seriesStats.map((statCategory, index) => (
            <div key={index} className="statsNguts__seriesDescription_T20I_statItem">
              <h3>{statCategory.statName}</h3>
              <div className="statsNguts__seriesDescription_T20I_statList">
                {statCategory.stats.map((stat, i) => (
                  <div
                    key={i}
                    className={`statsNguts__seriesDescription_T20I_statDetail ${stat.isTop ? 'highlight' : ''}`}
                  >
                    <img
                      src={require(`../../../../../assets/image/players/${stat.photo}`)}
                      alt={stat.playerName}
                      className="statsNguts__seriesDescription_T20I_photo"
                    />
                    <p className="statsNguts__seriesDescription_T20I_player">{stat.playerName}</p>
                    <p className="statsNguts__seriesDescription_T20I_value">{stat.statValue}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <PublishedMatches key="publishedMatchesINDVSENGT20IJANFEB2025" seriesId={seriesId} />
        <UpcomingMatches key="upcomingMatchesINDVSENGT20IJANFEB2025" seriesId={seriesId} />
        <CompletedMatches key="completedMatchesINDVSENGT20IJANFEB2025" seriesId={seriesId} />
      </div>
    </div>
  );
};

export default SeriesDescription;
