/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import './playerMatchup.css'; // Ensure this is the correct path to your CSS file

const PlayerMatchup = ({ batter, bowler, stats, highlight }) => (
  <div className={`statsNguts__playerMatchup ${highlight ? 'highlight' : ''}`}>
    <div className="statsNguts__playerMatchup_header">
      <div className="statsNguts__playerMatchup_imageWrapper left">
        <img src={require(`../../../../../assets/image/players/${batter.image}`)} alt={batter.name} className="statsNguts__playerMatchup_image" />
        <p className="statsNguts__playerMatchup_name">{batter.name}</p>
      </div>
      <p className="statsNguts__playerMatchup_vs">Vs</p>
      <div className="statsNguts__playerMatchup_imageWrapper right">
        <img src={require(`../../../../../assets/image/players/${bowler.image}`)} alt={bowler.name} className="statsNguts__playerMatchup_image" />
        <p className="statsNguts__playerMatchup_name">{bowler.name}</p>
      </div>
    </div>

    <div className="statsNguts__playerMatchup_stats">
      <table>
        <thead>
          <tr>
            <th>Runs</th>
            <th>Balls</th>
            <th>Wickets</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{stats.runs}</td>
            <td>{stats.balls}</td>
            <td>{stats.wickets}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default PlayerMatchup;
