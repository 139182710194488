export const getAverageInningScoresChart = (firstInningsScores, secondInningsScore) => {
  let firstInningScoreSum = 0;
  const firstInningScoresLength = firstInningsScores.length;
  for (let i = 0; i < firstInningScoresLength; i += 1) {
    firstInningScoreSum += firstInningsScores[i].Runs;
  }
  const firstInningAverageScores = Math.floor(firstInningScoreSum / firstInningScoresLength);

  let secondInningScoreSum = 0;
  const secondInningScoresLength = secondInningsScore.length;
  for (let i = 0; i < secondInningScoresLength; i += 1) {
    secondInningScoreSum += secondInningsScore[i].Runs;
  }
  const secondInningAverageScores = Math.floor(secondInningScoreSum / secondInningScoresLength);

  const averageInningScoresChartData = [
    ['Inning', 'Average Scores', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
    ['First Inning', firstInningAverageScores, '#ADD8E6', firstInningAverageScores],
    ['Second Inning', secondInningAverageScores, '#2C3E50', secondInningAverageScores],
  ];

  const averageInningScoresChartOptions = {
    title: 'Average Scores Per Innings in IPL (2022, 2023, 2024) and Recent T20I Matches on Ground',
    hAxis: {
    },
    vAxis: {
      title: 'Runs',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const averageInningScoresChart = {
    chartType: 'ColumnChart',
    data: averageInningScoresChartData,
    options: averageInningScoresChartOptions,
  };

  return averageInningScoresChart;
};

export const getWicketTakenByFastAndSlowBowlerChart = (wicketTakenByFastBowlers, wicketTakenBySlowBowlers) => {
  let wicketsByFastBowlersSum = 0;
  const wicketsByFastBowlersLength = wicketTakenByFastBowlers.length;
  for (let i = 0; i < wicketsByFastBowlersLength; i += 1) {
    wicketsByFastBowlersSum += wicketTakenByFastBowlers[i].Wickets;
  }

  let wicketsBySlowBowlersSum = 0;
  const wicketsBySlowBowlersLength = wicketTakenBySlowBowlers.length;
  for (let i = 0; i < wicketsBySlowBowlersLength; i += 1) {
    wicketsBySlowBowlersSum += wicketTakenBySlowBowlers[i].Wickets;
  }

  const wicketTakenByFastAndSlowBowlerChartData = [
    ['Bowler Type', 'Wicket Percentage'],
    ['Fast Bowlers', wicketsByFastBowlersSum],
    ['Slow Bowlers', wicketsBySlowBowlersSum],
  ];

  const wicketTakenByFastAndSlowBowlerChartOptions = {
    title: 'Wickets Distribution in IPL (2022, 2023, 2024) and Recent T20I Matches on Ground',
  };

  const wicketTakenByFastAndSlowBowlerChart = {
    chartType: 'PieChart',
    data: wicketTakenByFastAndSlowBowlerChartData,
    options: wicketTakenByFastAndSlowBowlerChartOptions,
  };

  return wicketTakenByFastAndSlowBowlerChart;
};

export const getLastFiveRunsScoredChart = (runsScored) => {
  const lastFiveRunsScored = runsScored.slice(0, 5);
  const lastFiveRunsScoredChartData = [
    [
      'Match',
      'Runs',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
  ];

  for (let i = 0; i < 5 && i < lastFiveRunsScored.length; i += 1) {
    const runs = lastFiveRunsScored[i].Runs;
    const entry = [lastFiveRunsScored[i].Date, runs, '#ADD8E6', runs];
    lastFiveRunsScoredChartData.push(entry);
  }

  const lastFiveRunsScoredChartOptions = {
    title: 'Runs Scored in Last 5 IPL Innings',
    hAxis: {
      title: 'Runs',
      format: '0',
    },
    vAxis: {
      title: 'Matches (Most Recent on Top)',
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const lastFiveRunsScoredChart = {
    chartType: 'BarChart',
    data: lastFiveRunsScoredChartData,
    options: lastFiveRunsScoredChartOptions,
  };

  return lastFiveRunsScoredChart;
};

export const getStrikeRateAndBallsFacedChart = (runsScored) => {
  const lastFiveRunsScored = runsScored.slice(0, 5);
  lastFiveRunsScored.reverse();
  const strikeRateAndBallsFacedChartData = [
    ['x', 'strike rate', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'balls faced', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < 10 && i < lastFiveRunsScored.length; i += 1) {
    const strikeRate = Math.ceil((lastFiveRunsScored[i].Runs / lastFiveRunsScored[i].Balls) * 100);
    const balls = lastFiveRunsScored[i].Balls;
    const entry = [lastFiveRunsScored[i].Date, strikeRate, strikeRate, balls, balls];
    strikeRateAndBallsFacedChartData.push(entry);
  }

  const strikeRateAndBallsFacedChartOption = {
    title: 'Strike Rate and Balls Faced in Last IPL 5 Innings',
    hAxis: {
      title: 'Matches (Most Recent on Right)',
      slantedText: true,
      slantedTextAngle: 45,
    },
    vAxis: {
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    series: {
      1: { curveType: 'none' },
    },
    legend: { position: 'top' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const strikeRateAndBallsFacedChart = {
    chartType: 'LineChart',
    data: strikeRateAndBallsFacedChartData,
    options: strikeRateAndBallsFacedChartOption,
  };

  return strikeRateAndBallsFacedChart;
};

export const getLastFiveWicketsTakenChart = (wicketsTaken) => {
  const lastFiveWicketsTaken = wicketsTaken.slice(0, 5);
  const lastFiveWicketsTakenChartData = [
    [
      'Match',
      'Wickets',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
  ];

  for (let i = 0; i < 5 && i < lastFiveWicketsTaken.length; i += 1) {
    const wickets = lastFiveWicketsTaken[i].Wickets;
    const entry = [lastFiveWicketsTaken[i].Date, wickets, '#ADD8E6', wickets];
    lastFiveWicketsTakenChartData.push(entry);
  }

  const lastFiveWicketsTakenChartOptions = {
    title: 'Wickets Taken in Last 5 IPL Innings',
    hAxis: {
      title: 'Wickets',
      format: '0',
    },
    vAxis: {
      title: 'Matches (Most Recent on Top)',
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const lastFiveWicketsTakenChart = {
    chartType: 'BarChart',
    data: lastFiveWicketsTakenChartData,
    options: lastFiveWicketsTakenChartOptions,
  };

  return lastFiveWicketsTakenChart;
};

export const getEconomyRateAndOversBowledChart = (wicketsTaken) => {
  const lastFiveWicketsTaken = wicketsTaken.slice(0, 5);
  lastFiveWicketsTaken.reverse();

  const economyRateAndBallsBowledChartData = [
    ['x', 'economy rate', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'overs bowled', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < 10 && i < lastFiveWicketsTaken.length; i += 1) {
    const overs = Math.floor(lastFiveWicketsTaken[i].Balls / 6);
    const balls = lastFiveWicketsTaken[i].Balls % 6;
    const oversBowled = `${overs}.${balls}`;
    const economyRate = (lastFiveWicketsTaken[i].Runs / (lastFiveWicketsTaken[i].Balls / 6)).toFixed(2);
    const entry = [lastFiveWicketsTaken[i].Date, parseFloat(economyRate), parseFloat(economyRate), parseFloat(oversBowled), parseFloat(oversBowled)];
    economyRateAndBallsBowledChartData.push(entry);
  }

  const economyRateAndOversBowledChartOption = {
    title: 'Economy Rate and Overs Bowled in Last 5 IPL Innings',
    hAxis: {
      title: 'Matches (Most Recent on Right)',
      textPosition: 'out',
      slantedText: true,
      slantedTextAngle: 45,
    },
    vAxis: {
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    series: {
      1: { curveType: 'none' },
    },
    legend: { position: 'top' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const economyRateAndBallsBowledChart = {
    chartType: 'LineChart',
    data: economyRateAndBallsBowledChartData,
    options: economyRateAndOversBowledChartOption,
  };

  return economyRateAndBallsBowledChart;
};

export const getWinningPercentageByInningChart = (winningPercentageByInnings) => {
  const firstInningWinningPercentage = winningPercentageByInnings.First;
  const secondInningWinningPercentage = winningPercentageByInnings.Second;

  const winningPercentageByInnChartData = [
    ['Inning', 'Winning Percentage'],
    ['First Inning', parseFloat(firstInningWinningPercentage)],
    ['Second Inning', parseFloat(secondInningWinningPercentage)],
  ];

  const winningPercentageByInnChartOptions = {
    title: 'Winning Percentage by Inning in IPL (2022, 2023, 2024) and Recent T20I Matches on Ground',
  };

  const winningPercentageByInnChart = {
    chartType: 'PieChart',
    data: winningPercentageByInnChartData,
    options: winningPercentageByInnChartOptions,
  };

  return winningPercentageByInnChart;
};

export const getAverageInningWicketsChart = (firstInningsScores, secondInningsScore) => {
  let firstInningWicketsSum = 0;
  const firstInningWicketsLength = firstInningsScores.length;
  for (let i = 0; i < firstInningWicketsLength; i += 1) {
    firstInningWicketsSum += firstInningsScores[i].Wickets;
  }
  const firstInningAverageWickets = Math.floor(firstInningWicketsSum / firstInningWicketsLength);

  let secondInningWicketsSum = 0;
  const secondInningWicketsLength = secondInningsScore.length;
  for (let i = 0; i < secondInningWicketsLength; i += 1) {
    secondInningWicketsSum += secondInningsScore[i].Wickets;
  }
  const secondInningAverageWickets = Math.floor(secondInningWicketsSum / secondInningWicketsLength);

  const averageInningWicketsChartData = [
    ['Inning', 'Average Wickets', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
    ['First Inning', firstInningAverageWickets, '#ADD8E6', firstInningAverageWickets],
    ['Second Inning', secondInningAverageWickets, '#2C3E50', secondInningAverageWickets],
  ];

  const averageInningWicketsChartOptions = {
    title: 'Average Wickets Per Inning in IPL (2022, 2023, 2024) and Recent T20I Matches on Ground',
    hAxis: {
    },
    vAxis: {
      title: 'Wickets',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const averageInningWicketsChart = {
    chartType: 'ColumnChart',
    data: averageInningWicketsChartData,
    options: averageInningWicketsChartOptions,
  };

  return averageInningWicketsChart;
};

export const getH2HPlayerMostRunsChart = (players, h2hBattingPerformances) => {
  const allPlayerRunScoredInH2HMatches = [];
  const playerMap = new Map();
  for (let i = 0; i < h2hBattingPerformances.length; i += 1) {
    const { Player, Runs } = h2hBattingPerformances[i];
    if (playerMap.has(Player)) {
      let totalRuns = playerMap.get(Player);
      totalRuns += Runs;
      playerMap.set(Player, totalRuns);
    } else {
      playerMap.set(Player, Runs);
    }
  }

  for (let i = 0; i < players.length; i += 1) {
    const playerName = players[i].name;
    if (playerMap.has(playerName)) {
      const player = {
        Name: playerName,
        Runs: playerMap.get(playerName),
      };
      allPlayerRunScoredInH2HMatches.push(player);
    } else {
      const player = {
        Name: playerName,
        Runs: 0,
      };
      allPlayerRunScoredInH2HMatches.push(player);
    }
  }

  allPlayerRunScoredInH2HMatches.sort((a, b) => b.Runs - a.Runs);
  const topFivePlayers = allPlayerRunScoredInH2HMatches.slice(0, 5);

  const h2hPlayerMostRunsChartData = [
    ['Player', 'Runs', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topFivePlayers.length; i += 1) {
    const entry = [];
    entry.push(topFivePlayers[i].Name);
    entry.push(topFivePlayers[i].Runs);
    entry.push('#FFA500');
    entry.push(topFivePlayers[i].Runs);

    h2hPlayerMostRunsChartData.push(entry);
  }

  const h2hPlayerMostRunsChartOptions = {
    title: 'Most Runs (Head-to-Head Matches since IPL 2022)',
    hAxis: {
    },
    vAxis: {
      title: 'Runs',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const h2hPlayerMostRunsChart = {
    chartType: 'ColumnChart',
    data: h2hPlayerMostRunsChartData,
    options: h2hPlayerMostRunsChartOptions,
  };

  return h2hPlayerMostRunsChart;
};

export const getH2HPlayerMostWicketsChart = (players, h2hBowlingPerformances) => {
  const allPlayerWicketsTakenInH2HMatches = [];
  const playerMap = new Map();
  for (let i = 0; i < h2hBowlingPerformances.length; i += 1) {
    const { Player, Wickets } = h2hBowlingPerformances[i];
    if (playerMap.has(Player)) {
      let totalWickets = playerMap.get(Player);
      totalWickets += Wickets;
      playerMap.set(Player, totalWickets);
    } else {
      playerMap.set(Player, Wickets);
    }
  }

  for (let i = 0; i < players.length; i += 1) {
    const playerName = players[i].name;
    if (playerMap.has(playerName)) {
      const player = {
        Name: playerName,
        Wickets: playerMap.get(playerName),
      };
      allPlayerWicketsTakenInH2HMatches.push(player);
    } else {
      const player = {
        Name: playerName,
        Wickets: 0,
      };
      allPlayerWicketsTakenInH2HMatches.push(player);
    }
  }

  allPlayerWicketsTakenInH2HMatches.sort((a, b) => b.Wickets - a.Wickets);
  const topFivePlayers = allPlayerWicketsTakenInH2HMatches.slice(0, 5);

  const h2hPlayerMostWicketsChartData = [
    ['Player', 'Wickets', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topFivePlayers.length; i += 1) {
    const entry = [];
    entry.push(topFivePlayers[i].Name);
    entry.push(topFivePlayers[i].Wickets);
    entry.push('#8470FF');
    entry.push(topFivePlayers[i].Wickets);

    h2hPlayerMostWicketsChartData.push(entry);
  }

  const h2hPlayerMostWicketsChartOptions = {
    title: 'Most Wickets (Head-to-Head Matches since IPL 2022)',
    hAxis: {
    },
    vAxis: {
      title: 'Wickets',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const h2hPlayerMostWicketsChart = {
    chartType: 'ColumnChart',
    data: h2hPlayerMostWicketsChartData,
    options: h2hPlayerMostWicketsChartOptions,
  };

  return h2hPlayerMostWicketsChart;
};

export const getLastFiveRunsScoredAcrossFormatsChart = (runsScored, internationalT20RunsScored, domesticT20RunsScored, internationalT20LeagueRunsScored) => {
  const lastFiveRunsScored = runsScored.slice(0, 5);
  const lastFiveRunsScoredDomesticT20 = internationalT20RunsScored.slice(0, 5);
  const lastFiveRunsScoredInternationalT20 = domesticT20RunsScored.slice(0, 5);
  const lastFiveRunsScoredInternationalT20League = internationalT20LeagueRunsScored.slice(0, 5);
  const lastTwentyRunsScored = [];

  for (let j = 0; j < lastFiveRunsScored.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScored[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredDomesticT20.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredDomesticT20[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredInternationalT20.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredInternationalT20[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredInternationalT20League.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredInternationalT20League[j]);
  }

  lastTwentyRunsScored.sort((a, b) => {
    const dateA = new Date(a.Date.split('-').reverse().join('-'));
    const dateB = new Date(b.Date.split('-').reverse().join('-'));
    return dateB - dateA;
  });

  const lastFiveRunsScoredAcrossFormats = lastTwentyRunsScored.slice(0, 5);

  const lastFiveRunsScoredAcrossFormatsChartData = [
    [
      'Match',
      'Runs',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
  ];

  for (let i = 0; i < 5 && i < lastFiveRunsScoredAcrossFormats.length; i += 1) {
    const runs = lastFiveRunsScoredAcrossFormats[i].Runs;
    const entry = [lastFiveRunsScoredAcrossFormats[i].Date, runs, '#ADD8E6', runs];
    lastFiveRunsScoredAcrossFormatsChartData.push(entry);
  }

  const lastFiveRunsScoredAcrossFormatsChartOptions = {
    title: 'Runs Scored in Last 5 T20 Innings, including IPL, India Domestic T20, T20I & Other T20 Leagues',
    hAxis: {
      title: 'Runs',
      format: '0',
    },
    vAxis: {
      title: 'Matches (Most Recent on Top)',
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const lastFiveRunsScoredAcrossFormatsChart = {
    chartType: 'BarChart',
    data: lastFiveRunsScoredAcrossFormatsChartData,
    options: lastFiveRunsScoredAcrossFormatsChartOptions,
  };

  return lastFiveRunsScoredAcrossFormatsChart;
};

export const getStrikeRateAndBallsFacedAcrossFormatsChart = (runsScored, internationalT20RunsScored, domesticT20RunsScored, internationalT20LeagueRunsScored) => {
  const lastFiveRunsScored = runsScored.slice(0, 5);
  const lastFiveRunsScoredDomesticT20 = domesticT20RunsScored.slice(0, 5);
  const lastFiveRunsScoredInternationalT20 = internationalT20RunsScored.slice(0, 5);
  const lastFiveRunsScoredInternationalT20League = internationalT20LeagueRunsScored.slice(0, 5);
  const lastTwentyRunsScored = [];
  for (let j = 0; j < lastFiveRunsScored.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScored[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredDomesticT20.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredDomesticT20[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredInternationalT20.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredInternationalT20[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredInternationalT20League.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredInternationalT20League[j]);
  }

  lastTwentyRunsScored.sort((a, b) => {
    const dateA = new Date(a.Date.split('-').reverse().join('-'));
    const dateB = new Date(b.Date.split('-').reverse().join('-'));
    return dateB - dateA;
  });

  const lastFiveRunsScoredAcrossFormats = lastTwentyRunsScored.slice(0, 5);
  lastFiveRunsScoredAcrossFormats.reverse();

  const chartData = [
    ['x', 'strike rate', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'balls faced', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < 10 && i < lastFiveRunsScoredAcrossFormats.length; i += 1) {
    const strikeRate = Math.ceil((lastFiveRunsScoredAcrossFormats[i].Runs / lastFiveRunsScoredAcrossFormats[i].Balls) * 100);
    const balls = lastFiveRunsScoredAcrossFormats[i].Balls;
    const entry = [lastFiveRunsScoredAcrossFormats[i].Date, strikeRate, strikeRate, balls, balls];
    chartData.push(entry);
  }

  const chartOption = {
    title: 'Strike Rate and Balls Faced in Last 5 T20 Innings, including IPL, India Domestic T20, T20I & Other T20 Leagues',
    hAxis: {
      title: 'Matches (Most Recent on Right)',
      slantedText: true,
      slantedTextAngle: 45,
    },
    vAxis: {
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    series: {
      1: { curveType: 'none' },
    },
    legend: { position: 'top' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const chart = {
    chartType: 'LineChart',
    data: chartData,
    options: chartOption,
  };

  return chart;
};

export const getLastFiveWicketsTakenAcrossFormatsChart = (wicketsTaken, internationalT20WicketsTaken, domesticT20WicketsTaken, internationalT20LeagueWicketsTaken) => {
  const lastFiveWicketsTaken = wicketsTaken.slice(0, 5);
  const lastFiveWicketTakensDomesticT20 = domesticT20WicketsTaken.slice(0, 5);
  const lastFiveWicketTakensInternationalT20 = internationalT20WicketsTaken.slice(0, 5);
  const lastFiveWicketTakensInternationalT20Leagues = internationalT20LeagueWicketsTaken.slice(0, 5);
  const lastTwentyWicketTakens = [];

  for (let j = 0; j < lastFiveWicketsTaken.length; j += 1) {
    lastTwentyWicketTakens.push(lastFiveWicketsTaken[j]);
  }
  for (let j = 0; j < lastFiveWicketTakensDomesticT20.length; j += 1) {
    lastTwentyWicketTakens.push(lastFiveWicketTakensDomesticT20[j]);
  }
  for (let j = 0; j < lastFiveWicketTakensInternationalT20.length; j += 1) {
    lastTwentyWicketTakens.push(lastFiveWicketTakensInternationalT20[j]);
  }
  for (let j = 0; j < lastFiveWicketTakensInternationalT20Leagues.length; j += 1) {
    lastTwentyWicketTakens.push(lastFiveWicketTakensInternationalT20Leagues[j]);
  }

  lastTwentyWicketTakens.sort((a, b) => {
    const dateA = new Date(a.Date.split('-').reverse().join('-'));
    const dateB = new Date(b.Date.split('-').reverse().join('-'));
    return dateB - dateA;
  });

  const lastFiveWicketTakensAcrossFormats = lastTwentyWicketTakens.slice(0, 5);
  const chartData = [
    [
      'Match',
      'Wickets',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
  ];

  for (let i = 0; i < 5 && i < lastFiveWicketTakensAcrossFormats.length; i += 1) {
    const wickets = lastFiveWicketTakensAcrossFormats[i].Wickets;
    const entry = [lastFiveWicketTakensAcrossFormats[i].Date, wickets, '#ADD8E6', wickets];
    chartData.push(entry);
  }

  const chartOptions = {
    title: 'Wickets Taken in Last 5 T20 Innings, including IPL, India Domestic T20, T20I & Other T20 Leagues',
    hAxis: {
      title: 'Wickets',
      format: '0',
    },
    vAxis: {
      title: 'Matches (Most Recent on Top)',
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const chart = {
    chartType: 'BarChart',
    data: chartData,
    options: chartOptions,
  };

  return chart;
};

export const getEconomyRateAndOversBowledAcrossFormatsChart = (wicketsTaken, internationalT20WicketsTaken, domesticT20WicketsTaken, internationalT20LeagueWicketsTaken) => {
  const lastFiveWicketsTaken = wicketsTaken.slice(0, 5);
  const lastFiveWicketsTakenDomesticT20 = domesticT20WicketsTaken.slice(0, 5);
  const lastFiveWicketsTakenInternationalT20 = internationalT20WicketsTaken.slice(0, 5);
  const lastFiveWicketsTakenInternationalT20Leagues = internationalT20LeagueWicketsTaken.slice(0, 5);
  const lastTwentyWicketsTaken = [];
  for (let j = 0; j < lastFiveWicketsTaken.length; j += 1) {
    lastTwentyWicketsTaken.push(lastFiveWicketsTaken[j]);
  }
  for (let j = 0; j < lastFiveWicketsTakenDomesticT20.length; j += 1) {
    lastTwentyWicketsTaken.push(lastFiveWicketsTakenDomesticT20[j]);
  }
  for (let j = 0; j < lastFiveWicketsTakenInternationalT20.length; j += 1) {
    lastTwentyWicketsTaken.push(lastFiveWicketsTakenInternationalT20[j]);
  }
  for (let j = 0; j < lastFiveWicketsTakenInternationalT20Leagues.length; j += 1) {
    lastTwentyWicketsTaken.push(lastFiveWicketsTakenInternationalT20Leagues[j]);
  }

  lastTwentyWicketsTaken.sort((a, b) => {
    const dateA = new Date(a.Date.split('-').reverse().join('-'));
    const dateB = new Date(b.Date.split('-').reverse().join('-'));
    return dateB - dateA;
  });

  const lastFiveWicketsTakenAcrossFormats = lastTwentyWicketsTaken.slice(0, 5);
  lastFiveWicketsTakenAcrossFormats.reverse();

  const chartData = [
    ['x', 'economy rate', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'overs bowled', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < 10 && i < lastFiveWicketsTakenAcrossFormats.length; i += 1) {
    const overs = Math.floor(lastFiveWicketsTakenAcrossFormats[i].Balls / 6);
    const balls = lastFiveWicketsTakenAcrossFormats[i].Balls % 6;
    const oversBowled = `${overs}.${balls}`;
    const economyRate = (lastFiveWicketsTakenAcrossFormats[i].Runs / (lastFiveWicketsTakenAcrossFormats[i].Balls / 6)).toFixed(2);
    const entry = [lastFiveWicketsTakenAcrossFormats[i].Date, parseFloat(economyRate), parseFloat(economyRate), parseFloat(oversBowled), parseFloat(oversBowled)];
    chartData.push(entry);
  }

  const chartOptions = {
    title: 'Economy Rate and Overs Bowled in Last 5 T20 Innings, including IPL, India Domestic T20, T20I & Other T20 Leagues',
    subtitle: 'xyz',
    hAxis: {
      title: 'Matches (Most Recent on Right)',
      textPosition: 'out',
      slantedText: true,
      slantedTextAngle: 45,
    },
    vAxis: {
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    series: {
      1: { curveType: 'none' },
    },
    legend: { position: 'top' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const chart = {
    chartType: 'LineChart',
    data: chartData,
    options: chartOptions,
  };

  return chart;
};
