import React from 'react';
import CompletedMatches from '../../../../common/completedMatches/CompletedMatches';
import './seriesDescription.css';

const SeriesDescription = () => {
  const seriesId = 'ICC-Mens-T20-WC-2024';

  return (
    <div className="statsNguts__seriesDescription_T20I section__padding" id="seriesDescriptionT20I">
      <div className="statsNguts__seriesDescription_T20I_heading">
        <h1 className="gradient__text">ICC Mens T20 World Cup</h1>
      </div>
      <CompletedMatches key="completedMatchesICCMensT20WC2024" seriesId={seriesId} />
    </div>
  );
};

export default SeriesDescription;
