import React from 'react';
import { useParams } from 'react-router-dom';

import IPL2024MatchMatchStats from './T20Leagues/IPL-2024/matchStats/MatchStats';
import WPL2025MatchMatchStats from './T20Leagues/WPL-2025/matchStats/MatchStats';
import T20IMatchStats from './T20Is/common/matchStats/MatchStats';
import ODIMatchStats from './ODIs/common/matchStats/MatchStats';

const SeriesMatchStats = () => {
  const { seriesId, matchNumber, matchId } = useParams();

  const componentsMap = {
    'IPL-2024': [
      <IPL2024MatchMatchStats key="matchStatsIPL2024" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'ICC-Mens-T20-WC-2024': [
      <T20IMatchStats key="matchStatsICCMensT20WC2024" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'SA-VS-IND-T20I-NOV-2024': [
      <T20IMatchStats key="matchStatsSAVSINDT20INOV2024" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'SL-VS-NZ-T20I-NOV-2024': [
      <T20IMatchStats key="matchStatsSLVSNZT20INOV2024" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'WI-VS-ENG-T20I-NOV-2024': [
      <T20IMatchStats key="matchStatsWIVSENGT20INOV2024" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'IND-VS-ENG-T20I-JAN-FEB-2025': [
      <T20IMatchStats key="matchStatsINDVSENGT20IJANFEB2025" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'IND-VS-ENG-ODI-FEB-2025': [
      <ODIMatchStats key="matchStatsINDVSENGODIFEB2025" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'WPL-2025': [
      <WPL2025MatchMatchStats key="matchStatsWPL2025" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'ICC-CT-2025': [
      <ODIMatchStats key="matchStatsICCCT2025" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
  };

  const componentsToRender = componentsMap[seriesId] || null;

  return <div className="statsNguts__seriesMatchStats">{componentsToRender}</div>;
};

export default SeriesMatchStats;
