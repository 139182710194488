/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchSeriesStats } from '../../../../../api/dataService';
import PublishedMatches from '../../../../common/publishedMatches/PublishedMatches';
import CompletedMatches from '../../../../common/completedMatches/CompletedMatches';
import UpcomingMatches from '../../../../common/upcomingMatches/UpcomingMatches';
import './seriesDescription.css';

const SeriesDescription = () => {
  const [seriesStatsdata, setSeriesStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);
  const seriesId = 'ICC-CT-2025';
  const navigate = useNavigate();
  const handleTeamClick = (teamShortName) => {
    navigate(`ODI/team/${teamShortName}`);
  };

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const seriesStats = await fetchSeriesStats(seriesId);
        if (isMounted.current) {
          setSeriesStatsData(seriesStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Series Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  /*
  const seriesStats = [
    {
      statName: 'Most Runs',
      stats: seriesStatsdata.seriesStats.MostRuns.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Runs} Runs`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Wickets',
      stats: seriesStatsdata.seriesStats.MostWickets.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Wickets} Wickets`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Strike Rate',
      stats: seriesStatsdata.seriesStats.BestStrikeRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `SR: ${stat.StrikeRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Economy Rate',
      stats: seriesStatsdata.seriesStats.BestEconomyRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
  ];
  */

  const teamsData = seriesStatsdata.seriesStats.Teams;

  return (
    <div className="statsNguts__seriesDescription_ODI section__padding" id="seriesDescriptionODI">
      <div className="statsNguts__seriesDescription_ODI_heading">
        <h1 className="gradient__text">ICC Champions Trophy 2025</h1>
      </div>
      <div className="statsNguts__seriesDescription_ODI_text">
        <p>
          The ICC Champions Trophy is a premier One Day International (ODI) cricket tournament organized by the International Cricket Council (ICC). It was first introduced in 1998 as the ICC KnockOut Tournament and was later rebranded as the Champions Trophy in 2002. Initially conceived as a mini-World Cup, the tournament features the top-ranked teams in the ICC ODI rankings, making it one of the most competitive global events in cricket. Unlike the ICC Cricket World Cup, which includes more associate teams, the Champions Trophy is reserved for the elite cricketing nations, ensuring high-quality and closely contested matches. Over the years, the tournament has seen several dominant performances, including Australia&apos;s back-to-back titles in 2006 and 2009, India&apos;s shared victory with Sri Lanka in 2002 due to rain, and Pakistan&apos;s remarkable win in 2017, where they defeated India in a memorable final.
        </p>
        <p>
          The tournament has undergone several format changes and was even discontinued after the 2017 edition, as the ICC shifted its focus toward the World Cup and T20 competitions. However, due to its popularity and shorter duration, the Champions Trophy is set to return in 2025, with Pakistan scheduled to host the event. This revival brings back a tournament known for its intense rivalries, thrilling encounters, and the prestige of being the “mini World Cup.” The return of the Champions Trophy is highly anticipated, as it provides a high-stakes platform for the world&apos;s best ODI teams to showcase their skills and battle for supremacy in a condensed and action-packed format.
        </p>
      </div>

      <div className="statsNguts__seriesDescription_ODI_teams_heading">
        <h1 className="gradient__text">Teams</h1>
      </div>

      <div className="statsNguts__seriesDescription_ODI_teams_grid">
        {teamsData.map((team, index) => (
          <div
            key={index}
            className="statsNguts__seriesDescription_ODI_teams_card"
            onClick={() => handleTeamClick(team.TeamShortName)}
          >
            <div className="statsNguts__seriesDescription_ODI_teams_images">
              <img
                src={require(`../../../../../assets/image/teams/${team.TeamAvatar}`)}
                alt={`${team.TeamName} Logo`}
                className="statsNguts__seriesDescription_ODI_team_logo"
              />
            </div>

            <div className="statsNguts__seriesDescription_ODI_team_name">
              <span>{team.TeamName}</span>
            </div>
          </div>
        ))}
      </div>

      <PublishedMatches key="publishedMatchesINDVSENGT20IJANFEB2025" seriesId={seriesId} />

      {/*
      <div className="statsNguts__seriesDescription_ODI_statsSection">
        <h2 className="statsNguts__seriesDescription_ODI_section_heading">Series Stats</h2>

        <div className="statsNguts__seriesDescription_ODI_series_result_inside">
          <p className="statsNguts__seriesDescription_ODI_series_result_text">India won the 3-match series 3-0</p>
          <p className="statsNguts__seriesDescription_ODI_series_result_player">Player of the Series: Shubman Gill</p>
        </div>

        <div className="statsNguts__seriesDescription_ODI_stats">
          {seriesStats.map((statCategory, index) => (
            <div key={index} className="statsNguts__seriesDescription_ODI_statItem">
              <h3>{statCategory.statName}</h3>
              <div className="statsNguts__seriesDescription_ODI_statList">
                {statCategory.stats.map((stat, i) => (
                  <div
                    key={i}
                    className={`statsNguts__seriesDescription_ODI_statDetail ${stat.isTop ? 'highlight' : ''}`}
                  >
                    <img
                      src={require(`../../../../../assets/image/players/${stat.photo}`)}
                      alt={stat.playerName}
                      className="statsNguts__seriesDescription_ODI_photo"
                    />
                    <p className="statsNguts__seriesDescription_ODI_player">{stat.playerName}</p>
                    <p className="statsNguts__seriesDescription_ODI_value">{stat.statValue}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      */}

      <UpcomingMatches key="upcomingMatchesINDVSENGT20IJANFEB2025" seriesId={seriesId} />
      <CompletedMatches key="completedMatchesINDVSENGT20IJANFEB2025" seriesId={seriesId} />
    </div>
  );
};

export default SeriesDescription;
