/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { fetchSeriesStats } from '../../../../../api/dataService';
import PublishedMatches from '../../../../common/publishedMatches/PublishedMatches';
import CompletedMatches from '../../../../common/completedMatches/CompletedMatches';
import UpcomingMatches from '../../../../common/upcomingMatches/UpcomingMatches';
import './seriesDescription.css';

const SeriesDescription = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);
  const seriesId = 'SA-VS-IND-T20I-NOV-2024';

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const seriesStats = await fetchSeriesStats(seriesId);
        if (isMounted.current) {
          setData(seriesStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Series Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const seriesStats = [
    {
      statName: 'Most Runs',
      stats: data.seriesStats.MostRuns.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Runs} Runs`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Wickets',
      stats: data.seriesStats.MostWickets.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Wickets} Wickets`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Strike Rate',
      stats: data.seriesStats.BestStrikeRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `SR: ${stat.StrikeRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Economy Rate',
      stats: data.seriesStats.BestEconomyRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Fours',
      stats: data.seriesStats.MostFours.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.TotalFours} Fours`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Sixes',
      stats: data.seriesStats.MostSixes.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.TotalSixes} Sixes`,
        isTop: i === 0,
      })),
    },
  ];

  return (
    <div className="statsNguts__seriesDescription_T20I section__padding" id="seriesDescription">
      <div className="statsNguts__seriesDescription_T20I_heading">
        <h1 className="gradient__text">India tour of South Africa, November 2024</h1>
      </div>
      <div className="statsNguts__seriesDescription_T20I_text">
        <p>
          The rivalry between India and South Africa in T20 cricket is a captivating chapter in international cricket, showcasing high-intensity matches, individual brilliance, and thrilling finishes. This rivalry has grown over the years, evolving into a marquee contest, especially in T20 Internationals (T20Is) where both teams bring aggressive styles, star-studded line-ups, and a wealth of talent.
        </p>
        <p>
          The first T20I between India and South Africa took place in the inaugural ICC T20 World Cup in 2007, a tournament where India emerged as champions. This match, held in Durban, saw India defeat South Africa by 37 runs, knocking the Proteas out of the tournament. India&apos;s victory was led by a young Rohit Sharma, whose impactful knock helped India reach a competitive total. This match set the tone for what would become a spirited rivalry in the shortest format of the game.
        </p>
      </div>
      <div className="statsNguts__seriesResult">
        <p className="statsNguts__seriesResult_text">India won the 4-match series 3-1</p>
        <p className="statsNguts__seriesResult_player">Player of the Series: Tilak Varma</p>
      </div>
      <div className="statsNguts__seriesDescription_T20I_stats">
        {seriesStats.map((statCategory, index) => (
          <div key={index} className="statsNguts__seriesDescription_T20I_statItem">
            <h3>{statCategory.statName}</h3>
            <div className="statsNguts__seriesDescription_T20I_statList">
              {statCategory.stats.map((stat, i) => (
                <div
                  key={i}
                  className={`statsNguts__seriesDescription_T20I_statDetail ${stat.isTop ? 'highlight' : ''}`}
                >
                  <img
                    src={require(`../../../../../assets/image/players/${stat.photo}`)}
                    alt={stat.playerName}
                    className="statsNguts__seriesDescription_T20I_photo"
                  />
                  <p className="statsNguts__seriesDescription_T20I_player">{stat.playerName}</p>
                  <p className="statsNguts__seriesDescription_T20I_value">{stat.statValue}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <PublishedMatches key="publishedMatchesSAVSINDT20INOV2024" seriesId={seriesId} />,
      <UpcomingMatches key="upcomingMatchesSAVSINDT20INOV2024" seriesId={seriesId} />,
      <CompletedMatches key="completedMatchesSAVSINDT20INOV2024" seriesId={seriesId} />,
    </div>
  );
};

export default SeriesDescription;
