/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import {
  useAuthenticator,
  Authenticator,
  View,
  useTheme,
  Heading,
  Button,
  ThemeProvider } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { signInWithRedirect } from '@aws-amplify/auth'; // ✅ Added Google Sign-In
import { FcGoogle } from 'react-icons/fc';
import './login.css';

import awsconfig from './awsExports';
import customTheme from './customTheme';

Amplify.configure(awsconfig.Auth);

const signInWithGoogle = async () => {
  try {
    // console.log('🔍 Initiating Google Sign-In...');
    await signInWithRedirect({ provider: 'Google' });
  } catch (error) {
    // console.error('❌ Google sign-in error:', error);
  }
};

// ✅ Custom UI Components (Everything from your original code)
const components = {
  Header() {
    const { tokens } = useTheme();
    return <View textAlign="center" padding={tokens.space.large} />;
  },

  Footer() {
    const { tokens } = useTheme();
    return <View textAlign="center" padding={tokens.space.large} />;
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 ${tokens.space.large} 0`}
          level={3}
          style={{
            fontSize: '26px', // ✅ Slightly larger font size
            fontWeight: 'bold', // ✅ Stronger emphasis
            textAlign: 'center',
            fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif', // ✅ Uses modern system fonts
            color: '#1E3A8A', // ✅ Deep blue for elegance
            letterSpacing: '0.6px', // ✅ Slight spacing for clarity
            textTransform: 'capitalize', // ✅ Makes it more readable
            marginBottom: '14px',
          }}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();
      return (
        <View
          textAlign="center"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            width: '100%',
          }}
        >
          {/* 🔥 Reset Password Button */}
          <Button
            fontWeight="bold"
            onClick={toForgotPassword}
            size="large"
            style={{
              background: 'linear-gradient(135deg,rgb(142, 106, 76),rgb(181, 110, 38))', // ✅ Teal to Blue Gradient
              color: 'white',
              borderRadius: '8px',
              padding: '12px 24px',
              fontSize: '16px',
              boxShadow: '0px 4px 10px rgba(79, 172, 255, 0.3)', // ✅ Soft Glow
              transition: '0.3s ease-in-out',
              border: 'none',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'linear-gradient(135deg, #1FA2FF, #4ECDC4)'; // ✅ Reverse Gradient
              e.target.style.boxShadow = '0px 6px 15px rgba(79, 172, 255, 0.5)'; // ✅ Bigger Glow
              e.target.style.transform = 'translateY(-2px)'; // ✅ Lift Effect
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'linear-gradient(135deg, #4ECDC4, #1FA2FF)';
              e.target.style.boxShadow = '0px 4px 10px rgba(79, 172, 255, 0.3)';
              e.target.style.transform = 'translateY(0px)';
            }}
          >
            🔑 Reset Password
          </Button>

          {/* 🔥 Beautiful Google Sign-In Button (Centered) */}
          <Button
            onClick={signInWithGoogle}
            size="large"
            variation="primary"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#ffffff',
              color: '#000000',
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '10px 20px',
              fontSize: '16px',
              cursor: 'pointer',
              fontWeight: 'bold',
              width: '250px',
              textAlign: 'center',
              gap: '10px',
              transition: '0.3s ease-in-out',
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = '#f0f0f0')} // Light gray hover effect
            onMouseLeave={(e) => (e.target.style.backgroundColor = '#ffffff')}
          >
            <FcGoogle size={22} />
            Sign in with Google
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 ${tokens.space.large} 0`}
          level={3}
          style={{
            fontSize: '26px', // ✅ Slightly larger for better readability
            fontWeight: 'bold', // ✅ Stronger emphasis
            textAlign: 'center',
            fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif', // ✅ Modern system fonts
            color: '#1E3A8A', // ✅ Deep blue for elegance
            letterSpacing: '0.6px', // ✅ Slight spacing for clarity
            textTransform: 'capitalize', // ✅ Makes it more readable
            marginBottom: '14px',
          }}
        >
          Create a New Account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      return (
        <View textAlign="center">
          {/* 🔥 Back to Sign In Button (Same Style as Reset Password) */}
          <Button
            fontWeight="bold"
            onClick={toSignIn}
            size="large"
            style={{
              background: 'linear-gradient(135deg,rgb(142, 106, 76),rgb(181, 110, 38))', // ✅ Teal to Blue Gradient
              color: 'white',
              borderRadius: '8px',
              padding: '12px 24px',
              fontSize: '16px',
              boxShadow: '0px 4px 10px rgba(79, 172, 255, 0.3)', // ✅ Soft Glow
              transition: '0.3s ease-in-out',
              border: 'none',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'linear-gradient(135deg, #1FA2FF, #4ECDC4)'; // ✅ Reverse Gradient
              e.target.style.boxShadow = '0px 6px 15px rgba(79, 172, 255, 0.5)'; // ✅ Bigger Glow
              e.target.style.transform = 'translateY(-2px)'; // ✅ Lift Effect
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'linear-gradient(135deg, #4ECDC4, #1FA2FF)';
              e.target.style.boxShadow = '0px 4px 10px rgba(79, 172, 255, 0.3)';
              e.target.style.transform = 'translateY(0px)';
            }}
          >
            ⬅ Back to Sign In
          </Button>
        </View>
      );
    },
  },

  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {},
  },

  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {},
  },

  ForgotPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {},
  },

  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {},
  },
};

// ✅ Form Fields Configuration (Kept as-is)
const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your Email',
      isRequired: true,
      label: 'Email',
    },
  },
  signUp: {
    username: {
      order: 1,
      placeholder: 'Enter your Email',
      isRequired: true,
      label: 'Email',
    },
    name: {
      order: 2,
      isRequired: true,
      label: 'Name',
    },
    password: {
      order: 3,
      isRequired: true,
      label: 'Password',
    },
    confirm_password: {
      order: 4,
      isRequired: true,
      label: 'Confirm Password',
    },
  },
  forgotPassword: {
    username: {
      placeholder: 'Enter your email',
      isRequired: true,
      label: 'Email',
    },
  },
};

// ✅ Sign-Up Attributes (Kept as-is)
const signUpAttributes = ['name'];

// ✅ Login Component (Kept as-is)
const Login = () => {
  const { user } = useAuthenticator();
  const location = useLocation();
  const [redirectPath, setRedirectPath] = useState('/');

  useEffect(() => {
    if (location.state?.from) {
      setRedirectPath(location.state.from.pathname);
    }
  }, [location.state]);

  if (user) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Authenticator formFields={formFields} signUpAttributes={signUpAttributes} components={components}>
        {({ signOut }) => (
          <button type="button" onClick={signOut}>
            Sign out
          </button>
        )}
      </Authenticator>
    </ThemeProvider>

  );
};

export default Login;
