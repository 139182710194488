import React, { useState } from 'react';
import './groundTrends.css';
import { Chart } from 'react-google-charts';
import {
  getWinningPercentageByInningChart,
  getAverageInningScoresChart,
  getWicketTakenByFastAndSlowBowlerChart,
  getAverageInningWicketsChart,
} from '../utils/ChartsUtil.jsx';

const GroundTrends = ({ groundStats }) => {
  const [isGroundTrendsExpanded, setIsGroundTrendsExpanded] = useState(true);
  const charts = [];
  if (groundStats.firstInningsScore.length > 0 && groundStats.secondInningsScore.length) {
    charts.push(getAverageInningScoresChart(groundStats.firstInningsScore, groundStats.secondInningsScore));
    charts.push(getWinningPercentageByInningChart(groundStats.winningPercentageByInnings));
    charts.push(getWicketTakenByFastAndSlowBowlerChart(groundStats.wicketsTakenByFastBowlers, groundStats.wicketsTakenBySlowBowlers));
    charts.push(getAverageInningWicketsChart(groundStats.firstInningsScore, groundStats.secondInningsScore));
  } else if (groundStats.wicketsTakenByFastBowlers > 0 || groundStats.wicketsTakenBySlowBowlers > 0) {
    charts.push(getWicketTakenByFastAndSlowBowlerChart(groundStats.wicketsTakenByFastBowlers, groundStats.wicketsTakenBySlowBowlers));
  }

  return (
    <div>
      <div className="statsNguts__ground_trends">
        <button type="button" onClick={() => setIsGroundTrendsExpanded(!isGroundTrendsExpanded)} className="statsNguts__ground_trends_toggle">
          Trends
        </button>
        {isGroundTrendsExpanded && (
          <div className="statsNguts__ground_chartContainer">
            {charts.map((chart) => (
              <div className="statsNguts__ground_chartContainer_chart">
                <Chart
                  chartType={chart.chartType}
                  data={chart.data}
                  options={chart.options}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default GroundTrends;
