/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { fetchSeriesStats } from '../../../../../api/dataService';
import PublishedMatches from '../../../../common/publishedMatches/PublishedMatches';
import CompletedMatches from '../../../../common/completedMatches/CompletedMatches';
import UpcomingMatches from '../../../../common/upcomingMatches/UpcomingMatches';
import './seriesDescription.css';

const SeriesDescription = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);
  const seriesId = 'WI-VS-ENG-T20I-NOV-2024';

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const seriesStats = await fetchSeriesStats(seriesId);
        if (isMounted.current) {
          setData(seriesStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Series Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const seriesStats = [
    {
      statName: 'Most Runs',
      stats: data.seriesStats.MostRuns.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Runs} Runs`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Wickets',
      stats: data.seriesStats.MostWickets.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Wickets} Wickets`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Strike Rate',
      stats: data.seriesStats.BestStrikeRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `SR: ${stat.StrikeRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Economy Rate',
      stats: data.seriesStats.BestEconomyRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Fours',
      stats: data.seriesStats.MostFours.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.TotalFours} Fours`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Sixes',
      stats: data.seriesStats.MostSixes.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.TotalSixes} Sixes`,
        isTop: i === 0,
      })),
    },
  ];

  return (
    <div className="statsNguts__seriesDescription_T20I section__padding" id="seriesDescription">
      <div className="statsNguts__seriesDescription_T20I_heading">
        <h1 className="gradient__text">England tour of West Indies, November 2024</h1>
      </div>
      <div className="statsNguts__seriesDescription_T20I_text">
        <p>
          The cricket rivalry between England and the West Indies is one of the most exciting in T20 cricket, characterized by intense competition, powerful performances, and a history of memorable encounters. This rivalry has brought thrilling moments to fans worldwide, as both teams are known for their aggressive playing style, dynamic players, and passion for the game.
        </p>
        <p>
          In T20 cricket, the West Indies hold a significant edge over England. The Caribbean team has consistently performed well in T20 World Cups, clinching the title twice, including a stunning win in 2016, where they defeated England in a dramatic final. That match remains etched in cricket history, with Carlos Brathwaite&apos;s unforgettable four consecutive sixes off Ben Stokes in the last over, securing victory for the West Indies. This iconic moment symbolizes the power and unpredictability that West Indies bring to T20 cricket, and it has fueled their rivalry with England ever since.
        </p>
      </div>
      <div className="statsNguts__seriesResult">
        <p className="statsNguts__seriesResult_text">England won the 5-match series 3-1</p>
        <p className="statsNguts__seriesResult_player">Player of the Series: Saqib Mahmood</p>
      </div>
      <div className="statsNguts__seriesDescription_T20I_stats">
        {seriesStats.map((statCategory, index) => (
          <div key={index} className="statsNguts__seriesDescription_T20I_statItem">
            <h3>{statCategory.statName}</h3>
            <div className="statsNguts__seriesDescription_T20I_statList">
              {statCategory.stats.map((stat, i) => (
                <div
                  key={i}
                  className={`statsNguts__seriesDescription_T20I_statDetail ${stat.isTop ? 'highlight' : ''}`}
                >
                  <img
                    src={require(`../../../../../assets/image/players/${stat.photo}`)}
                    alt={stat.playerName}
                    className="statsNguts__seriesDescription_T20I_photo"
                  />
                  <p className="statsNguts__seriesDescription_T20I_player">{stat.playerName}</p>
                  <p className="statsNguts__seriesDescription_T20I_value">{stat.statValue}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <PublishedMatches key="publishedMatchesWIVSENGT20INOV2024" seriesId={seriesId} />
      <UpcomingMatches key="upcomingMatchesWIVSENGT20INOV2024" seriesId={seriesId} />
      <CompletedMatches key="completedMatchesWIVSENGT20INOV2024" seriesId={seriesId} />
    </div>
  );
};

export default SeriesDescription;
