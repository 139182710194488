/* eslint-disable import/no-dynamic-require */
/* eslint-disable consistent-return */
/* eslint-disable global-require */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchTeamStats } from '../../../../../api/dataService';
import './seriesTeamStats.css';

const PlayerCard = ({ player }) => (
  <div className="player-card">
    <img src={require(`../../../../../assets/image/players/${player.avatar}`)} alt={player.name} className="player-img" />
    <h4>{player.name}</h4>
  </div>
);

const PlayerSection = ({ title, players }) => (
  <div className="player-section">
    <h3>{title}</h3>
    <div className="player-grid">
      {players.map((player, index) => (
        <PlayerCard key={index} player={player} />
      ))}
    </div>
  </div>
);

const SeriesTeamStats = () => {
  const { seriesId, teamShortName } = useParams();
  const [teamStatsdata, setTeamStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const teamStats = await fetchTeamStats(seriesId);
        if (isMounted.current) {
          setTeamStatsData(teamStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Team Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const teamData = teamStatsdata[teamShortName];

  const teamStats = [
    {
      statName: 'Most Runs',
      stats: teamData.MostRuns.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Runs} Runs`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Wickets',
      stats: teamData.MostWickets.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Wickets} Wickets`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Strike Rate',
      stats: teamData.BestStrikeRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `SR: ${stat.StrikeRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Economy Rate',
      stats: teamData.BestEconomyRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Fours',
      stats: teamData.MostFours.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.TotalFours} Fours`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Sixes',
      stats: teamData.MostSixes.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.TotalSixes} Sixes`,
        isTop: i === 0,
      })),
    },
  ];

  return (
    <div className="statsNguts__seriesTeamStats">
      <h2>{teamData.teamName}</h2>

      <div className="statsNguts__seriesTeamStats_images">
        <img src={require(`../../../../../assets/image/players/${teamData.teamCaptainAvatar}`)} alt="Captain" className="captain-img" />
        <img src={require(`../../../../../assets/image/teams/${teamData.teamAvatar}`)} alt="Team Logo" className="team-logo" />
      </div>
      <p><strong>Captain:</strong> {teamData.teamCaptain}</p>

      {teamData.wicketKeepers.length > 0 && <PlayerSection title="Wicket Keepers" players={teamData.wicketKeepers} />}
      {teamData.batters.length > 0 && <PlayerSection title="Batters" players={teamData.batters} />}
      {teamData.allRounders.length > 0 && <PlayerSection title="All Rounders" players={teamData.allRounders} />}
      {teamData.bowlers.length > 0 && <PlayerSection title="Bowlers" players={teamData.bowlers} />}

      <div className="statsNguts__seriesTeamStats_statsSection">
        <h2 className="section-heading">Team {teamData.teamName} Stats in Last 1 Year</h2>
        <div className="statsNguts__seriesTeamStats_grid">
          {teamStats.map((statCategory, index) => (
            <div key={index} className="statsNguts__seriesTeamStats_card">
              <h3 className="statsNguts__seriesTeamStats_title">{statCategory.statName}</h3>
              <div className="statsNguts__seriesTeamStats_details">
                {statCategory.stats.map((stat, i) => (
                  <div key={i} className={`statsNguts__seriesTeamStats_detail ${stat.isTop ? 'highlight' : ''}`}>
                    <img
                      src={require(`../../../../../assets/image/players/${stat.photo}`)}
                      alt={stat.playerName}
                      className="statsNguts__seriesTeamStats_photo"
                    />
                    <p className="statsNguts__seriesTeamStats_player">{stat.playerName}</p>
                    <p className="statsNguts__seriesTeamStats_value">{stat.statValue}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeriesTeamStats;
