import React from 'react';
import { useParams } from 'react-router-dom';
import IPL2024SeriesDescription from './T20Leagues/IPL-2024/seriesDescription/SeriesDescription';
import ICCMensT20IWC2024SeriesDescription from './T20Is/ICC-Mens-T20I-WC-2024/seriesDescription/SeriesDescription';
import SAVSINDT20INOV2024SeriesDescription from './T20Is/SA-VS-IND-T20I-NOV-2024/seriesDescription/SeriesDescription';
import SLVSNZT20INOV2024SeriesDescription from './T20Is/SL-VS-NZ-T20I-NOV-2024/seriesDescription/SeriesDescription';
import WIVSENGT20INOV2024SeriesDescription from './T20Is/WI-VS-ENG-T20I-NOV-2024/seriesDescription/SeriesDescription';
import INDVSENGT20IJANFEB2025SeriesDescription from './T20Is/IND-VS-ENG-T20I-JAN-FEB-2025/seriesDescription/SeriesDescription';
import INDVSENGODIFEB2025SeriesDescription from './ODIs/IND-VS-ENG-ODI-FEB-2025/seriesDescription/SeriesDescription';
import WPL2025SeriesDescription from './T20Leagues/WPL-2025/seriesDescription/SeriesDescription';
import ICCCT2025SeriesDescription from './ODIs/ICC-CT-2025/seriesDescription/SeriesDescription';

const SeriesHome = () => {
  const { seriesId } = useParams();

  const componentsMap = {
    'IPL-2024': [
      <IPL2024SeriesDescription key="seriesDescIPL2024" />,
    ],
    'ICC-Mens-T20-WC-2024': [
      <ICCMensT20IWC2024SeriesDescription key="seriesDescICCMensT20WC2024" />,
    ],
    'SA-VS-IND-T20I-NOV-2024': [
      <SAVSINDT20INOV2024SeriesDescription key="seriesDescSAVSINDT20INOV2024" />,
    ],
    'SL-VS-NZ-T20I-NOV-2024': [
      <SLVSNZT20INOV2024SeriesDescription key="seriesDescSLVSNZT20INOV2024" />,
    ],
    'WI-VS-ENG-T20I-NOV-2024': [
      <WIVSENGT20INOV2024SeriesDescription key="seriesDescWIVSENGT20INOV2024" />,
    ],
    'IND-VS-ENG-T20I-JAN-FEB-2025': [
      <INDVSENGT20IJANFEB2025SeriesDescription key="seriesDescINDVSENGT20IJANFEB2025" />,
    ],
    'IND-VS-ENG-ODI-FEB-2025': [
      <INDVSENGODIFEB2025SeriesDescription key="seriesDescINDVSENGODIFEB2025" />,
    ],
    'WPL-2025': [
      <WPL2025SeriesDescription key="seriesDescWPL2025" />,
    ],
    'ICC-CT-2025': [
      <ICCCT2025SeriesDescription key="seriesDescICCCT2025" />,
    ],
  };

  const componentsToRender = componentsMap[seriesId] || null;

  return <div className="statsNguts__seriesHome">{componentsToRender}</div>;
};

export default SeriesHome;
