import React from 'react';
import CompletedMatches from '../../../../common/completedMatches/CompletedMatches';
import './seriesDescription.css';

const SeriesDescription = () => {
  const seriesId = 'IPL-2024';

  return (
    <div className="statsNguts__seriesDescription_IPL2024 section__padding" id="seriesDescriptionIPL">
      <div className="statsNguts__seriesDescription_IPL2024_heading">
        <h1 className="gradient__text">Indian Premier League 2024</h1>
      </div>
      <CompletedMatches key="completedMatchesIPL2024" seriesId={seriesId} />
    </div>
  );
};

export default SeriesDescription;
