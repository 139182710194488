import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { signOut } from '@aws-amplify/auth';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../../assets/image/logo.png';
import './navbar.css';

const Navbar = ({ userName }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]); // ✅ Directly check if user is logged in

  const handleSignInClick = () => {
    ReactGA.event({
      category: 'User Actions',
      action: 'SignIn-Click',
      label: 'UserSignIn',
    });
    navigate('/login');
    setToggleMenu(false);
  };

  const clearCookies = () => {
    try {
      document.cookie.split(';').forEach((c) => {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
      });
    } catch (error) {
      // console.warn('⚠️ Failed to clear cookies:', error);
    }
  };

  const handleSignOutClick = async () => {
    try {
      await signOut({ global: true });

      try {
        localStorage.clear();
        sessionStorage.clear();
      } catch (err) {
        // console.warn('⚠️ Storage clear failed:', err);
      }

      try {
        clearCookies();
      } catch (err) {
        // console.warn('⚠️ Cookie clear failed:', err);
      }

      window.location.href = '/';
    } catch (error) {
      // console.error('❌ Error signing out:', error);
    }
  };

  return (
    <div className="statsNguts__navbar">
      <div className="statsNguts__navbar_links">
        <div className="statsNguts__navbar_links_logo">
          <a href="https://www.statsnguts.com">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div className="statsNguts__navbar_links_container">
          <p><a href="https://www.statsnguts.com">Home</a></p>
          <h4 className="statsNguts__navbar_menu_container_links_welcomeMessage">
            Welcome, {user ? userName : 'Guest'}!
          </h4>
        </div>
        <div className="statsNguts__navbar_centered_text">
          <p className="statsNguts__navbar_big_text">
            Uncover the Stats that makes a Difference!
          </p>
        </div>
      </div>

      <div className="statsNguts__navbar_sign">
        {user ? (
          <button type="button" onClick={handleSignOutClick}>Sign out</button>
        ) : (
          <button type="button" onClick={handleSignInClick}>Sign in</button>
        )}
      </div>

      <div className="statsNguts__navbar_menu">
        {toggleMenu ? (
          <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
        ) : (
          <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <div className="statsNguts__navbar_menu_container scale_up_center">
            <div className="statsNguts__navbar_menu_container_links">
              <p><a href="https://www.statsnguts.com">Home</a></p>
              <h4 className="statsNguts__navbar_menu_container_links_welcomeMessage">
                Welcome, {user ? userName : 'Guest'}!
              </h4>
            </div>
            <div className="statsNguts__navbar_menu_container_links_sign">
              {user ? (
                <button type="button" onClick={handleSignOutClick}>Sign out</button>
              ) : (
                <button type="button" onClick={handleSignInClick}>Sign in</button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
