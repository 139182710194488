/* eslint-disable no-param-reassign */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState } from 'react';
import './ground.css';
import GroundOverview from '../groundOverview/GroundOverview';
import GroundTrends from '../groundTrends/GroundTrends';

const Ground = ({ groundStats, imgURL, groundName, groundLocation }) => {
  let activeTab = '';
  let setActiveTab = '';
  let useStateTab = '';
  if (groundStats.firstInningsScore && groundStats.firstInningsScore.length > 0) {
    useStateTab = 'firstInningsScore';
  } else if (groundStats.secondInningsScore && groundStats.secondInningsScore.length > 0) {
    useStateTab = 'secondInningsScore';
  } else if (groundStats.wicketsTakenByFastBowlers && groundStats.wicketsTakenByFastBowlers.length > 0) {
    useStateTab = 'wicketsTakenByFastBowlers';
  } else if (groundStats.wicketsTakenBySlowBowlers && groundStats.wicketsTakenBySlowBowlers.length > 0) {
    useStateTab = 'wicketsTakenBySlowBowlers';
  } else if (groundStats.thirtyPlusScores && groundStats.thirtyPlusScores.length > 0) {
    useStateTab = 'thirtyPlusScores';
  } else if (groundStats.fiftyPlusScores && groundStats.fiftyPlusScores.length > 0) {
    useStateTab = 'fiftyPlusScores';
  } else if (groundStats.hundredPlusScores && groundStats.hundredPlusScores.length > 0) {
    useStateTab = 'hundredPlusScores';
  }

  [activeTab, setActiveTab] = useState(useStateTab);
  const [isGroundOverviewVisible, setIsGroundOverviewVisible] = useState(false);
  const [isGroundTrendsVisible, setIsGroundTrendsVisible] = useState(false);

  const recordWithWicketsFastBowlers = groundStats.wicketsTakenByFastBowlers.filter((record) => record.Wickets !== 0);
  const recordWithWicketsSlowBowlers = groundStats.wicketsTakenBySlowBowlers.filter((record) => record.Wickets !== 0);
  groundStats.wicketsTakenByFastBowlers = recordWithWicketsFastBowlers;
  groundStats.wicketsTakenBySlowBowlers = recordWithWicketsSlowBowlers;

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleGroundOverviewClick = () => {
    setIsGroundOverviewVisible(!isGroundOverviewVisible);
  };

  const handleGroundTrendsClick = () => {
    setIsGroundTrendsVisible(!isGroundTrendsVisible);
  };

  const getTableColumns = () => {
    switch (activeTab) {
      case 'firstInningsScore':
        return ['Score', 'Team', 'Against', 'Winner', 'Series', 'Match', 'Date'];
      case 'secondInningsScore':
        return ['Score', 'Team', 'Against', 'Winner', 'Series', 'Match', 'Date'];
      case 'wicketsTakenByFastBowlers':
        return ['Bowler', 'Team', 'Against', 'Wickets', 'Balls', 'Runs', 'Series', 'Match', 'Date'];
      case 'wicketsTakenBySlowBowlers':
        return ['Bowler', 'Team', 'Against', 'Wickets', 'Balls', 'Runs', 'Series', 'Match', 'Date'];
      case 'thirtyPlusScores':
        return ['Batter', 'Team', 'Against', 'Runs', 'Balls', 'Series', 'Match', 'Date'];
      case 'fiftyPlusScores':
        return ['Batter', 'Team', 'Against', 'Runs', 'Balls', 'Series', 'Match', 'Date'];
      case 'hundredPlusScores':
        return ['Batter', 'Team', 'Against', 'Runs', 'Balls', 'Series', 'Match', 'Date'];
      default:
        return [];
    }
  };

  const tabWithData = [];

  if (groundStats.firstInningsScore && groundStats.firstInningsScore.length > 0) {
    tabWithData.firstInningsScore = { description: 'First Inning Scores', data: groundStats.firstInningsScore };
  }
  if (groundStats.secondInningsScore && groundStats.secondInningsScore.length > 0) {
    tabWithData.secondInningsScore = { description: 'Second Inning Scores', data: groundStats.secondInningsScore };
  }
  if (groundStats.wicketsTakenByFastBowlers && groundStats.wicketsTakenByFastBowlers.length > 0) {
    tabWithData.wicketsTakenByFastBowlers = { description: 'Wickets - Fast Bowlers', data: groundStats.wicketsTakenByFastBowlers };
  }
  if (groundStats.wicketsTakenBySlowBowlers && groundStats.wicketsTakenBySlowBowlers.length > 0) {
    tabWithData.wicketsTakenBySlowBowlers = { description: 'Wickets - Slow Bowlers', data: groundStats.wicketsTakenBySlowBowlers };
  }
  if (groundStats.thirtyPlusScores && groundStats.thirtyPlusScores.length > 0) {
    tabWithData.thirtyPlusScores = { description: '30+ Scores', data: groundStats.thirtyPlusScores };
  }
  if (groundStats.fiftyPlusScores && groundStats.fiftyPlusScores.length > 0) {
    tabWithData.fiftyPlusScores = { description: '50+ Scores', data: groundStats.fiftyPlusScores };
  }
  if (groundStats.hundredPlusScores && groundStats.hundredPlusScores.length > 0) {
    tabWithData.hundredPlusScores = { description: '100+ Scores', data: groundStats.hundredPlusScores };
  }

  const tabDataMap = tabWithData;

  const renderDataRows = () => {
    const tabData = tabDataMap[activeTab].data || [];

    return tabData.map((entry, rowIndex) => (
      <tr key={rowIndex}>
        {getTableColumns().map((column, columnIndex) => (
          <td key={columnIndex} className="statsNguts__ground_card_stats_details_table_entry">
            {entry[column]}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <div className="statsNguts__ground_card">
      <div className="statsNguts__ground_card_avatarContainer">
        <img className="statsNguts__ground_card_avatarContainer_avatar" src={require(`../../../../../assets/image/ground/${imgURL}`)} alt="groundAvatar" />
        <div className="statsNguts__ground_card_name">
          <h2>{groundName}</h2>
        </div>
        <div className="statsNguts__ground_card_location">
          <h2>{groundLocation}</h2>
        </div>
        <div className="statsNguts__ground_card_ground_overview" onClick={handleGroundOverviewClick}>
          <h4>Overview<br />
            <h5 className="clickToKnowMore">
              Click to Know More!
            </h5>
          </h4>
        </div>
        <div className="statsNguts__ground_card_ground_trends" onClick={handleGroundTrendsClick}>
          <h4>Trends<br />
            <h5 className="clickToKnowMore">
              Click to Know More!
            </h5>
          </h4>
        </div>
      </div>
      <div className="statsNguts__ground_card_content">
        {isGroundOverviewVisible && (
        <div className="statsNguts__ground_card_groundOverview">
          <GroundOverview groundStats={groundStats} />
        </div>
        )}
        {isGroundTrendsVisible && (
        <div className="statsNguts__ground_card_groundCharts">
          <GroundTrends groundStats={groundStats} />
        </div>
        )}
        <div className="statsNguts__ground_card_stats_container">
          <h1>Stats</h1>
          <div className="statsNguts__ground_card_stats">
            <div className="statsNguts__ground_card_stats_tabs">
              {Object.keys(tabDataMap).map((tab, index) => (
                <button
                  key={index}
                  type="button"
                  className={`statsNguts__ground_card_stats_tabs_button ${activeTab === tab ? 'active' : ''}`}
                  onClick={() => handleTabChange(tab)}
                >
                  {tabDataMap[tab].description}
                </button>
              ))}
            </div>
            <div className="statsNguts__ground_card_stats_details">
              {activeTab && (
              <div className="statsNguts__ground_card_stats_details_table">
                <table>
                  <thead>
                    <tr>
                      {getTableColumns().map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{renderDataRows()}</tbody>
                </table>
              </div>
              )}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Ground;
