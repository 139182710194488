/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import './fantasyTeamCustom.css';

const FantasyTeamCustom = ({ selectedPlayers }) => {
  // **Check if Team is Full**
  const isTeamFull = selectedPlayers.length === 11;

  return (
    <div className="statsNguts__fantasyTeamCustom">
      <h2>Your Fantasy XI</h2>
      <div className="statsNguts__fantasyTeamCustom_container">
        <div className="statsNguts__fantasyTeamCustom_container_team">
          {['Wicket-Keeper', 'Batter', 'All-Rounder', 'Bowler'].map((role) => (
            <div key={role} className="statsNguts__fantasyTeamCustom_container_team_section">
              <h3>{role}s</h3>
              <div className="statsNguts__fantasyTeamCustom_container_players">
                {/* Display Selected Players */}
                {selectedPlayers
                  .filter((player) => player.role === role || (role === 'Batter' && player.role === 'Batsman'))
                  .map((player, index) => (
                    <div key={index} className="statsNguts__fantasyTeamCustom_container_player_item">
                      <img
                        src={require(`../../../assets/image/players/${player.avatar}`)}
                        alt={player.name}
                        className="statsNguts__fantasyTeamCustom_container_player_avatar"
                      />
                      <div className="statsNguts__fantasyTeamCustom_container_player_info">
                        <p className="statsNguts__fantasyTeamCustom_container_player_name">{player.name}</p>
                      </div>
                    </div>
                  ))}

                {/* Show Empty Slots ONLY if Team is NOT Full */}
                {!isTeamFull
                  && selectedPlayers.filter((p) => p.role === role || (role === 'Batter' && p.role === 'Batsman')).length < 3
                  && new Array(3 - selectedPlayers.filter((p) => p.role === role || (role === 'Batter' && p.role === 'Batsman')).length)
                    .fill(null)
                    .map((_, i) => (
                      <div key={`empty-${i}`} className="statsNguts__fantasyTeamCustom_container_player_item empty-slot">
                        <p>Empty</p>
                      </div>
                    ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FantasyTeamCustom;
