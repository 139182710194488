import React from 'react';
import { useLocation } from 'react-router-dom';
import FantasyCard from '../fantasyCard/FantasyCard';
import './fantasyCards.css';

const FantasyCards = () => {
  const location = useLocation();
  const matchInfo = location.state?.matchInfo || {};
  const fantasyCards = matchInfo?.fantasyCards ?? [];

  return (
    <div className="statsNguts__fantasyCardsWrapper">
      <div className="statsNguts__fantasyCardsHeading">
        <h1>Match {matchInfo.matchNumber} - {matchInfo.seriesFullName}</h1>
        <h2>Fantasy Cards</h2>
      </div>

      <div className="statsNguts__fantasyCardsContainer">
        {fantasyCards.map((fantasyCard, index) => (
          <FantasyCard key={index} fantasyCard={fantasyCard} />
        ))}
      </div>
    </div>
  );
};

export default FantasyCards;
