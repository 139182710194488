const customTheme = {
  name: 'ElegantTheme',
  tokens: {
    colors: {
      background: {
        primary: { value: '#f3f4f6' }, // Light gray background for the page
        secondary: { value: '#E0F2F1' }, // ✅ Light cyan for the form
      },
      font: {
        primary: { value: '#1F2937' }, // Dark gray text
        secondary: { value: '#6B7280' }, // Light gray text
      },
      border: {
        primary: { value: '#B2EBF2' }, // ✅ Light blue borders
      },
      brand: {
        primary: { value: '#0077b6' }, // ✅ Blue branding
        secondary: { value: '#005f99' }, // ✅ Darker blue hover
      },
    },
    components: {
      tabs: {
        item: {
          backgroundColor: { value: 'white' }, // Default white tab
          color: { value: '#6B7280' }, // Default gray text
          fontSize: { value: '18px' },
          fontWeight: { value: '600' },
          padding: { value: '12px 20px' },
          borderRadius: { value: '8px' },
          transition: { value: '0.3s ease-in-out' },
          _hover: {
            backgroundColor: { value: '#E0F7FA' }, // Light blue on hover
            color: { value: '#1E3A8A' },
          },
          _focus: {
            backgroundColor: { value: '#0077b6' }, // ✅ Fully highlight selected tab
            color: { value: 'white' },
            fontWeight: { value: 'bold' },
          },
          _active: {
            backgroundColor: { value: 'rgb(181, 110, 38)' }, // ✅ Change tab to blue when selected
            color: { value: 'white' }, // ✅ White text for contrast
            fontWeight: { value: 'bold' },
            borderBottom: { value: '3px solid #005f99' }, // ✅ Slightly darker underline
          },
        },
      },
      formSection: {
        backgroundColor: { value: '{colors.background.secondary.value}' }, // ✅ Apply light cyan
        padding: { value: '32px' },
        borderRadius: { value: '12px' },
        boxShadow: { value: '0px 6px 20px rgba(0, 0, 0, 0.15)' }, // ✅ Soft shadow effect
      },
      button: {
        backgroundColor: { value: 'linear-gradient(135deg, #1E3A8A, #2563EB)' }, // ✅ Dark Blue to Light Blue Gradient
        color: { value: 'white' },
        fontSize: { value: '16px' },
        fontWeight: { value: 'bold' },
        padding: { value: '12px 24px' },
        borderRadius: { value: '8px' },
        boxShadow: { value: '0px 4px 10px rgba(37, 99, 235, 0.3)' }, // ✅ Soft Glow
        transition: { value: '0.3s ease-in-out' },
        _hover: {
          backgroundColor: { value: 'linear-gradient(135deg, #2563EB, #1E3A8A)' }, // ✅ Reverse Gradient on Hover
          boxShadow: { value: '0px 6px 15px rgba(37, 99, 235, 0.5)' }, // ✅ Bigger Glow
          transform: { value: 'translateY(-2px)' }, // ✅ Slight Lift Effect
        },
      },
    },
  },
};

export default customTheme;
