/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-undef */
/* eslint-disable global-require */
/* eslint-disable max-len */

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './seriesDescription.css';
import { fetchSeriesStats } from '../../../../../api/dataService';
import PublishedMatches from '../../../../common/publishedMatches/PublishedMatches';
import CompletedMatches from '../../../../common/completedMatches/CompletedMatches';
import UpcomingMatches from '../../../../common/upcomingMatches/UpcomingMatches';

const SeriesDescription = () => {
  const navigate = useNavigate();

  const handleTeamClick = (teamShortName) => {
    navigate(`team/${teamShortName}`); // Navigate to team page with team name in URL
  };

  const [seriesStatsdata, setSeriesStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);
  const seriesId = 'WPL-2025';

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const seriesStats = await fetchSeriesStats(seriesId);
        if (isMounted.current) {
          setSeriesStatsData(seriesStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Series Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const wpl2025Stats = [
    {
      statName: 'Most Runs',
      stats: seriesStatsdata.seriesStats.WPL2025.MostRuns.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.Runs} Runs`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Most Wickets',
      stats: seriesStatsdata.seriesStats.WPL2025.MostWickets.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.Wickets} Wickets`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Best Strike Rate',
      stats: seriesStatsdata.seriesStats.WPL2025.BestStrikeRate.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `SR: ${stat.StrikeRate}`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Best Economy Rate',
      stats: seriesStatsdata.seriesStats.WPL2025.BestEconomyRate.slice(
        0,
        3,
      ).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Fours',
      stats: seriesStatsdata.seriesStats.WPL2025.MostFours.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.TotalFours} Fours`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Most Sixes',
      stats: seriesStatsdata.seriesStats.WPL2025.MostSixes.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.TotalSixes} Sixes`,
          isTop: i === 0,
        }),
      ),
    },
  ];

  const wpl2024Stats = [
    {
      statName: 'Most Runs',
      stats: seriesStatsdata.seriesStats.WPL2024.MostRuns.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.Runs} Runs`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Most Wickets',
      stats: seriesStatsdata.seriesStats.WPL2024.MostWickets.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.Wickets} Wickets`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Best Strike Rate',
      stats: seriesStatsdata.seriesStats.WPL2024.BestStrikeRate.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `SR: ${stat.StrikeRate}`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Best Economy Rate',
      stats: seriesStatsdata.seriesStats.WPL2024.BestEconomyRate.slice(
        0,
        3,
      ).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Fours',
      stats: seriesStatsdata.seriesStats.WPL2024.MostFours.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.TotalFours} Fours`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Most Sixes',
      stats: seriesStatsdata.seriesStats.WPL2024.MostSixes.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.TotalSixes} Sixes`,
          isTop: i === 0,
        }),
      ),
    },
  ];

  const wpl2023Stats = [
    {
      statName: 'Most Runs',
      stats: seriesStatsdata.seriesStats.WPL2023.MostRuns.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.Runs} Runs`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Most Wickets',
      stats: seriesStatsdata.seriesStats.WPL2023.MostWickets.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.Wickets} Wickets`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Best Strike Rate',
      stats: seriesStatsdata.seriesStats.WPL2023.BestStrikeRate.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `SR: ${stat.StrikeRate}`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Best Economy Rate',
      stats: seriesStatsdata.seriesStats.WPL2023.BestEconomyRate.slice(
        0,
        3,
      ).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Fours',
      stats: seriesStatsdata.seriesStats.WPL2023.MostFours.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.TotalFours} Fours`,
          isTop: i === 0,
        }),
      ),
    },
    {
      statName: 'Most Sixes',
      stats: seriesStatsdata.seriesStats.WPL2023.MostSixes.slice(0, 3).map(
        (stat, i) => ({
          playerName: stat.Name,
          photo: stat.Avatar,
          statValue: `${stat.TotalSixes} Sixes`,
          isTop: i === 0,
        }),
      ),
    },
  ];

  const teamsData = seriesStatsdata.seriesStats.WPL2025.Teams;

  return (
    <div
      className="statsNguts__seriesDescription_WPL2025 section__padding"
      id="seriesDescriptionWPL"
    >
      <div className="statsNguts__seriesDescription_WPL2025_heading">
        <h1 className="gradient__text">Women&apos;s Premier League 2025</h1>
      </div>

      <div className="statsNguts__seriesDescription_WPL2025_text">
        <p>
          The Women&apos;s Premier League (WPL) is India&apos;s premier
          women&apos;s T20 cricket league, organized by the Board of Control for
          Cricket in India (BCCI). Launched in 2023, the tournament aims to
          provide a global platform for women cricketers, similar to the
          men&apos;s Indian Premier League (IPL).
        </p>
        <p>
          The league features top international and domestic players, competing
          in franchise-based teams, and has significantly contributed to the
          growing popularity of women&apos;s cricket in India. With a format
          that includes league-stage matches followed by playoffs, the WPL has
          quickly become a high-stakes competition, drawing massive viewership
          and commercial investments.
        </p>
        <p>
          The WPL has not only enhanced opportunities for Indian women
          cricketers but has also attracted top overseas talent, making it one
          of the most competitive women&apos;s leagues globally. The tournament
          has given young Indian players a chance to share the field with
          experienced international stars, improving the overall standard of the
          game.
        </p>
      </div>

      <div className="statsNguts__seriesDescription_WPL2025_teams_heading">
        <h1 className="gradient__text">Teams</h1>
      </div>

      <div className="statsNguts__seriesDescription_WPL2025_teams_grid">
        {teamsData.map((team, index) => (
          <div
            key={index}
            className="statsNguts__seriesDescription_WPL2025_teams_card"
            onClick={() => handleTeamClick(team.TeamShortName)}
          >
            <div className="statsNguts__seriesDescription_WPL2025_teams_images">
              <img
                src={require(`../../../../../assets/image/teams/${team.TeamAvatar}`)}
                alt={`${team.TeamName} Logo`}
                className="statsNguts__seriesDescription_WPL2025_team_logo"
              />
            </div>

            <div className="statsNguts__seriesDescription_WPL2025_team_name">
              <span>{team.TeamName}</span>
            </div>
          </div>
        ))}
      </div>

      <PublishedMatches key="publishedMatchesWPL2025" seriesId={seriesId} />
      <UpcomingMatches key="upcomingMatchesWPL2025" seriesId={seriesId} />

      <div className="statsNguts__seriesDescription_WPL2025_statsSection">
        <h2 className="statsNguts__seriesDescription_WPL2025_section_heading">
          WPL 2025 Stats
        </h2>
        <div className="statsNguts__seriesDescription_WPL2025_grid">
          {wpl2025Stats.map((statCategory, index) => (
            <div key={index} className="statsNguts__seriesDescription_WPL2025_card">
              <h3 className="statsNguts__seriesDescription_WPL2025_title">
                {statCategory.statName}
              </h3>
              <div className="statsNguts__seriesDescription_WPL2025_details">
                {statCategory.stats.map((stat, i) => (
                  <div
                    key={i}
                    className={`statsNguts__seriesDescription_WPL2025_detail ${
                      stat.isTop ? 'highlight' : ''
                    }`}
                  >
                    <img
                      src={require(`../../../../../assets/image/players/${stat.photo}`)}
                      alt={stat.playerName}
                      className="statsNguts__seriesDescription_WPL2025_photo"
                    />
                    <p className="statsNguts__seriesDescription_WPL2025_player">
                      {stat.playerName}
                    </p>
                    <p className="statsNguts__seriesDescription_WPL2025_value">
                      {stat.statValue}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="statsNguts__seriesDescription_WPL2025_statsSection">
        <h2 className="statsNguts__seriesDescription_WPL2025_section_heading">
          WPL 2024 Stats
        </h2>
        <div className="statsNguts__seriesDescription_WPL2025_grid">
          {wpl2024Stats.map((statCategory, index) => (
            <div key={index} className="statsNguts__seriesDescription_WPL2025_card">
              <h3 className="statsNguts__seriesDescription_WPL2025_title">
                {statCategory.statName}
              </h3>
              <div className="statsNguts__seriesDescription_WPL2025_details">
                {statCategory.stats.map((stat, i) => (
                  <div
                    key={i}
                    className={`statsNguts__seriesDescription_WPL2025_detail ${
                      stat.isTop ? 'highlight' : ''
                    }`}
                  >
                    <img
                      src={require(`../../../../../assets/image/players/${stat.photo}`)}
                      alt={stat.playerName}
                      className="statsNguts__seriesDescription_WPL2025_photo"
                    />
                    <p className="statsNguts__seriesDescription_WPL2025_player">
                      {stat.playerName}
                    </p>
                    <p className="statsNguts__seriesDescription_WPL2025_value">
                      {stat.statValue}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="statsNguts__seriesDescription_WPL2025_statsSection">
        <h2 className="statsNguts__seriesDescription_WPL2025_section_heading">
          WPL 2023 Stats
        </h2>
        <div className="statsNguts__seriesDescription_WPL2025_grid">
          {wpl2023Stats.map((statCategory, index) => (
            <div key={index} className="statsNguts__seriesDescription_WPL2025_card">
              <h3 className="statsNguts__seriesDescription_WPL2025_title">
                {statCategory.statName}
              </h3>
              <div className="statsNguts__seriesDescription_WPL2025_details">
                {statCategory.stats.map((stat, i) => (
                  <div
                    key={i}
                    className={`statsNguts__seriesDescription_WPL2025_detail ${
                      stat.isTop ? 'highlight' : ''
                    }`}
                  >
                    <img
                      src={require(`../../../../../assets/image/players/${stat.photo}`)}
                      alt={stat.playerName}
                      className="statsNguts__seriesDescription_WPL2025_photo"
                    />
                    <p className="statsNguts__seriesDescription_WPL2025_player">
                      {stat.playerName}
                    </p>
                    <p className="statsNguts__seriesDescription_WPL2025_value">
                      {stat.statValue}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <CompletedMatches key="completedMatchesWPL2025" seriesId={seriesId} />
    </div>
  );
};

export default SeriesDescription;
