/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useState } from 'react';
import './seriesSummary.css';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

const SeriesSummary = ({ seriesId, seriesShortName, seriesFullName, season, numberOfMatches, seriesAvatar, seriesStatus }) => {
  const [status] = useState(seriesStatus);
  const navigate = useNavigate();

  const handleClick = () => {
    ReactGA.event({
      category: 'Series Summary',
      action: 'Click',
      label: `SeriesSummary-${seriesId}`,
    });

    navigate(`series/${seriesId}`);
  };

  let footerText;
  let textColor = '';
  let cursorStyle = '';
  let onClickHandler = null;

  switch (status) {
    case 'Published':
      footerText = 'Click to view stats';
      textColor = '#17dd70';
      cursorStyle = 'pointer';
      onClickHandler = handleClick;
      break;
    case 'Upcoming':
      footerText = 'Stats coming soon!';
      textColor = 'gray';
      cursorStyle = 'not-allowed';
      break;
    case 'Completed':
      footerText = 'Click to view published stats';
      textColor = '#17dd70';
      cursorStyle = 'pointer';
      onClickHandler = handleClick;
      break;
    default:
      footerText = 'Unknown';
      textColor = 'gray';
      cursorStyle = 'not-allowed';
  }

  return (
    <div
      key={seriesId}
      className="statsNguts__common_seriesSummary_card"
      style={{ cursor: cursorStyle }}
      onClick={onClickHandler}
    >
      <div className="statsNguts__common_seriesSummary_card_avatarContainer">
        <img
          className="statsNguts__common_seriesSummary_card_avatarContainer_avatar"
          src={require(`../../../assets/image/series/${seriesAvatar}`)}
          alt="seriesSummary_image"
        />
      </div>
      <div className="statsNguts__common_seriesSummary_card_content">
        <div>
          <p>{seriesShortName} - {season}</p>
          <h4>{seriesFullName}, {season}</h4>
          <h2>{numberOfMatches}</h2>
          <h3 style={{ color: textColor }}>{footerText}</h3>
        </div>
      </div>
    </div>
  );
};

export default SeriesSummary;

