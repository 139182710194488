/* eslint-disable no-param-reassign */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState } from 'react';
import './player.css'; // Import the CSS file
import PlayerOverview from '../playerOverview/PlayerOverview';

const Player = ({ player, avatar, ground, opposition, key, type }) => {
  const [isPlayerCardVisible, setIsPlayerCardVisible] = useState(false);
  const [isPlayerOverviewVisible, setIsPlayerOverviewVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('runsScored');

  const handlePlayerTitleClick = () => {
    setIsPlayerCardVisible(!isPlayerCardVisible);
  };

  const handlePlayerOverviewClick = () => {
    setIsPlayerOverviewVisible(!isPlayerOverviewVisible);
  };

  const wicketKeeperTabs = [];
  const batterTabs = [];
  const allrounderTabs = [];
  const bowlerTabs = [];

  if (player.runsScored && player.runsScored.length > 0) {
    wicketKeeperTabs.push({ tab: 'runsScored', label: 'Runs in ODI' });
    batterTabs.push({ tab: 'runsScored', label: 'Runs in ODI' });
    allrounderTabs.push({ tab: 'runsScored', label: 'Runs in ODI' });
    bowlerTabs.push({ tab: 'runsScored', label: 'Runs in ODI' });
  }
  if (player.runsScoredInGround && player.runsScoredInGround.length > 0) {
    wicketKeeperTabs.push({ tab: 'runsScoredInGround', label: `Runs in ${ground}` });
    batterTabs.push({ tab: 'runsScoredInGround', label: `Runs in ${ground}` });
    allrounderTabs.push({ tab: 'runsScoredInGround', label: `Runs in ${ground}` });
    bowlerTabs.push({ tab: 'runsScoredInGround', label: `Runs in ${ground}` });
  }
  if (player.runsScoredAgainstOpposition && player.runsScoredAgainstOpposition.length > 0) {
    wicketKeeperTabs.push({ tab: 'runsScoredAgainstOpposition', label: `Runs Vs ${opposition}` });
    batterTabs.push({ tab: 'runsScoredAgainstOpposition', label: `Runs Vs ${opposition}` });
    allrounderTabs.push({ tab: 'runsScoredAgainstOpposition', label: `Runs Vs ${opposition}` });
    bowlerTabs.push({ tab: 'runsScoredAgainstOpposition', label: `Runs Vs ${opposition}` });
  }
  if (player.runsScoredAgainstOppositionInGround && player.runsScoredAgainstOppositionInGround.length > 0) {
    wicketKeeperTabs.push({ tab: 'runsScoredAgainstOppositionInGround', label: `Runs Vs ${opposition} in ${ground}` });
    batterTabs.push({ tab: 'runsScoredAgainstOppositionInGround', label: `Runs Vs ${opposition} in ${ground}` });
    allrounderTabs.push({ tab: 'runsScoredAgainstOppositionInGround', label: `Runs Vs ${opposition} in ${ground}` });
    bowlerTabs.push({ tab: 'runsScoredAgainstOppositionInGround', label: `Runs Vs ${opposition} in ${ground}` });
  }
  if (player.runsScoredAndWicketsGivenAgainstBowlersOfOpposition && player.runsScoredAndWicketsGivenAgainstBowlersOfOpposition.length > 0) {
    wicketKeeperTabs.push({ tab: 'runsScoredAndWicketsGivenAgainstBowlersOfOpposition', label: `Batting Performance Vs ${opposition} Bowlers` });
    batterTabs.push({ tab: 'runsScoredAndWicketsGivenAgainstBowlersOfOpposition', label: `Batting Performance Vs ${opposition} Bowlers` });
    allrounderTabs.push({ tab: 'runsScoredAndWicketsGivenAgainstBowlersOfOpposition', label: `Batting Performance Vs ${opposition} Bowlers` });
    bowlerTabs.push({ tab: 'runsScoredAndWicketsGivenAgainstBowlersOfOpposition', label: `Batting Performance Vs ${opposition} Bowlers` });
  }
  if (player.wicketsTaken && player.wicketsTaken.length > 0) {
    wicketKeeperTabs.push({ tab: 'wicketsTaken', label: 'Wickets in ODI' });
    batterTabs.push({ tab: 'wicketsTaken', label: 'Wickets in ODI' });
    allrounderTabs.push({ tab: 'wicketsTaken', label: 'Wickets in ODI' });
    bowlerTabs.push({ tab: 'wicketsTaken', label: 'Wickets in ODI' });
  }
  if (player.wicketsTakenInGround && player.wicketsTakenInGround.length > 0) {
    wicketKeeperTabs.push({ tab: 'wicketsTakenInGround', label: `Wickets in ${ground}` });
    batterTabs.push({ tab: 'wicketsTakenInGround', label: `Wickets in ${ground}` });
    allrounderTabs.push({ tab: 'wicketsTakenInGround', label: `Wickets in ${ground}` });
    bowlerTabs.push({ tab: 'wicketsTakenInGround', label: `Wickets in ${ground}` });
  }
  if (player.wicketsTakenAgainstOpposition && player.wicketsTakenAgainstOpposition.length > 0) {
    wicketKeeperTabs.push({ tab: 'wicketsTakenAgainstOpposition', label: `Wickets Vs ${opposition}` });
    batterTabs.push({ tab: 'wicketsTakenAgainstOpposition', label: `Wickets Vs ${opposition}` });
    allrounderTabs.push({ tab: 'wicketsTakenAgainstOpposition', label: `Wickets Vs ${opposition}` });
    bowlerTabs.push({ tab: 'wicketsTakenAgainstOpposition', label: `Wickets Vs ${opposition}` });
  }
  if (player.wicketsTakenAgainstOppositionInGround && player.wicketsTakenAgainstOppositionInGround.length > 0) {
    wicketKeeperTabs.push({ tab: 'wicketsTakenAgainstOppositionInGround', label: `Wickets Vs ${opposition} in ${ground}` });
    batterTabs.push({ tab: 'wicketsTakenAgainstOppositionInGround', label: `Wickets Vs ${opposition} in ${ground}` });
    allrounderTabs.push({ tab: 'wicketsTakenAgainstOppositionInGround', label: `Wickets Vs ${opposition} in ${ground}` });
    bowlerTabs.push({ tab: 'wicketsTakenAgainstOppositionInGround', label: `Wickets Vs ${opposition} in ${ground}` });
  }
  if (player.wicketsTakenAndRunsGivenAgainstBattersOfOpposition && player.wicketsTakenAndRunsGivenAgainstBattersOfOpposition.length > 0) {
    wicketKeeperTabs.push({ tab: 'wicketsTakenAndRunsGivenAgainstBattersOfOpposition', label: `Bowling Performance Vs ${opposition} Batters` });
    batterTabs.push({ tab: 'wicketsTakenAndRunsGivenAgainstBattersOfOpposition', label: `Bowling Performance Vs ${opposition} Batters` });
    allrounderTabs.push({ tab: 'wicketsTakenAndRunsGivenAgainstBattersOfOpposition', label: `Bowling Performance Vs ${opposition} Batters` });
    bowlerTabs.push({ tab: 'wicketsTakenAndRunsGivenAgainstBattersOfOpposition', label: `Bowling Performance Vs ${opposition} Batters` });
  }

  const tabsConfig = {
    wicketKeeper: wicketKeeperTabs,
    batter: batterTabs,
    allrounder: allrounderTabs,
    bowler: bowlerTabs,
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getHeadingRow = () => {
    switch (type) {
      case 'wicketKeeper':
      case 'batter':
      case 'allrounder':
      case 'bowler':
        switch (activeTab) {
          case 'runsScored':
            return ['Runs', 'Balls', 'Team', 'Against', 'Series', 'Match', 'Ground', 'Date'];
          case 'runsScoredInGround':
            return ['Runs', 'Balls', 'Team', 'Against', 'Series', 'Match', 'Date'];
          case 'runsScoredAgainstOpposition':
            return ['Runs', 'Balls', 'Team', 'Series', 'Match', 'Ground', 'Date'];
          case 'runsScoredAgainstOppositionInGround':
            return ['Runs', 'Balls', 'Team', 'Series', 'Match', 'Date'];
          case 'runsScoredAndWicketsGivenAgainstBowlersOfOpposition':
            return ['Bowler', 'Balls', 'Runs', 'Wickets'];
          case 'wicketsTaken':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Against', 'Series', 'Match', 'Ground', 'Date'];
          case 'wicketsTakenInGround':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Against', 'Series', 'Match', 'Date'];
          case 'wicketsTakenAgainstOpposition':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Series', 'Match', 'Ground', 'Date'];
          case 'wicketsTakenAgainstOppositionInGround':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Series', 'Match', 'Date'];
          case 'wicketsTakenAndRunsGivenAgainstBattersOfOpposition':
            return ['Batter', 'Balls', 'Runs', 'Wickets'];
          default:
            return [];
        }
      default:
        return [];
    }
  };

  const playerStatus = player.status;
  let playerStatusClass = '';
  if (player.status === 'Available') {
    playerStatusClass = 'status-green';
  } else {
    playerStatusClass = 'status-red';
  }

  let playerName = player.name;
  if (player.isCaptain) {
    playerName += ' (Captain)';
  }
  if (player.isViceCaptain) {
    playerName += ' (Vice Captain)';
  }

  return (
    <div className="statsNguts__player_common">
      <div className="statsNguts__player_common_title" onClick={handlePlayerTitleClick}>
        <h3>{playerName}<br />
          <h5 className={playerStatusClass}>
            {playerStatus}
          </h5>
        </h3>
      </div>
      {isPlayerCardVisible && (
        <div className="statsNguts__player_common_card" key={key}>
          <div className="statsNguts__player_common_card_avatarContainer">
            <img className="statsNguts__player_common_card_avatarContainer_avatar" src={require(`../../../../../assets/image/players/${avatar}`)} alt="playerAvatar" />
            <div className="statsNguts__player_common_card_batting_style">
              <h4>Batting - {player.battingStyle}</h4>
            </div>
            <div className="statsNguts__player_common_card_bowling_style">
              <h4>Bowling - {player.bowlingStyle}</h4>
            </div>
            <div className="statsNguts__player_common_card_player_overview" onClick={handlePlayerOverviewClick}>
              <h4>Player Overview<br />
                <h5 className="clickToKnowMore">
                  Click to Know More!
                </h5>
              </h4>
            </div>
          </div>
          <div className="statsNguts__player_common_card_content">
            {isPlayerOverviewVisible && (
              <div className="statsNguts__player_common_card_playerOverview">
                <PlayerOverview player={player} />
              </div>
            )}
            <div className="statsNguts__player_common_card_stats_container">
              <h1>Stats</h1>
              <div className="statsNguts__player_common_card_stats">
                <div className="statsNguts__player_common_card_stats_tabs">
                  {tabsConfig[type].map((tabInfo) => (
                    <button
                      type="button"
                      key={tabInfo.tab}
                      className={`statsNguts__player_common_card_stats_tabs_button ${activeTab === tabInfo.tab ? 'active' : ''}`}
                      onClick={() => handleTabChange(tabInfo.tab)}
                    >
                      {tabInfo.label}
                    </button>
                  ))}
                </div>
                <div className="statsNguts__player_common_card_stats_details">
                  <div className="statsNguts__player_common_card_stats_details_table">
                    <table>
                      <tbody>
                        <tr>
                          {getHeadingRow().map((heading, index) => (
                            <th key={index}>{heading}</th>
                          ))}
                        </tr>
                        {player[activeTab].map((entry, index) => (
                          <tr key={index}>
                            {getHeadingRow().map((column, columnIndex) => (
                              <td key={columnIndex} className={`statsNguts__player_common_card_stats_details_table_entry ${column === 'Overview' ? 'overview-column' : ''}`}>
                                {entry[column]}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Player;
