import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Navbar, About, TermsAndConditions, PrivacyPolicy, Login, EarlyAccess, Footer, Header, PublishedSeries,
  UpcomingSeries, CompletedSeries, Feedback,
} from './components/common';
import FantasyCards from './components/common/fantasyCards/FantasyCards';
import WPLSeriesTeamStats from './components/series/T20Leagues/WPL-2025/seriesTeamStats/SeriesTeamStats';
import SeriesHome from './components/series/SeriesHome';
import SeriesMatchStats from './components/series/SeriesMatchStats';
import T20IsSeriesTeamStats from './components/series/T20Is/common/seriesTeamStats/SeriesTeamStats';
import ODIsSeriesTeamStats from './components/series/ODIs/common/seriesTeamStats/SeriesTeamStats';
import PlayerPage from './components/series/T20Leagues/WPL-2025/playerPage/PlayerPage';

import './App.css';
import AuthRoute from './AuthRoute';

const App = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [userName, setUserName] = useState('Guest');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (user) {
          const attributes = await fetchUserAttributes();
          const name = attributes?.name || attributes?.email || 'User';
          setUserName(name);
        }
      } catch (error) {
        setUserName('Google User');
        // console.error('❌ Error fetching user attributes:', error);
        // console.error('⚠️ Detailed error stack:', error.stack);
      }
    };

    if (user) {
      fetchUserDetails();
    }
  }, [user]);

  return (
    <Router>
      <div className="App">
        <Navbar userName={userName} />
        <Routes>
          <Route
            path="/player/:playerId"
            element={(
              <AuthRoute
                userName={userName}
                element={() => (
                  <>
                    <PlayerPage userName={userName} />
                    <EarlyAccess />
                    <Footer />
                  </>
                )}
              />
            )}
          />
          <Route
            path="series/WPL-2025/team/:teamShortName"
            element={(
              <AuthRoute
                userName={userName}
                element={() => (
                  <>
                    <WPLSeriesTeamStats userName={userName} />
                    <EarlyAccess />
                    <Footer />
                  </>
                )}
              />
            )}
          />
          <Route
            path="series/:seriesId/T20I/team/:teamShortName"
            element={(
              <AuthRoute
                userName={userName}
                element={() => (
                  <>
                    <T20IsSeriesTeamStats userName={userName} />
                    <EarlyAccess />
                    <Footer />
                  </>
                )}
              />
            )}
          />
          <Route
            path="series/:seriesId/ODI/team/:teamShortName"
            element={(
              <AuthRoute
                userName={userName}
                element={() => (
                  <>
                    <ODIsSeriesTeamStats userName={userName} />
                    <EarlyAccess />
                    <Footer />
                  </>
                )}
              />
            )}
          />
          <Route
            path="/fantasyCards/:matchId"
            element={(
              <AuthRoute
                userName={userName}
                element={() => (
                  <>
                    <FantasyCards userName={userName} />
                    <Footer />
                  </>
                )}
              />
            )}
          />
          <Route
            path="/"
            element={(
              <>
                <Header />
                <PublishedSeries />
                <UpcomingSeries />
                <CompletedSeries />
                <EarlyAccess />
                <Feedback />
                <Footer />
              </>
            )}
          />
          <Route
            path="login"
            element={(
              <>
                <Login />
                <Footer />
              </>
            )}
          />
          <Route
            path="/about"
            element={(
              <>
                <About />
                <Footer />
              </>
            )}
          />
          <Route
            path="/termsandconditions"
            element={(
              <>
                <TermsAndConditions />
                <Footer />
              </>
            )}
          />
          <Route
            path="/privacypolicy"
            element={(
              <>
                <PrivacyPolicy />
                <Footer />
              </>
            )}
          />
          <Route
            path="series/:seriesId"
            element={(
              <>
                <SeriesHome />
                <EarlyAccess />
                <Footer />
              </>
            )}
          />
          <Route
            path="/matchstats/:seriesId/:matchNumber/:matchId"
            element={(
              <AuthRoute
                userName={userName}
                element={() => (
                  <>
                    <SeriesMatchStats userName={userName} />
                    <EarlyAccess />
                    <Footer />
                  </>
                )}
              />
            )}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
