/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchSeriesStats } from '../../../../../api/dataService';
import PublishedMatches from '../../../../common/publishedMatches/PublishedMatches';
import CompletedMatches from '../../../../common/completedMatches/CompletedMatches';
import UpcomingMatches from '../../../../common/upcomingMatches/UpcomingMatches';
import './seriesDescription.css';

const SeriesDescription = () => {
  const [seriesStatsdata, setSeriesStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);
  const seriesId = 'IND-VS-ENG-ODI-FEB-2025';
  const navigate = useNavigate();
  const handleTeamClick = (teamShortName) => {
    navigate(`ODI/team/${teamShortName}`);
  };

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const seriesStats = await fetchSeriesStats(seriesId);
        if (isMounted.current) {
          setSeriesStatsData(seriesStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Series Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const seriesStats = [
    {
      statName: 'Most Runs',
      stats: seriesStatsdata.seriesStats.MostRuns.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Runs} Runs`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Wickets',
      stats: seriesStatsdata.seriesStats.MostWickets.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Wickets} Wickets`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Strike Rate',
      stats: seriesStatsdata.seriesStats.BestStrikeRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `SR: ${stat.StrikeRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Economy Rate',
      stats: seriesStatsdata.seriesStats.BestEconomyRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
  ];

  const teamsData = seriesStatsdata.seriesStats.Teams;

  return (
    <div className="statsNguts__seriesDescription_ODI section__padding" id="seriesDescriptionODI">
      <div className="statsNguts__seriesDescription_ODI_heading">
        <h1 className="gradient__text">England tour of India, January-February 2025</h1>
      </div>
      <div className="statsNguts__seriesDescription_ODI_text">
        <p>
          The cricket rivalry between India and England in One Day Internationals (ODIs) is one of the oldest and most competitive in the sport. Dating back to their first ODI encounter in 1974, the rivalry has seen both teams engage in fiercely contested matches across various conditions. India, known for its batting prowess and spin-friendly pitches, has historically enjoyed success at home, while England, with its fast-bowling attack and seaming conditions, has been dominant in their own backyard. Over the years, iconic matches such as the 2002 NatWest Series final at Lord&apos;s, where India pulled off a dramatic chase, and the 2011 World Cup group-stage tie in Bengaluru, have added intensity to the contest. With both teams evolving into modern ODI powerhouses, the rivalry has only grown stronger, fueled by high-stakes encounters in ICC tournaments.
        </p>
        <p>
          Beyond individual matches, the India-England ODI rivalry is defined by the contrasting cricketing philosophies of the two nations. England, historically conservative in their approach, revolutionized their white-ball game post-2015, adopting an aggressive, fearless batting style that led them to World Cup glory in 2019. India, on the other hand, has built a well-balanced ODI squad with world-class batters, spinners, and fast bowlers, consistently performing on the global stage. This contrast has made their battles even more intriguing, as each team pushes the other to innovate and adapt. Whether it&apos;s a bilateral series or a crucial ICC knockout match, India vs. England ODIs continue to be high-voltage contests that captivate cricket fans worldwide.
        </p>
      </div>

      <div className="statsNguts__seriesDescription_ODI_teams_heading">
        <h1 className="gradient__text">Teams</h1>
      </div>

      <div className="statsNguts__seriesDescription_ODI_teams_grid">
        {teamsData.map((team, index) => (
          <div
            key={index}
            className="statsNguts__seriesDescription_ODI_teams_card"
            onClick={() => handleTeamClick(team.TeamShortName)}
          >
            <div className="statsNguts__seriesDescription_ODI_teams_images">
              <img
                src={require(`../../../../../assets/image/teams/${team.TeamAvatar}`)}
                alt={`${team.TeamName} Logo`}
                className="statsNguts__seriesDescription_ODI_team_logo"
              />
            </div>

            <div className="statsNguts__seriesDescription_ODI_team_name">
              <span>{team.TeamName}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="statsNguts__seriesDescription_ODI_statsSection">
        <h2 className="statsNguts__seriesDescription_ODI_section_heading">Series Stats</h2>

        <div className="statsNguts__seriesDescription_ODI_series_result_inside">
          <p className="statsNguts__seriesDescription_ODI_series_result_text">India won the 3-match series 3-0</p>
          <p className="statsNguts__seriesDescription_ODI_series_result_player">Player of the Series: Shubman Gill</p>
        </div>

        <div className="statsNguts__seriesDescription_ODI_stats">
          {seriesStats.map((statCategory, index) => (
            <div key={index} className="statsNguts__seriesDescription_ODI_statItem">
              <h3>{statCategory.statName}</h3>
              <div className="statsNguts__seriesDescription_ODI_statList">
                {statCategory.stats.map((stat, i) => (
                  <div
                    key={i}
                    className={`statsNguts__seriesDescription_ODI_statDetail ${stat.isTop ? 'highlight' : ''}`}
                  >
                    <img
                      src={require(`../../../../../assets/image/players/${stat.photo}`)}
                      alt={stat.playerName}
                      className="statsNguts__seriesDescription_ODI_photo"
                    />
                    <p className="statsNguts__seriesDescription_ODI_player">{stat.playerName}</p>
                    <p className="statsNguts__seriesDescription_ODI_value">{stat.statValue}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <PublishedMatches key="publishedMatchesINDVSENGT20IJANFEB2025" seriesId={seriesId} />
        <UpcomingMatches key="upcomingMatchesINDVSENGT20IJANFEB2025" seriesId={seriesId} />
        <CompletedMatches key="completedMatchesINDVSENGT20IJANFEB2025" seriesId={seriesId} />
      </div>
    </div>
  );
};

export default SeriesDescription;
