/* eslint-disable no-loop-func */
/* eslint-disable no-param-reassign */

import { getTopRunScorers, getTopWicketTakers } from '../utils/StatsUtil.jsx';

export const GetFantasyTeams = (playerStats, matchInfo) => {
  let teamAfantasyPlayerList = [];
  let teamAPlayingWicketKeepers = [];
  let teamAPlayingBatters = [];
  let teamAPlayingAllRounders = [];
  let teamAPlayingBowlers = [];
  let teamBfantasyPlayerList = [];
  let teamBPlayingWicketKeepers = [];
  let teamBPlayingBatters = [];
  let teamBPlayingAllRounders = [];
  let teamBPlayingBowlers = [];

  teamAfantasyPlayerList = matchInfo.teamAPlayingSquad;
  teamBfantasyPlayerList = matchInfo.teamBPlayingSquad;

  const teamAWicketkeepers = playerStats.teamA_WicketKeepers.filter((player) => player.status === 'Available');
  const teamABatters = playerStats.teamA_Batters.filter((player) => player.status === 'Available');
  const teamAAllRounders = playerStats.teamA_AllRounders.filter((player) => player.status === 'Available');
  const teamABowlers = playerStats.teamA_Bowlers.filter((player) => player.status === 'Available');
  const teamBWicketkeepers = playerStats.teamB_WicketKeepers.filter((player) => player.status === 'Available');
  const teamBBatters = playerStats.teamB_Batters.filter((player) => player.status === 'Available');
  const teamBAllRounders = playerStats.teamB_AllRounders.filter((player) => player.status === 'Available');
  const teamBBowlers = playerStats.teamB_Bowlers.filter((player) => player.status === 'Available');

  if (teamAfantasyPlayerList.length > 0) {
    teamAWicketkeepers.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingWicketKeepers.push(player);
      }
    });

    teamABatters.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingBatters.push(player);
      }
    });

    teamAAllRounders.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingAllRounders.push(player);
      }
    });

    teamABowlers.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingBowlers.push(player);
      }
    });
  } else {
    teamAPlayingWicketKeepers = teamAWicketkeepers;
    teamAPlayingBatters = teamABatters;
    teamAPlayingAllRounders = teamAAllRounders;
    teamAPlayingBowlers = teamABowlers;
  }

  if (teamBfantasyPlayerList.length > 0) {
    teamBWicketkeepers.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingWicketKeepers.push(player);
      }
    });

    teamBBatters.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingBatters.push(player);
      }
    });

    teamBAllRounders.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingAllRounders.push(player);
      }
    });

    teamBBowlers.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingBowlers.push(player);
      }
    });
  } else {
    teamBPlayingWicketKeepers = teamBWicketkeepers;
    teamBPlayingBatters = teamBBatters;
    teamBPlayingAllRounders = teamBAllRounders;
    teamBPlayingBowlers = teamBBowlers;
  }

  const fantasyWicketKeepers = [];
  const fantasyBatters = [];
  const fantasyAllRounders = [];
  const fantasyBowlers = [];

  const highestRunScorerBatterTeamA = getTopRunScorers(teamAPlayingBatters.concat(teamAPlayingWicketKeepers).concat(teamAPlayingAllRounders), 1);
  const highestRunScorerBatterTeamB = getTopRunScorers(teamBPlayingBatters.concat(teamBPlayingWicketKeepers).concat(teamBPlayingAllRounders), 1);
  const highestWicketTakerBowlerTeamA = getTopWicketTakers(teamAPlayingBowlers.concat(teamAPlayingAllRounders), 'All', 1);
  const highestWicketTakerBowlerTeamB = getTopWicketTakers(teamBPlayingBowlers.concat(teamBPlayingAllRounders), 'All', 1);

  const team1Captain = highestRunScorerBatterTeamA[0].Name;
  let team1ViceCaptain = highestWicketTakerBowlerTeamA[0].Name;
  if (team1Captain === team1ViceCaptain) {
    const temp = getTopWicketTakers(teamAPlayingBowlers.concat(teamAPlayingAllRounders), 'All', 2);
    team1ViceCaptain = temp[1].Name;
  }

  const team2Captain = highestRunScorerBatterTeamB[0].Name;
  let team2ViceCaptain = highestWicketTakerBowlerTeamB[0].Name;
  if (team2Captain === team2ViceCaptain) {
    const temp = getTopWicketTakers(teamBPlayingBowlers.concat(teamBPlayingAllRounders), 'All', 2);
    team2ViceCaptain = temp[1].Name;
  }

  const team3Captain = highestRunScorerBatterTeamA[0].Name;
  const team3ViceCaptain = highestRunScorerBatterTeamB[0].Name;

  const team4Captain = highestRunScorerBatterTeamB[0].Name;
  const team4ViceCaptain = highestRunScorerBatterTeamA[0].Name;

  const topRunScorerWicketKeeper = getTopRunScorers(teamAPlayingWicketKeepers.concat(teamBPlayingWicketKeepers), 1);
  topRunScorerWicketKeeper.forEach((player) => {
    const wk1 = teamAPlayingWicketKeepers.find((entry) => entry.name === player.Name);
    if (wk1) {
      teamAPlayingWicketKeepers = teamAPlayingWicketKeepers.filter((item) => item.name !== player.Name);
    }
    const wk2 = teamBPlayingWicketKeepers.find((entry) => entry.name === player.Name);
    if (wk2) {
      teamBPlayingWicketKeepers = teamBPlayingWicketKeepers.filter((item) => item.name !== player.Name);
    }
  });

  const topRunScorerBatterTeamA = getTopRunScorers(teamAPlayingBatters, 1);
  topRunScorerBatterTeamA.forEach((player) => {
    const bt1 = teamAPlayingBatters.find((entry) => entry.name === player.Name);
    if (bt1) {
      teamAPlayingBatters = teamAPlayingBatters.filter((item) => item.name !== player.Name);
    }
  });

  const topRunScorerBatterTeamB = getTopRunScorers(teamBPlayingBatters, 1);
  topRunScorerBatterTeamB.forEach((player) => {
    const bt2 = teamBPlayingBatters.find((entry) => entry.name === player.Name);
    if (bt2) {
      teamBPlayingBatters = teamBPlayingBatters.filter((item) => item.name !== player.Name);
    }
  });

  const topRunScorerAllRounder = getTopRunScorers(teamAPlayingAllRounders.concat(teamBPlayingAllRounders), 1);
  topRunScorerAllRounder.forEach((player) => {
    const ar1 = teamAPlayingAllRounders.find((entry) => entry.name === player.Name);
    if (ar1) {
      teamAPlayingAllRounders = teamAPlayingAllRounders.filter((item) => item.name !== player.Name);
    }
    const ar2 = teamBPlayingAllRounders.find((entry) => entry.name === player.Name);
    if (ar2) {
      teamBPlayingAllRounders = teamBPlayingAllRounders.filter((item) => item.name !== player.Name);
    }
  });

  const topWicketTakerAllRounder = getTopWicketTakers(teamAPlayingAllRounders.concat(teamBPlayingAllRounders), 'All', 1);
  topWicketTakerAllRounder.forEach((player) => {
    const ar3 = teamAPlayingAllRounders.find((entry) => entry.name === player.Name);
    if (ar3) {
      teamAPlayingAllRounders = teamAPlayingAllRounders.filter((item) => item.name !== player.Name);
    }
    const ar4 = teamBPlayingAllRounders.find((entry) => entry.name === player.Name);
    if (ar4) {
      teamBPlayingAllRounders = teamBPlayingAllRounders.filter((item) => item.name !== player.Name);
    }
  });

  const topWicketTakerBowlerTeamA = getTopWicketTakers(teamAPlayingBowlers, 'All', 1);
  topWicketTakerBowlerTeamA.forEach((player) => {
    const bw1 = teamAPlayingBowlers.find((entry) => entry.name === player.Name);
    if (bw1) {
      teamAPlayingBowlers = teamAPlayingBowlers.filter((item) => item.name !== player.Name);
    }
  });

  const topWicketTakerBowlerTeamB = getTopWicketTakers(teamBPlayingBowlers, 'All', 1);
  topWicketTakerBowlerTeamB.forEach((player) => {
    const bw2 = teamBPlayingBowlers.find((entry) => entry.name === player.Name);
    if (bw2) {
      teamBPlayingBowlers = teamBPlayingBowlers.filter((item) => item.name !== player.Name);
    }
  });

  topRunScorerWicketKeeper.forEach((element) => {
    fantasyWicketKeepers.push(element.Name);
  });
  topRunScorerBatterTeamA.forEach((element) => {
    fantasyBatters.push(element.Name);
  });
  topRunScorerBatterTeamB.forEach((element) => {
    fantasyBatters.push(element.Name);
  });
  topRunScorerAllRounder.forEach((element) => {
    fantasyAllRounders.push(element.Name);
  });
  topWicketTakerAllRounder.forEach((element) => {
    fantasyAllRounders.push(element.Name);
  });
  topWicketTakerBowlerTeamA.forEach((element) => {
    fantasyBowlers.push(element.Name);
  });
  topWicketTakerBowlerTeamB.forEach((element) => {
    fantasyBowlers.push(element.Name);
  });

  const nextTwoTopRunScorer = getTopRunScorers(teamAPlayingWicketKeepers.concat(teamBPlayingWicketKeepers).concat(teamAPlayingBatters).concat(teamBPlayingBatters).concat(teamAPlayingAllRounders)
    .concat(teamBPlayingAllRounders), 2);
  nextTwoTopRunScorer.forEach((player) => {
    const wk1 = teamAPlayingWicketKeepers.find((entry) => entry.name === player.Name);
    if (wk1) {
      fantasyWicketKeepers.push(player.Name);
      teamAPlayingWicketKeepers = teamAPlayingWicketKeepers.filter((item) => item.name !== player.Name);
    }
    const bt1 = teamAPlayingBatters.find((entry) => entry.name === player.Name);
    if (bt1) {
      fantasyBatters.push(player.Name);
      teamAPlayingBatters = teamAPlayingBatters.filter((item) => item.name !== player.Name);
    }
    const ar1 = teamAPlayingAllRounders.find((entry) => entry.name === player.Name);
    if (ar1) {
      fantasyAllRounders.push(player.Name);
      teamAPlayingAllRounders = teamAPlayingAllRounders.filter((item) => item.name !== player.Name);
    }
    const wk2 = teamBPlayingWicketKeepers.find((entry) => entry.name === player.Name);
    if (wk2) {
      fantasyWicketKeepers.push(player.Name);
      teamBPlayingWicketKeepers = teamBPlayingWicketKeepers.filter((item) => item.name !== player.Name);
    }
    const bt2 = teamBPlayingBatters.find((entry) => entry.name === player.Name);
    if (bt2) {
      fantasyBatters.push(player.Name);
      teamBPlayingBatters = teamBPlayingBatters.filter((item) => item.name !== player.Name);
    }
    const ar2 = teamBPlayingAllRounders.find((entry) => entry.name === player.Name);
    if (ar2) {
      fantasyAllRounders.push(player.Name);
      teamBPlayingAllRounders = teamBPlayingAllRounders.filter((item) => item.name !== player.Name);
    }
  });

  const nextTwoTopWicketTaker = getTopWicketTakers(teamAPlayingAllRounders.concat(teamBPlayingAllRounders).concat(teamAPlayingBowlers).concat(teamBPlayingBowlers), 'All', 2);
  nextTwoTopWicketTaker.forEach((player) => {
    const ar1 = teamAPlayingAllRounders.find((entry) => entry.name === player.Name);
    if (ar1) {
      fantasyAllRounders.push(player.Name);
    }
    const ar2 = teamBPlayingAllRounders.find((entry) => entry.name === player.Name);
    if (ar2) {
      fantasyAllRounders.push(player.Name);
    }
    const bw1 = teamAPlayingBowlers.find((entry) => entry.name === player.Name);
    if (bw1) {
      fantasyBowlers.push(player.Name);
    }
    const bw2 = teamBPlayingBowlers.find((entry) => entry.name === player.Name);
    if (bw2) {
      fantasyBowlers.push(player.Name);
    }
  });

  const numberOfTeams = 4;
  const fantasyTeams = [];
  for (let i = 0; i < numberOfTeams; i += 1) {
    const teamNumber = i + 1;
    const teamName = `Team ${teamNumber}`;
    const players = [];
    fantasyWicketKeepers.forEach((name) => {
      let element = playerStats.teamA_WicketKeepers.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'WicketKeeper',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_WicketKeepers.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'WicketKeeper',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    fantasyBatters.forEach((name) => {
      let element = playerStats.teamA_Batters.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Batter',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_Batters.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Batter',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    fantasyAllRounders.forEach((name) => {
      let element = playerStats.teamA_AllRounders.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'AllRounder',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_AllRounders.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'AllRounder',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    fantasyBowlers.forEach((name) => {
      let element = playerStats.teamA_Bowlers.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Bowler',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_Bowlers.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Bowler',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    const fantasyTeam = {
      teamName,
      players,
    };

    fantasyTeams.push(fantasyTeam);
  }

  const fantasyTeam1 = fantasyTeams[0];
  const fantasyTeam1Players = fantasyTeam1.players;
  fantasyTeam1Players.forEach((player) => {
    if (player.name === team1Captain) {
      player.isCaptain = true;
    }
    if (player.name === team1ViceCaptain) {
      player.isViceCaptain = true;
    }
  });

  const fantasyTeam2 = fantasyTeams[1];
  const fantasyTeam2Players = fantasyTeam2.players;
  fantasyTeam2Players.forEach((player) => {
    if (player.name === team2Captain) {
      player.isCaptain = true;
    }
    if (player.name === team2ViceCaptain) {
      player.isViceCaptain = true;
    }
  });

  const fantasyTeam3 = fantasyTeams[2];
  const fantasyTeam3Players = fantasyTeam3.players;
  fantasyTeam3Players.forEach((player) => {
    if (player.name === team3Captain) {
      player.isCaptain = true;
    }
    if (player.name === team3ViceCaptain) {
      player.isViceCaptain = true;
    }
  });

  const fantasyTeam4 = fantasyTeams[3];
  const fantasyTeam4Players = fantasyTeam4.players;
  fantasyTeam4Players.forEach((player) => {
    if (player.name === team4Captain) {
      player.isCaptain = true;
    }
    if (player.name === team4ViceCaptain) {
      player.isViceCaptain = true;
    }
  });

  return fantasyTeams;
};
