/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useState } from 'react';
import './matchSummary.css';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

const MatchSummary = ({ matchId, seriesId, matchNumber, avatar, matchTeamAShortName, matchTeamBShortName, matchGroundCity, matchDate, matchTime, matchStatsStatus }) => {
  const [statsStatus] = useState(matchStatsStatus);
  const navigate = useNavigate();

  const handleClick = () => {
    ReactGA.event({
      category: 'Match Details',
      action: 'Click',
      label: `MatchSummary-${matchId}`,
    });
    navigate(`/matchstats/${seriesId}/Match${matchNumber}/${matchId}`);
  };

  let footerText;
  let textColor = '';
  let cursorStyle = '';
  let onClickHandler = null;

  switch (statsStatus) {
    case 'Published':
      footerText = 'Click to view stats and generate fantasy teams';
      textColor = '#17dd70';
      cursorStyle = 'pointer';
      onClickHandler = handleClick;
      break;
    case 'Upcoming':
      footerText = 'Stats coming soon!';
      textColor = 'gray';
      cursorStyle = 'not-allowed';
      break;
    case 'Completed':
      footerText = 'Click to view published stats!';
      textColor = '#17dd70';
      cursorStyle = 'pointer';
      onClickHandler = handleClick;
      break;
    default:
      footerText = 'Click to view published stats!';
      textColor = '#17dd70';
      cursorStyle = 'pointer';
      onClickHandler = handleClick;
  }

  return (
    <div
      key={matchId}
      className="statsNguts__matchSummary_card"
      style={{ cursor: cursorStyle }}
      onClick={onClickHandler}
    >
      <div className="statsNguts__matchSummary_card_avatarContainer">
        <img
          className="statsNguts__matchSummary_card_avatarContainer_avatar"
          src={require(`../../../assets/image/teams/${avatar}`)}
          alt="matchSummary_image"
        />
      </div>
      <div className="statsNguts__matchSummary_card_content">
        <div>
          <h2>Match {matchNumber} - {matchTeamAShortName} vs {matchTeamBShortName}, {matchGroundCity}</h2>
          <p>{matchDate} - {matchTime}</p>
          <h3 style={{ color: textColor }}>{footerText}</h3>
        </div>
      </div>
    </div>
  );
};

export default MatchSummary;
