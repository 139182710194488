import React from 'react';
import Ground from '../ground/Ground';
import './groundStats.css';

const GroundStats = React.forwardRef(({ matchInfo, groundStats, desc }, ref) => (
  <div ref={ref}>
    <div className="statsNguts__groundStats" id="groundStats">
      <div className="statsNguts__groundStats_heading">
        <h1 className="gradient__text">Ground Stats</h1>
        <h3>{desc}</h3>
      </div>
      <Ground groundStats={groundStats} imgURL={matchInfo.matchGroundAvatar} groundName={matchInfo.matchGroundFullName} groundLocation={matchInfo.matchGroundCity} />
    </div>
  </div>
));

export default GroundStats;
