export const getH2HWinsAndLossesChart = (teamAName, teamBName, h2hMatches) => {
  const totalH2HMatches = h2hMatches.length;
  let teamATotalWins = 0;
  let teamBTotalWins = 0;
  for (let i = 0; i < totalH2HMatches; i += 1) {
    if (h2hMatches[i].Winner === teamAName) {
      teamATotalWins += 1;
    }
    if (h2hMatches[i].Winner === teamBName) {
      teamBTotalWins += 1;
    }
  }

  const h2hWinsAndLossesChartData = [
    ['Team', 'Wins'],
    [teamAName, teamATotalWins],
    [teamBName, teamBTotalWins],
  ];

  const h2hWinsAndLossesChartOptions = {
    title: 'Last 5 Head to Head Matches',
  };

  const h2hWinsAndLossesChart = {
    chartType: 'PieChart',
    data: h2hWinsAndLossesChartData,
    options: h2hWinsAndLossesChartOptions,
  };

  return h2hWinsAndLossesChart;
};

export const getAverageInningScoresChart = (firstInningsScores, secondInningsScore) => {
  let firstInningScoreSum = 0;
  const firstInningScoresLength = firstInningsScores.length;
  for (let i = 0; i < firstInningScoresLength; i += 1) {
    firstInningScoreSum += firstInningsScores[i].Runs;
  }
  const firstInningAverageScores = Math.floor(firstInningScoreSum / firstInningScoresLength);

  let secondInningScoreSum = 0;
  const secondInningScoresLength = secondInningsScore.length;
  for (let i = 0; i < secondInningScoresLength; i += 1) {
    secondInningScoreSum += secondInningsScore[i].Runs;
  }
  const secondInningAverageScores = Math.floor(secondInningScoreSum / secondInningScoresLength);

  const averageInningScoresChartData = [
    ['Inning', 'Average Scores', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
    ['First Inning', firstInningAverageScores, '#ADD8E6', firstInningAverageScores],
    ['Second Inning', secondInningAverageScores, '#2C3E50', secondInningAverageScores],
  ];

  const averageInningScoresChartOptions = {
    title: 'Average Scores Per Inning',
    hAxis: {
    },
    vAxis: {
      title: 'Runs',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const averageInningScoresChart = {
    chartType: 'ColumnChart',
    data: averageInningScoresChartData,
    options: averageInningScoresChartOptions,
  };

  return averageInningScoresChart;
};

export const getWicketTakenByFastAndSlowBowlerChart = (wicketTakenByFastBowlers, wicketTakenBySlowBowlers) => {
  let wicketsByFastBowlersSum = 0;
  const wicketsByFastBowlersLength = wicketTakenByFastBowlers.length;
  for (let i = 0; i < wicketsByFastBowlersLength; i += 1) {
    wicketsByFastBowlersSum += wicketTakenByFastBowlers[i].Wickets;
  }

  let wicketsBySlowBowlersSum = 0;
  const wicketsBySlowBowlersLength = wicketTakenBySlowBowlers.length;
  for (let i = 0; i < wicketsBySlowBowlersLength; i += 1) {
    wicketsBySlowBowlersSum += wicketTakenBySlowBowlers[i].Wickets;
  }

  const wicketTakenByFastAndSlowBowlerChartData = [
    ['Bowler Type', 'Wicket Percentage'],
    ['Fast Bowlers', wicketsByFastBowlersSum],
    ['Slow Bowlers', wicketsBySlowBowlersSum],
  ];

  const wicketTakenByFastAndSlowBowlerChartOptions = {
    title: 'Wickets Distribution',
  };

  const wicketTakenByFastAndSlowBowlerChart = {
    chartType: 'PieChart',
    data: wicketTakenByFastAndSlowBowlerChartData,
    options: wicketTakenByFastAndSlowBowlerChartOptions,
  };

  return wicketTakenByFastAndSlowBowlerChart;
};

export const getTopFiveRunScorersChart = (players) => {
  const allPlayerRunScoredInLastFiveMatches = [];
  const totalPlayers = players.length;

  for (let i = 0; i < totalPlayers; i += 1) {
    const { runsScored, name } = players[i];
    let totalRunsScored = 0;
    let totalBallsFaced = 0;
    if (runsScored.length > 0) {
      for (let j = 0; j < runsScored.length; j += 1) {
        totalRunsScored += runsScored[j].Runs;
        totalBallsFaced += runsScored[j].Balls;
      }

      const playerRunsScoredInLastFiveMatches = {
        Name: name,
        Runs: totalRunsScored,
        Balls: totalBallsFaced,
        StrikeRate: Math.ceil((totalRunsScored / totalBallsFaced) * 100),
      };

      allPlayerRunScoredInLastFiveMatches.push(playerRunsScoredInLastFiveMatches);
    }
  }

  allPlayerRunScoredInLastFiveMatches.sort((a, b) => b.Runs - a.Runs);
  const topFivePlayers = allPlayerRunScoredInLastFiveMatches.slice(0, 5);

  const topFiveRunScorersChartData = [
    ['Player', 'Runs', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topFivePlayers.length; i += 1) {
    const entry = [];
    entry.push(topFivePlayers[i].Name);
    entry.push(topFivePlayers[i].Runs);
    entry.push('#ADD8E6');
    entry.push(topFivePlayers[i].Runs);

    topFiveRunScorersChartData.push(entry);
  }

  const topFiveRunScorersChartOptions = {
    title: 'Most Runs (in last 5 ODI matches of the respective teams)',
    hAxis: {
    },
    vAxis: {
      title: 'Runs',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const topFiveRunScorersChart = {
    chartType: 'ColumnChart',
    data: topFiveRunScorersChartData,
    options: topFiveRunScorersChartOptions,
  };

  return topFiveRunScorersChart;
};

export const getTopFiveWicketTakersChart = (players) => {
  const allPlayerWicketTakersInLastFiveMatches = [];
  const totalPlayers = players.length;

  for (let i = 0; i < totalPlayers; i += 1) {
    const { wicketsTaken, name } = players[i];
    let totalWicketsTaken = 0;
    let totalBallsBowled = 0;
    if (wicketsTaken.length > 0) {
      for (let j = 0; j < wicketsTaken.length; j += 1) {
        totalWicketsTaken += wicketsTaken[j].Wickets;
        totalBallsBowled += wicketsTaken[j].Balls;
      }

      const playerWicketTakenInLastFiveMatches = {
        Name: name,
        Wickets: totalWicketsTaken,
        Balls: totalBallsBowled,
      };

      allPlayerWicketTakersInLastFiveMatches.push(playerWicketTakenInLastFiveMatches);
    }
  }

  allPlayerWicketTakersInLastFiveMatches.sort((a, b) => b.Wickets - a.Wickets);
  const topFivePlayers = allPlayerWicketTakersInLastFiveMatches.slice(0, 5);

  const topFiveWicketTakersChartData = [
    ['Player', 'Wickets', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topFivePlayers.length; i += 1) {
    const entry = [];
    entry.push(topFivePlayers[i].Name);
    entry.push(topFivePlayers[i].Wickets);
    entry.push('#ADD8E6');
    entry.push(topFivePlayers[i].Wickets);

    topFiveWicketTakersChartData.push(entry);
  }

  const topFiveWicketTakersChartOptions = {
    title: 'Most Wickets (in last 5 ODI matches of the respective teams)',
    hAxis: {
    },
    vAxis: {
      title: 'Wickets',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const topFiveWicketTakersChart = {
    chartType: 'ColumnChart',
    data: topFiveWicketTakersChartData,
    options: topFiveWicketTakersChartOptions,
  };

  return topFiveWicketTakersChart;
};

export const getLastFiveRunsScoredChart = (runsScored) => {
  const lastFiveRunsScored = runsScored.slice(0, 5);
  const lastFiveRunsScoredChartData = [
    [
      'Match',
      'Runs',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
  ];

  for (let i = 0; i < 5 && i < lastFiveRunsScored.length; i += 1) {
    const runs = lastFiveRunsScored[i].Runs;
    const entry = [lastFiveRunsScored[i].Date, runs, '#ADD8E6', runs];
    lastFiveRunsScoredChartData.push(entry);
  }

  const lastFiveRunsScoredChartOptions = {
    title: 'Runs Scored in Last 5 IPL Innings',
    hAxis: {
      title: 'Runs',
      format: '0',
    },
    vAxis: {
      title: 'Matches (Most Recent on Top)',
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const lastFiveRunsScoredChart = {
    chartType: 'BarChart',
    data: lastFiveRunsScoredChartData,
    options: lastFiveRunsScoredChartOptions,
  };

  return lastFiveRunsScoredChart;
};

export const getStrikeRateAndBallsFacedChart = (runsScored) => {
  const lastFiveRunsScored = runsScored.slice(0, 5);
  lastFiveRunsScored.reverse();
  const strikeRateAndBallsFacedChartData = [
    ['x', 'strike rate', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'balls faced', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < 10 && i < lastFiveRunsScored.length; i += 1) {
    const strikeRate = Math.ceil((lastFiveRunsScored[i].Runs / lastFiveRunsScored[i].Balls) * 100);
    const balls = lastFiveRunsScored[i].Balls;
    const entry = [lastFiveRunsScored[i].Date, strikeRate, strikeRate, balls, balls];
    strikeRateAndBallsFacedChartData.push(entry);
  }

  const strikeRateAndBallsFacedChartOption = {
    title: 'Strike Rate and Balls Faced in Last 5 IPL Innings',
    hAxis: {
      title: 'Matches (Most Recent on Right)',
      slantedText: true,
      slantedTextAngle: 45,
    },
    vAxis: {
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    series: {
      1: { curveType: 'none' },
    },
    legend: { position: 'top' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const strikeRateAndBallsFacedChart = {
    chartType: 'LineChart',
    data: strikeRateAndBallsFacedChartData,
    options: strikeRateAndBallsFacedChartOption,
  };

  return strikeRateAndBallsFacedChart;
};

export const getLastFiveWicketsTakenChart = (wicketsTaken) => {
  const lastFiveWicketsTaken = wicketsTaken.slice(0, 5);
  const lastFiveWicketsTakenChartData = [
    [
      'Match',
      'Wickets',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
  ];

  for (let i = 0; i < 5 && i < lastFiveWicketsTaken.length; i += 1) {
    const wickets = lastFiveWicketsTaken[i].Wickets;
    const entry = [lastFiveWicketsTaken[i].Date, wickets, '#ADD8E6', wickets];
    lastFiveWicketsTakenChartData.push(entry);
  }

  const lastFiveWicketsTakenChartOptions = {
    title: 'Wickets Taken in Last 5 IPL Innings',
    hAxis: {
      title: 'Wickets',
      format: '0',
    },
    vAxis: {
      title: 'Matches (Most Recent on Top)',
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const lastFiveWicketsTakenChart = {
    chartType: 'BarChart',
    data: lastFiveWicketsTakenChartData,
    options: lastFiveWicketsTakenChartOptions,
  };

  return lastFiveWicketsTakenChart;
};

export const getEconomyRateAndOversBowledChart = (wicketsTaken) => {
  const lastFiveWicketsTaken = wicketsTaken.slice(0, 5);
  lastFiveWicketsTaken.reverse();

  const economyRateAndBallsBowledChartData = [
    ['x', 'economy rate', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'overs bowled', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < 10 && i < lastFiveWicketsTaken.length; i += 1) {
    const overs = Math.floor(lastFiveWicketsTaken[i].Balls / 6);
    const balls = lastFiveWicketsTaken[i].Balls % 6;
    const oversBowled = `${overs}.${balls}`;
    const economyRate = (lastFiveWicketsTaken[i].Runs / (lastFiveWicketsTaken[i].Balls / 6)).toFixed(2);
    const entry = [lastFiveWicketsTaken[i].Date, parseFloat(economyRate), parseFloat(economyRate), parseFloat(oversBowled), parseFloat(oversBowled)];
    economyRateAndBallsBowledChartData.push(entry);
  }

  const economyRateAndOversBowledChartOption = {
    title: 'Economy Rate and Overs Bowled in Last 5 IPL Innings',
    hAxis: {
      title: 'Matches (Most Recent on Right)',
      textPosition: 'out',
      slantedText: true,
      slantedTextAngle: 45,
    },
    vAxis: {
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    series: {
      1: { curveType: 'none' },
    },
    legend: { position: 'top' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const economyRateAndBallsBowledChart = {
    chartType: 'LineChart',
    data: economyRateAndBallsBowledChartData,
    options: economyRateAndOversBowledChartOption,
  };

  return economyRateAndBallsBowledChart;
};

export const getTeamWinsAndLossesChart = (teamAName, teamARecentMatches, teamBName, teamBRecentMatches) => {
  let teamATotalWins = 0;
  let teamATotalLosses = 0;
  const teamARecentMatchesLength = teamARecentMatches.slice(0, 5).length;
  for (let i = 0; i < teamARecentMatchesLength; i += 1) {
    if (teamARecentMatches[i].Winner === teamAName) {
      teamATotalWins += 1;
    }
  }
  teamATotalLosses = teamARecentMatchesLength - teamATotalWins;

  let teamBTotalWins = 0;
  let teamBTotalLosses = 0;
  const teamBRecentMatchesLength = teamBRecentMatches.slice(0, 5).length;
  for (let i = 0; i < teamBRecentMatchesLength; i += 1) {
    if (teamBRecentMatches[i].Winner === teamBName) {
      teamBTotalWins += 1;
    }
  }
  teamBTotalLosses = teamBRecentMatchesLength - teamBTotalWins;

  const teamWinsAndLossesChartData = [
    ['Team', 'Wins', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'Losses', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
    [teamAName, teamATotalWins, teamATotalWins, teamATotalLosses, teamATotalLosses],
    [teamBName, teamBTotalWins, teamBTotalWins, teamBTotalLosses, teamBTotalLosses],
  ];

  const teamWinsAndLossesChartOptions = {
    title: 'Team Recent Performances',
    legend: { position: 'top' },
  };

  const teamWinsAndLossesChart = {
    chartType: 'ColumnChart',
    data: teamWinsAndLossesChartData,
    options: teamWinsAndLossesChartOptions,
  };

  return teamWinsAndLossesChart;
};

export const getWinningPercentageByInningChart = (winningPercentageByInnings) => {
  const firstInningWinningPercentage = winningPercentageByInnings.First;
  const secondInningWinningPercentage = winningPercentageByInnings.Second;

  const winningPercentageByInnChartData = [
    ['Inning', 'Winning Percentage'],
    ['First Inning', parseFloat(firstInningWinningPercentage)],
    ['Second Inning', parseFloat(secondInningWinningPercentage)],
  ];

  const winningPercentageByInnChartOptions = {
    title: 'Winning Percentage by Inning since January 1st, 2021',
  };

  const winningPercentageByInnChart = {
    chartType: 'PieChart',
    data: winningPercentageByInnChartData,
    options: winningPercentageByInnChartOptions,
  };

  return winningPercentageByInnChart;
};

export const getAverageInningWicketsChart = (firstInningsScores, secondInningsScore) => {
  let firstInningWicketsSum = 0;
  const firstInningWicketsLength = firstInningsScores.length;
  for (let i = 0; i < firstInningWicketsLength; i += 1) {
    firstInningWicketsSum += firstInningsScores[i].Wickets;
  }
  const firstInningAverageWickets = Math.floor(firstInningWicketsSum / firstInningWicketsLength);

  let secondInningWicketsSum = 0;
  const secondInningWicketsLength = secondInningsScore.length;
  for (let i = 0; i < secondInningWicketsLength; i += 1) {
    secondInningWicketsSum += secondInningsScore[i].Wickets;
  }
  const secondInningAverageWickets = Math.floor(secondInningWicketsSum / secondInningWicketsLength);

  const averageInningWicketsChartData = [
    ['Inning', 'Average Wickets', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
    ['First Inning', firstInningAverageWickets, '#ADD8E6', firstInningAverageWickets],
    ['Second Inning', secondInningAverageWickets, '#2C3E50', secondInningAverageWickets],
  ];

  const averageInningWicketsChartOptions = {
    title: 'Average Wickets Per Inning',
    hAxis: {
    },
    vAxis: {
      title: 'Wickets',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const averageInningWicketsChart = {
    chartType: 'ColumnChart',
    data: averageInningWicketsChartData,
    options: averageInningWicketsChartOptions,
  };

  return averageInningWicketsChart;
};

export const getHeadToHeadRunsComparisonChart = (h2hMatches, teamAShortName, teamBShortName) => {
  const headToHeadRunsComparisonChartData = [
    ['Matches', `${teamAShortName}`, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, `${teamBShortName}`, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  const matches = h2hMatches.length;
  for (let i = 0; i < matches; i += 1) {
    const teamFI = h2hMatches[i].FITeam;
    const teamSI = h2hMatches[i].SITeam;
    const runsFI = h2hMatches[i].FIRuns;
    const runsSI = h2hMatches[i].SIRuns;
    const matchDate = h2hMatches[i].Date;
    const entry = [];

    if (teamFI === teamAShortName) {
      entry.push(matchDate);
      entry.push(runsFI);
      entry.push(runsFI);
      entry.push(runsSI);
      entry.push(runsSI);
    }

    if (teamSI === teamAShortName) {
      entry.push(matchDate);
      entry.push(runsSI);
      entry.push(runsSI);
      entry.push(runsFI);
      entry.push(runsFI);
    }

    headToHeadRunsComparisonChartData.push(entry);
  }

  const headToHeadRunsComparisonChartOptions = {
    title: 'Runs Comparison',
    hAxis: {
      title: 'Matches',
    },
    vAxis: {
      title: 'Runs',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    legend: { position: 'top' },
  };

  const headToHeadRunsComparisonChart = {
    chartType: 'ColumnChart',
    data: headToHeadRunsComparisonChartData,
    options: headToHeadRunsComparisonChartOptions,
  };

  return headToHeadRunsComparisonChart;
};

export const getHeadToHeadWicketsComparisonChart = (h2hMatches, teamAShortName, teamBShortName) => {
  const headToHeadWicketsComparisonChartData = [
    ['Matches', `${teamAShortName}`, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, `${teamBShortName}`, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  const matches = h2hMatches.length;
  for (let i = 0; i < matches; i += 1) {
    const teamFI = h2hMatches[i].FITeam;
    const teamSI = h2hMatches[i].SITeam;
    const wicketsFI = h2hMatches[i].FIWickets;
    const wicketsSI = h2hMatches[i].SIWickets;
    const matchDate = h2hMatches[i].Date;
    const entry = [];

    if (teamFI === teamAShortName) {
      entry.push(matchDate);
      entry.push(wicketsFI);
      entry.push(wicketsFI);
      entry.push(wicketsSI);
      entry.push(wicketsSI);
    }

    if (teamSI === teamAShortName) {
      entry.push(matchDate);
      entry.push(wicketsSI);
      entry.push(wicketsSI);
      entry.push(wicketsFI);
      entry.push(wicketsFI);
    }

    headToHeadWicketsComparisonChartData.push(entry);
  }

  const headToHeadWicketsComparisonChartOptions = {
    title: 'Wickets Comparison',
    hAxis: {
      title: 'Matches',
    },
    vAxis: {
      title: 'Wickets',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    legend: { position: 'top' },
  };

  const headToHeadWicketsComparisonChart = {
    chartType: 'ColumnChart',
    data: headToHeadWicketsComparisonChartData,
    options: headToHeadWicketsComparisonChartOptions,
  };

  return headToHeadWicketsComparisonChart;
};

export const getTeamWinsAndLossesOnGroundChart = (groundName, teamAName, teamARecentMatches, teamBName, teamBRecentMatches) => {
  let teamATotalMatchesOnGround = 0;
  let teamATotalWinsOnGround = 0;
  let teamATotalLossesOnGround = 0;
  const teamARecentMatchesLength = teamARecentMatches.length;
  for (let i = 0; i < teamARecentMatchesLength; i += 1) {
    if (teamARecentMatches[i].Ground === groundName) {
      teamATotalMatchesOnGround += 1;
      if (teamARecentMatches[i].Winner === teamAName) {
        teamATotalWinsOnGround += 1;
      }
    }
  }
  teamATotalLossesOnGround = teamATotalMatchesOnGround - teamATotalWinsOnGround;

  let teamBTotalMatchesOnGround = 0;
  let teamBTotalWinsOnGround = 0;
  let teamBTotalLossesOnGround = 0;
  const teamBRecentMatchesLength = teamBRecentMatches.length;
  for (let i = 0; i < teamBRecentMatchesLength; i += 1) {
    if (teamBRecentMatches[i].Ground === groundName) {
      teamBTotalMatchesOnGround += 1;
      if (teamBRecentMatches[i].Winner === teamBName) {
        teamBTotalWinsOnGround += 1;
      }
    }
  }
  teamBTotalLossesOnGround = teamBTotalMatchesOnGround - teamBTotalWinsOnGround;

  const teamWinsAndLossesOnGroundChartData = [
    ['Team', 'Played', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'Wins', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'Losses', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
    [teamAName, teamATotalMatchesOnGround, teamATotalMatchesOnGround, teamATotalWinsOnGround, teamATotalWinsOnGround, teamATotalLossesOnGround, teamATotalLossesOnGround],
    [teamBName, teamBTotalMatchesOnGround, teamBTotalMatchesOnGround, teamBTotalWinsOnGround, teamBTotalWinsOnGround, teamBTotalLossesOnGround, teamBTotalLossesOnGround],
  ];

  const teamWinsAndLossesOnGroundChartOptions = {
    title: `Performances in ${groundName}, since January 1st, 2021`,
    hAxis: {
    },
    vAxis: {
      title: 'Matches',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
  };

  const teamWinsAndLossesOnGroundChart = {
    chartType: 'ColumnChart',
    data: teamWinsAndLossesOnGroundChartData,
    options: teamWinsAndLossesOnGroundChartOptions,
  };

  return teamWinsAndLossesOnGroundChart;
};

export const getEconomyRateAndStrikeRateOfFastAndSlowBowlersOnGroundChart = (wicketTakenByFastBowlers, wicketTakenBySlowBowlers) => {
  let wicketsByFastBowlersSum = 0;
  let runsByFastBowlersSum = 0;
  let ballsByFastBowlersSum = 0;
  const wicketsByFastBowlersLength = wicketTakenByFastBowlers.length;
  for (let i = 0; i < wicketsByFastBowlersLength; i += 1) {
    wicketsByFastBowlersSum += wicketTakenByFastBowlers[i].Wickets;
    runsByFastBowlersSum += wicketTakenByFastBowlers[i].Runs;
    ballsByFastBowlersSum += wicketTakenByFastBowlers[i].Balls;
  }

  const totalOversBowledFastBowlers = ballsByFastBowlersSum / 6;
  const economyRateFastBowlers = parseFloat((runsByFastBowlersSum / totalOversBowledFastBowlers).toFixed(2));
  const strikeRateFastBowlers = Math.floor(runsByFastBowlersSum / wicketsByFastBowlersSum);

  let wicketsBySlowBowlersSum = 0;
  let runsBySlowBowlersSum = 0;
  let ballsBySlowBowlersSum = 0;
  const wicketsBySlowBowlersLength = wicketTakenBySlowBowlers.length;
  for (let i = 0; i < wicketsBySlowBowlersLength; i += 1) {
    wicketsBySlowBowlersSum += wicketTakenBySlowBowlers[i].Wickets;
    runsBySlowBowlersSum += wicketTakenBySlowBowlers[i].Runs;
    ballsBySlowBowlersSum += wicketTakenBySlowBowlers[i].Balls;
  }

  const totalOversBowledSlowBowlers = ballsBySlowBowlersSum / 6;
  const economyRateSlowBowlers = parseFloat((runsBySlowBowlersSum / totalOversBowledSlowBowlers).toFixed(2));
  const strikeRateSlowBowlers = Math.floor(runsByFastBowlersSum / wicketsBySlowBowlersSum);

  const economyRateAndStrikeRateOfFastAndSlowBowlersOnGroundChartData = [
    ['Stats', 'Fast Bowlers', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'Slow Bowlers', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],

    ['Strike Rate', strikeRateFastBowlers, strikeRateFastBowlers, strikeRateSlowBowlers, strikeRateSlowBowlers],
    ['Economy Rate', economyRateFastBowlers, economyRateFastBowlers, economyRateSlowBowlers, economyRateSlowBowlers],
  ];

  const economyRateAndStrikeRateOfFastAndSlowBowlersOnGroundChartOptions = {
    title: 'Strike Rate and Economy Rate',
    hAxis: {
    },
    vAxis: {
      title: 'Matches',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
  };

  const economyRateAndStrikeRateOfFastAndSlowBowlersOnGroundChart = {
    chartType: 'ColumnChart',
    data: economyRateAndStrikeRateOfFastAndSlowBowlersOnGroundChartData,
    options: economyRateAndStrikeRateOfFastAndSlowBowlersOnGroundChartOptions,
  };

  return economyRateAndStrikeRateOfFastAndSlowBowlersOnGroundChart;
};

export const getH2HPlayerMostRunsChart = (players, h2hBattingPerformances) => {
  const allPlayerRunScoredInH2HMatches = [];
  const playerMap = new Map();
  for (let i = 0; i < h2hBattingPerformances.length; i += 1) {
    const { Player, Runs } = h2hBattingPerformances[i];
    if (playerMap.has(Player)) {
      let totalRuns = playerMap.get(Player);
      totalRuns += Runs;
      playerMap.set(Player, totalRuns);
    } else {
      playerMap.set(Player, Runs);
    }
  }

  for (let i = 0; i < players.length; i += 1) {
    const playerName = players[i].name;
    if (playerMap.has(playerName)) {
      const player = {
        Name: playerName,
        Runs: playerMap.get(playerName),
      };
      allPlayerRunScoredInH2HMatches.push(player);
    } else {
      const player = {
        Name: playerName,
        Runs: 0,
      };
      allPlayerRunScoredInH2HMatches.push(player);
    }
  }

  allPlayerRunScoredInH2HMatches.sort((a, b) => b.Runs - a.Runs);
  const topFivePlayers = allPlayerRunScoredInH2HMatches.slice(0, 5);

  const h2hPlayerMostRunsChartData = [
    ['Player', 'Runs', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topFivePlayers.length; i += 1) {
    const entry = [];
    entry.push(topFivePlayers[i].Name);
    entry.push(topFivePlayers[i].Runs);
    entry.push('#FFA500');
    entry.push(topFivePlayers[i].Runs);

    h2hPlayerMostRunsChartData.push(entry);
  }

  const h2hPlayerMostRunsChartOptions = {
    title: 'Most Runs (Last 5 Head to Head Matches)',
    hAxis: {
    },
    vAxis: {
      title: 'Runs',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const h2hPlayerMostRunsChart = {
    chartType: 'ColumnChart',
    data: h2hPlayerMostRunsChartData,
    options: h2hPlayerMostRunsChartOptions,
  };

  return h2hPlayerMostRunsChart;
};

export const getH2HPlayerMostWicketsChart = (players, h2hBowlingPerformances) => {
  const allPlayerWicketsTakenInH2HMatches = [];
  const playerMap = new Map();
  for (let i = 0; i < h2hBowlingPerformances.length; i += 1) {
    const { Player, Wickets } = h2hBowlingPerformances[i];
    if (playerMap.has(Player)) {
      let totalWickets = playerMap.get(Player);
      totalWickets += Wickets;
      playerMap.set(Player, totalWickets);
    } else {
      playerMap.set(Player, Wickets);
    }
  }

  for (let i = 0; i < players.length; i += 1) {
    const playerName = players[i].name;
    if (playerMap.has(playerName)) {
      const player = {
        Name: playerName,
        Wickets: playerMap.get(playerName),
      };
      allPlayerWicketsTakenInH2HMatches.push(player);
    } else {
      const player = {
        Name: playerName,
        Wickets: 0,
      };
      allPlayerWicketsTakenInH2HMatches.push(player);
    }
  }

  allPlayerWicketsTakenInH2HMatches.sort((a, b) => b.Wickets - a.Wickets);
  const topFivePlayers = allPlayerWicketsTakenInH2HMatches.slice(0, 5);

  const h2hPlayerMostWicketsChartData = [
    ['Player', 'Wickets', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topFivePlayers.length; i += 1) {
    const entry = [];
    entry.push(topFivePlayers[i].Name);
    entry.push(topFivePlayers[i].Wickets);
    entry.push('#8470FF');
    entry.push(topFivePlayers[i].Wickets);

    h2hPlayerMostWicketsChartData.push(entry);
  }

  const h2hPlayerMostWicketsChartOptions = {
    title: 'Most Wickets (Last 5 Head to Head Matches)',
    hAxis: {
    },
    vAxis: {
      title: 'Wickets',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const h2hPlayerMostWicketsChart = {
    chartType: 'ColumnChart',
    data: h2hPlayerMostWicketsChartData,
    options: h2hPlayerMostWicketsChartOptions,
  };

  return h2hPlayerMostWicketsChart;
};

export const getTeamPlayerMostRunsChart = (teamName, players, recentMatches) => {
  const allPlayerRunScoredInTeamMatches = [];
  const playerMap = new Map();

  for (let j = 0; j < players.length; j += 1) {
    let totalRuns = 0;
    const { name, runsScored } = players[j];
    for (let i = 0; i < recentMatches.length; i += 1) {
      const { Date } = recentMatches[i];
      for (let k = 0; k < runsScored.length; k += 1) {
        if (runsScored[k].Team === teamName && runsScored[k].Date === Date) {
          if (playerMap.has(name)) {
            totalRuns = playerMap.get(name);
            totalRuns += runsScored[k].Runs;
            playerMap.set(name, totalRuns);
          } else {
            playerMap.set(name, runsScored[k].Runs);
          }
        }
      }
    }

    let playerRuns = 0;
    if (playerMap.has(name)) {
      playerRuns = playerMap.get(name);
    } else {
      playerRuns = 0;
    }

    const player = {
      Name: name,
      Runs: playerRuns,
    };

    allPlayerRunScoredInTeamMatches.push(player);
  }

  allPlayerRunScoredInTeamMatches.sort((a, b) => b.Runs - a.Runs);
  const topFivePlayers = allPlayerRunScoredInTeamMatches.slice(0, 5);

  const teamPlayerMostRunsChartData = [
    ['Player', 'Runs', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topFivePlayers.length; i += 1) {
    const entry = [];
    entry.push(topFivePlayers[i].Name);
    entry.push(topFivePlayers[i].Runs);
    entry.push('#FFA500');
    entry.push(topFivePlayers[i].Runs);

    teamPlayerMostRunsChartData.push(entry);
  }

  const teamPlayerMostRunsChartOptions = {
    title: `Most Runs (Last 5 ODI Matches of ${teamName})`,
    hAxis: {
    },
    vAxis: {
      title: 'Runs',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const teamPlayerMostRunsChart = {
    chartType: 'ColumnChart',
    data: teamPlayerMostRunsChartData,
    options: teamPlayerMostRunsChartOptions,
  };

  return teamPlayerMostRunsChart;
};

export const getTeamPlayerMostWicketsChart = (teamName, players, recentMatches) => {
  const allPlayerWicketsTakenInTeamMatches = [];
  const playerMap = new Map();

  for (let j = 0; j < players.length; j += 1) {
    let totalWickets = 0;
    const { name, wicketsTaken } = players[j];
    for (let i = 0; i < recentMatches.length; i += 1) {
      const { Date } = recentMatches[i];
      for (let k = 0; k < wicketsTaken.length; k += 1) {
        if (wicketsTaken[k].Team === teamName && wicketsTaken[k].Date === Date) {
          if (playerMap.has(name)) {
            totalWickets = playerMap.get(name);
            totalWickets += wicketsTaken[k].Wickets;
            playerMap.set(name, totalWickets);
          } else {
            playerMap.set(name, wicketsTaken[k].Wickets);
          }
        }
      }
    }

    let playerWickets = 0;
    if (playerMap.has(name)) {
      playerWickets = playerMap.get(name);
    } else {
      playerWickets = 0;
    }
    const player = {
      Name: name,
      Wickets: playerWickets,
    };

    allPlayerWicketsTakenInTeamMatches.push(player);
  }

  allPlayerWicketsTakenInTeamMatches.sort((a, b) => b.Wickets - a.Wickets);
  const topFivePlayers = allPlayerWicketsTakenInTeamMatches.slice(0, 5);

  const teamPlayerMostWicketsChartData = [
    ['Player', 'Wickets', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topFivePlayers.length; i += 1) {
    const entry = [];
    entry.push(topFivePlayers[i].Name);
    entry.push(topFivePlayers[i].Wickets);
    entry.push('#8470FF');
    entry.push(topFivePlayers[i].Wickets);

    teamPlayerMostWicketsChartData.push(entry);
  }

  const teamPlayerMostWicketsChartOptions = {
    title: `Most Wickets (Last 5 ODI Matches of ${teamName})`,
    hAxis: {
    },
    vAxis: {
      title: 'Wickets',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const teamPlayerMostWicketsChart = {
    chartType: 'ColumnChart',
    data: teamPlayerMostWicketsChartData,
    options: teamPlayerMostWicketsChartOptions,
  };

  return teamPlayerMostWicketsChart;
};

export const getTopNRunScorersAcrossFormatsChart = (players, n) => {
  const allPlayerRunScoredInLastFiveMatches = [];
  const totalPlayers = players.length;

  for (let i = 0; i < totalPlayers; i += 1) {
    const { runsScored, domesticT20RunsScored, internationalT20RunsScored, internationalT20LeagueRunsScored, name } = players[i];
    const lastFiveRunsScored = runsScored.slice(0, 5);
    const lastFiveRunsScoredDomesticT20 = domesticT20RunsScored.slice(0, 5);
    const lastFiveRunsScoredInternationalT20 = internationalT20RunsScored.slice(0, 5);
    const lastFiveRunsScoredInternationalT20Leagues = internationalT20LeagueRunsScored.slice(0, 5);
    const lastTwentyRunsScored = [];

    for (let j = 0; j < lastFiveRunsScored.length; j += 1) {
      lastTwentyRunsScored.push(lastFiveRunsScored[j]);
    }
    for (let j = 0; j < lastFiveRunsScoredDomesticT20.length; j += 1) {
      lastTwentyRunsScored.push(lastFiveRunsScoredDomesticT20[j]);
    }
    for (let j = 0; j < lastFiveRunsScoredInternationalT20.length; j += 1) {
      lastTwentyRunsScored.push(lastFiveRunsScoredInternationalT20[j]);
    }
    for (let j = 0; j < lastFiveRunsScoredInternationalT20Leagues.length; j += 1) {
      lastTwentyRunsScored.push(lastFiveRunsScoredInternationalT20Leagues[j]);
    }

    lastTwentyRunsScored.sort((a, b) => {
      const dateA = new Date(a.Date.split('-').reverse().join('-'));
      const dateB = new Date(b.Date.split('-').reverse().join('-'));
      return dateB - dateA;
    });

    const lastFiveRunsScoredAcrossFormats = lastTwentyRunsScored.slice(0, 5);
    let totalRunsScored = 0;
    if (lastFiveRunsScoredAcrossFormats.length > 0) {
      for (let j = 0; j < lastFiveRunsScoredAcrossFormats.length; j += 1) {
        totalRunsScored += lastFiveRunsScoredAcrossFormats[j].Runs;
      }

      const playerRunsScoredInLastFiveMatches = {
        Name: name,
        Runs: totalRunsScored,
      };

      allPlayerRunScoredInLastFiveMatches.push(playerRunsScoredInLastFiveMatches);
    }
  }

  allPlayerRunScoredInLastFiveMatches.sort((a, b) => b.Runs - a.Runs);
  const topNPlayers = allPlayerRunScoredInLastFiveMatches.slice(0, n);

  const topFiveRunScorersAcrossFormatsChartData = [
    ['Player', 'Runs', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topNPlayers.length; i += 1) {
    const entry = [];
    entry.push(topNPlayers[i].Name);
    entry.push(topNPlayers[i].Runs);
    entry.push('#ADD8E6');
    entry.push(topNPlayers[i].Runs);

    topFiveRunScorersAcrossFormatsChartData.push(entry);
  }

  const topFiveRunScorersAcrossFormatsChartOptions = {
    title: 'Most Runs (Last 5 T20 Innings of Player in IPL, India Domestic T20, T20I & Other T20 Leagues)',
    hAxis: {
    },
    vAxis: {
      title: 'Runs',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const topFiveRunScorersAcrossFormatsChart = {
    chartType: 'ColumnChart',
    data: topFiveRunScorersAcrossFormatsChartData,
    options: topFiveRunScorersAcrossFormatsChartOptions,
  };

  return topFiveRunScorersAcrossFormatsChart;
};

export const getTopNWicketTakersAcrossFormatsChart = (players, n) => {
  const allPlayerWicketTakensInLastFiveMatches = [];
  const totalPlayers = players.length;

  for (let i = 0; i < totalPlayers; i += 1) {
    const { wicketsTaken, domesticT20WicketsTaken, internationalT20WicketsTaken, internationalT20LeagueWicketsTaken, name } = players[i];
    const lastFiveWicketTakens = wicketsTaken.slice(0, 5);
    const lastFiveWicketTakensDomesticT20 = domesticT20WicketsTaken.slice(0, 5);
    const lastFiveWicketTakensInternationalT20 = internationalT20WicketsTaken.slice(0, 5);
    const lastFiveWicketTakensInternationalT20Leagues = internationalT20LeagueWicketsTaken.slice(0, 5);
    const lastTwentyWicketTakens = [];

    for (let j = 0; j < lastFiveWicketTakens.length; j += 1) {
      lastTwentyWicketTakens.push(lastFiveWicketTakens[j]);
    }
    for (let j = 0; j < lastFiveWicketTakensDomesticT20.length; j += 1) {
      lastTwentyWicketTakens.push(lastFiveWicketTakensDomesticT20[j]);
    }
    for (let j = 0; j < lastFiveWicketTakensInternationalT20.length; j += 1) {
      lastTwentyWicketTakens.push(lastFiveWicketTakensInternationalT20[j]);
    }
    for (let j = 0; j < lastFiveWicketTakensInternationalT20Leagues.length; j += 1) {
      lastTwentyWicketTakens.push(lastFiveWicketTakensInternationalT20Leagues[j]);
    }

    lastTwentyWicketTakens.sort((a, b) => {
      const dateA = new Date(a.Date.split('-').reverse().join('-'));
      const dateB = new Date(b.Date.split('-').reverse().join('-'));
      return dateB - dateA;
    });

    const lastFiveWicketTakensAcrossFormats = lastTwentyWicketTakens.slice(0, 5);
    let totalWicketsTaken = 0;
    if (lastFiveWicketTakensAcrossFormats.length > 0) {
      for (let j = 0; j < lastFiveWicketTakensAcrossFormats.length; j += 1) {
        totalWicketsTaken += lastFiveWicketTakensAcrossFormats[j].Wickets;
      }

      const playerWicketsTakenInLastFiveMatches = {
        Name: name,
        Wickets: totalWicketsTaken,
      };

      allPlayerWicketTakensInLastFiveMatches.push(playerWicketsTakenInLastFiveMatches);
    }
  }

  allPlayerWicketTakensInLastFiveMatches.sort((a, b) => b.Wickets - a.Wickets);
  const topNPlayers = allPlayerWicketTakensInLastFiveMatches.slice(0, n);

  const topFiveWicketTakersAcrossFormatsChartData = [
    ['Player', 'Wickets', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topNPlayers.length; i += 1) {
    const entry = [];
    entry.push(topNPlayers[i].Name);
    entry.push(topNPlayers[i].Wickets);
    entry.push('#ADD8E6');
    entry.push(topNPlayers[i].Wickets);

    topFiveWicketTakersAcrossFormatsChartData.push(entry);
  }

  const topFiveWicketTakersAcrossFormatsChartOptions = {
    title: 'Most Wickets (Last 5 T20 Innings of Player in IPL, India Domestic T20, T20I & Other T20 Leagues)',
    hAxis: {
    },
    vAxis: {
      title: 'Wickets',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const topFiveWicketTakersAcrossFormatsChart = {
    chartType: 'ColumnChart',
    data: topFiveWicketTakersAcrossFormatsChartData,
    options: topFiveWicketTakersAcrossFormatsChartOptions,
  };

  return topFiveWicketTakersAcrossFormatsChart;
};

export const getLastFiveRunsScoredAcrossFormatsChart = (runsScored, internationalT20RunsScored, domesticT20RunsScored, internationalT20LeagueRunsScored) => {
  const lastFiveRunsScored = runsScored.slice(0, 5);
  const lastFiveRunsScoredDomesticT20 = internationalT20RunsScored.slice(0, 5);
  const lastFiveRunsScoredInternationalT20 = domesticT20RunsScored.slice(0, 5);
  const lastFiveRunsScoredInternationalT20League = internationalT20LeagueRunsScored.slice(0, 5);
  const lastTwentyRunsScored = [];

  for (let j = 0; j < lastFiveRunsScored.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScored[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredDomesticT20.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredDomesticT20[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredInternationalT20.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredInternationalT20[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredInternationalT20League.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredInternationalT20League[j]);
  }

  lastTwentyRunsScored.sort((a, b) => {
    const dateA = new Date(a.Date.split('-').reverse().join('-'));
    const dateB = new Date(b.Date.split('-').reverse().join('-'));
    return dateB - dateA;
  });

  const lastFiveRunsScoredAcrossFormats = lastTwentyRunsScored.slice(0, 5);

  const lastFiveRunsScoredAcrossFormatsChartData = [
    [
      'Match',
      'Runs',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
  ];

  for (let i = 0; i < 5 && i < lastFiveRunsScoredAcrossFormats.length; i += 1) {
    const runs = lastFiveRunsScoredAcrossFormats[i].Runs;
    const entry = [lastFiveRunsScoredAcrossFormats[i].Date, runs, '#ADD8E6', runs];
    lastFiveRunsScoredAcrossFormatsChartData.push(entry);
  }

  const lastFiveRunsScoredAcrossFormatsChartOptions = {
    title: 'Runs Scored in Last 5 T20 Innings (IPL, India Domestic T20, T20I & Other T20 Leagues)',
    hAxis: {
      title: 'Runs',
      format: '0',
    },
    vAxis: {
      title: 'Matches (Most Recent on Top)',
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const lastFiveRunsScoredAcrossFormatsChart = {
    chartType: 'BarChart',
    data: lastFiveRunsScoredAcrossFormatsChartData,
    options: lastFiveRunsScoredAcrossFormatsChartOptions,
  };

  return lastFiveRunsScoredAcrossFormatsChart;
};

export const getStrikeRateAndBallsFacedAcrossFormatsChart = (runsScored, internationalT20RunsScored, domesticT20RunsScored, internationalT20LeagueRunsScored) => {
  const lastFiveRunsScored = runsScored.slice(0, 5);
  const lastFiveRunsScoredDomesticT20 = domesticT20RunsScored.slice(0, 5);
  const lastFiveRunsScoredInternationalT20 = internationalT20RunsScored.slice(0, 5);
  const lastFiveRunsScoredInternationalT20League = internationalT20LeagueRunsScored.slice(0, 5);
  const lastTwentyRunsScored = [];
  for (let j = 0; j < lastFiveRunsScored.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScored[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredDomesticT20.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredDomesticT20[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredInternationalT20.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredInternationalT20[j]);
  }
  for (let j = 0; j < lastFiveRunsScoredInternationalT20League.length; j += 1) {
    lastTwentyRunsScored.push(lastFiveRunsScoredInternationalT20League[j]);
  }

  lastTwentyRunsScored.sort((a, b) => {
    const dateA = new Date(a.Date.split('-').reverse().join('-'));
    const dateB = new Date(b.Date.split('-').reverse().join('-'));
    return dateB - dateA;
  });

  const lastFiveRunsScoredAcrossFormats = lastTwentyRunsScored.slice(0, 5);
  lastFiveRunsScoredAcrossFormats.reverse();

  const chartData = [
    ['x', 'strike rate', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'balls faced', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < 10 && i < lastFiveRunsScoredAcrossFormats.length; i += 1) {
    const strikeRate = Math.ceil((lastFiveRunsScoredAcrossFormats[i].Runs / lastFiveRunsScoredAcrossFormats[i].Balls) * 100);
    const balls = lastFiveRunsScoredAcrossFormats[i].Balls;
    const entry = [lastFiveRunsScoredAcrossFormats[i].Date, strikeRate, strikeRate, balls, balls];
    chartData.push(entry);
  }

  const chartOption = {
    title: 'Strike Rate and Balls Faced in Last 5 T20 Innings (IPL, India Domestic T20, T20I & Other T20 Leagues)',
    hAxis: {
      title: 'Matches (Most Recent on Right)',
      slantedText: true,
      slantedTextAngle: 45,
    },
    vAxis: {
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    series: {
      1: { curveType: 'none' },
    },
    legend: { position: 'top' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const chart = {
    chartType: 'LineChart',
    data: chartData,
    options: chartOption,
  };

  return chart;
};

export const getLastFiveWicketsTakenAcrossFormatsChart = (wicketsTaken, internationalT20WicketsTaken, domesticT20WicketsTaken, internationalT20LeagueWicketsTaken) => {
  const lastFiveWicketsTaken = wicketsTaken.slice(0, 5);
  const lastFiveWicketTakensDomesticT20 = domesticT20WicketsTaken.slice(0, 5);
  const lastFiveWicketTakensInternationalT20 = internationalT20WicketsTaken.slice(0, 5);
  const lastFiveWicketTakensInternationalT20Leagues = internationalT20LeagueWicketsTaken.slice(0, 5);
  const lastTwentyWicketTakens = [];

  for (let j = 0; j < lastFiveWicketsTaken.length; j += 1) {
    lastTwentyWicketTakens.push(lastFiveWicketsTaken[j]);
  }
  for (let j = 0; j < lastFiveWicketTakensDomesticT20.length; j += 1) {
    lastTwentyWicketTakens.push(lastFiveWicketTakensDomesticT20[j]);
  }
  for (let j = 0; j < lastFiveWicketTakensInternationalT20.length; j += 1) {
    lastTwentyWicketTakens.push(lastFiveWicketTakensInternationalT20[j]);
  }
  for (let j = 0; j < lastFiveWicketTakensInternationalT20Leagues.length; j += 1) {
    lastTwentyWicketTakens.push(lastFiveWicketTakensInternationalT20Leagues[j]);
  }

  lastTwentyWicketTakens.sort((a, b) => {
    const dateA = new Date(a.Date.split('-').reverse().join('-'));
    const dateB = new Date(b.Date.split('-').reverse().join('-'));
    return dateB - dateA;
  });

  const lastFiveWicketTakensAcrossFormats = lastTwentyWicketTakens.slice(0, 5);
  const chartData = [
    [
      'Match',
      'Wickets',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
  ];

  for (let i = 0; i < 5 && i < lastFiveWicketTakensAcrossFormats.length; i += 1) {
    const wickets = lastFiveWicketTakensAcrossFormats[i].Wickets;
    const entry = [lastFiveWicketTakensAcrossFormats[i].Date, wickets, '#ADD8E6', wickets];
    chartData.push(entry);
  }

  const chartOptions = {
    title: 'Wickets Taken in Last 5 T20 Innings (IPL, India Domestic T20, T20I & Other T20 Leagues)',
    hAxis: {
      title: 'Wickets',
      format: '0',
    },
    vAxis: {
      title: 'Matches (Most Recent on Top)',
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const chart = {
    chartType: 'BarChart',
    data: chartData,
    options: chartOptions,
  };

  return chart;
};

export const getEconomyRateAndOversBowledAcrossFormatsChart = (wicketsTaken, internationalT20WicketsTaken, domesticT20WicketsTaken, internationalT20LeagueWicketsTaken) => {
  const lastFiveWicketsTaken = wicketsTaken.slice(0, 5);
  const lastFiveWicketsTakenDomesticT20 = domesticT20WicketsTaken.slice(0, 5);
  const lastFiveWicketsTakenInternationalT20 = internationalT20WicketsTaken.slice(0, 5);
  const lastFiveWicketsTakenInternationalT20Leagues = internationalT20LeagueWicketsTaken.slice(0, 5);
  const lastTwentyWicketsTaken = [];
  for (let j = 0; j < lastFiveWicketsTaken.length; j += 1) {
    lastTwentyWicketsTaken.push(lastFiveWicketsTaken[j]);
  }
  for (let j = 0; j < lastFiveWicketsTakenDomesticT20.length; j += 1) {
    lastTwentyWicketsTaken.push(lastFiveWicketsTakenDomesticT20[j]);
  }
  for (let j = 0; j < lastFiveWicketsTakenInternationalT20.length; j += 1) {
    lastTwentyWicketsTaken.push(lastFiveWicketsTakenInternationalT20[j]);
  }
  for (let j = 0; j < lastFiveWicketsTakenInternationalT20Leagues.length; j += 1) {
    lastTwentyWicketsTaken.push(lastFiveWicketsTakenInternationalT20Leagues[j]);
  }

  lastTwentyWicketsTaken.sort((a, b) => {
    const dateA = new Date(a.Date.split('-').reverse().join('-'));
    const dateB = new Date(b.Date.split('-').reverse().join('-'));
    return dateB - dateA;
  });

  const lastFiveWicketsTakenAcrossFormats = lastTwentyWicketsTaken.slice(0, 5);
  lastFiveWicketsTakenAcrossFormats.reverse();

  const chartData = [
    ['x', 'economy rate', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }, 'overs bowled', {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < 10 && i < lastFiveWicketsTakenAcrossFormats.length; i += 1) {
    const overs = Math.floor(lastFiveWicketsTakenAcrossFormats[i].Balls / 6);
    const balls = lastFiveWicketsTakenAcrossFormats[i].Balls % 6;
    const oversBowled = `${overs}.${balls}`;
    const economyRate = (lastFiveWicketsTakenAcrossFormats[i].Runs / (lastFiveWicketsTakenAcrossFormats[i].Balls / 6)).toFixed(2);
    const entry = [lastFiveWicketsTakenAcrossFormats[i].Date, parseFloat(economyRate), parseFloat(economyRate), parseFloat(oversBowled), parseFloat(oversBowled)];
    chartData.push(entry);
  }

  const chartOptions = {
    title: 'Economy Rate and Overs Bowled in Last 5 T20 Innings (IPL, India Domestic T20, T20I & Other T20 Leagues)',
    subtitle: 'xyz',
    hAxis: {
      title: 'Matches (Most Recent on Right)',
      textPosition: 'out',
      slantedText: true,
      slantedTextAngle: 45,
    },
    vAxis: {
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    series: {
      1: { curveType: 'none' },
    },
    legend: { position: 'top' },
    chartArea: { width: '70%' },
    width: '100%',
  };

  const chart = {
    chartType: 'LineChart',
    data: chartData,
    options: chartOptions,
  };

  return chart;
};

export const getBestStrikeRate = (players) => {
  const allPlayerStrikeRateInLastFiveMatches = [];
  const totalPlayers = players.length;

  for (let i = 0; i < totalPlayers; i += 1) {
    const { runsScored, name } = players[i];
    const lastTwentyRunsScored = [];

    for (let j = 0; j < runsScored.length; j += 1) {
      lastTwentyRunsScored.push(runsScored[j]);
    }

    lastTwentyRunsScored.sort((a, b) => {
      const dateA = new Date(a.Date.split('-').reverse().join('-'));
      const dateB = new Date(b.Date.split('-').reverse().join('-'));
      return dateB - dateA;
    });

    const lastFiveRunsScoredAcrossFormats = lastTwentyRunsScored;
    let totalRunsScored = 0;
    let totalBallsFaced = 0;
    if (lastFiveRunsScoredAcrossFormats.length > 0) {
      for (let j = 0; j < lastFiveRunsScoredAcrossFormats.length; j += 1) {
        totalRunsScored += lastFiveRunsScoredAcrossFormats[j].Runs;
        totalBallsFaced += lastFiveRunsScoredAcrossFormats[j].Balls;
      }

      const strikeRate = Math.ceil((totalRunsScored / totalBallsFaced) * 100);

      const playerStrikeRateInLastFiveMatches = {
        Name: name,
        StrikeRate: strikeRate,
      };

      allPlayerStrikeRateInLastFiveMatches.push(playerStrikeRateInLastFiveMatches);
    }
  }

  allPlayerStrikeRateInLastFiveMatches.sort((a, b) => b.StrikeRate - a.StrikeRate);
  const topFivePlayers = allPlayerStrikeRateInLastFiveMatches.slice(0, 5);

  const chartData = [
    ['Player', 'Strike Rate', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topFivePlayers.length; i += 1) {
    const entry = [];
    entry.push(topFivePlayers[i].Name);
    entry.push(topFivePlayers[i].StrikeRate);
    entry.push('#FFA500');
    entry.push(topFivePlayers[i].StrikeRate);

    chartData.push(entry);
  }

  const chartOptions = {
    title: 'Best Strike Rate (Last 10 T20I Innings of Player)',
    hAxis: {
    },
    vAxis: {
      title: 'Strike Rate',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const chart = {
    chartType: 'ColumnChart',
    data: chartData,
    options: chartOptions,
  };

  return chart;
};

export const getBestEconomyRate = (players) => {
  const allPlayerEconomyRateInLastFiveMatches = [];

  for (let i = 0; i < players.length; i += 1) {
    const { wicketsTaken, name } = players[i];
    const lastTwentyWicketTakens = [];

    for (let j = 0; j < wicketsTaken.length; j += 1) {
      lastTwentyWicketTakens.push(wicketsTaken[j]);
    }

    lastTwentyWicketTakens.sort((a, b) => {
      const dateA = new Date(a.Date.split('-').reverse().join('-'));
      const dateB = new Date(b.Date.split('-').reverse().join('-'));
      return dateB - dateA;
    });

    const lastFiveWicketTakensAcrossFormats = lastTwentyWicketTakens;
    let totalRunsGiven = 0;
    let totalBallsBowled = 0;
    if (lastFiveWicketTakensAcrossFormats.length > 0) {
      for (let j = 0; j < lastFiveWicketTakensAcrossFormats.length; j += 1) {
        totalRunsGiven += lastFiveWicketTakensAcrossFormats[j].Runs;
        totalBallsBowled += lastFiveWicketTakensAcrossFormats[j].Balls;
      }

      const economyRate = (totalRunsGiven / (totalBallsBowled / 6)).toFixed(2);
      const playerEconomyRateInLastFiveMatches = {
        Name: name,
        EconomyRate: parseFloat(economyRate),
      };

      allPlayerEconomyRateInLastFiveMatches.push(playerEconomyRateInLastFiveMatches);
    }
  }

  allPlayerEconomyRateInLastFiveMatches.sort((a, b) => a.EconomyRate - b.EconomyRate);
  const topFivePlayers = allPlayerEconomyRateInLastFiveMatches.slice(0, 5);
  const chartData = [
    ['Player', 'Economy Rate', { role: 'style' }, {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    }],
  ];

  for (let i = 0; i < topFivePlayers.length; i += 1) {
    const entry = [];
    entry.push(topFivePlayers[i].Name);
    entry.push(topFivePlayers[i].EconomyRate);
    entry.push('#8470FF');
    entry.push(topFivePlayers[i].EconomyRate);

    chartData.push(entry);
  }

  const chartOptions = {
    title: 'Best Economy Rate (Last 10 T20I Innings of Player)',
    hAxis: {
    },
    vAxis: {
      title: 'Economy Rate',
      format: '0',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
      },
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
  };

  const chart = {
    chartType: 'ColumnChart',
    data: chartData,
    options: chartOptions,
  };

  return chart;
};
