import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

const AuthRoute = ({ element: Element, userName }) => {
  const { user } = useAuthenticator();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Element userName={userName} />;
};

export default AuthRoute;
